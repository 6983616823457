import { Button } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popover } from '@progress/kendo-react-tooltip';
import React, { ComponentType, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFittingPopoverCallout } from '../../hooks/commonHooks';
import { useSoleToggle } from '../../hooks/toggleHooks';
import { ReactComponent as AcceptedInviteIcon } from '../../icons/check-circle.svg';
import { ReactComponent as NotificationsIcon } from '../../icons/notifications.svg';
import { ReactComponent as InvalidInviteIcon } from '../../icons/x-circle.svg';
import bellAnimation from '../../images/notifications-bell-animated.svg';
import { appEventHub } from '../../services/appEvents';
import { dateTimeService } from '../../services/dateTimeService';
import { HttpException } from '../../services/httpServiceBase';
import { Idea, Invite, InviteState, ideasService } from '../../services/ideasService';
import { Notification, NotificationEventType, notificationsService } from '../../services/notificationsService';
import { realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { UserRole, generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import LoadingIndicator from '../ui/loadingIndicator';
import { P } from '../ui/typography';
import UserAvatar from '../user/userAvatar';

export const notificationPresentersMap: Record<NotificationEventType, ComponentType<any>> = {
    [NotificationEventType.InviteIssued]: InviteIssuedNotificationPresenter,
    [NotificationEventType.InviteAccepted]: InviteAcceptedNotificationPresenter,
    [NotificationEventType.InviteDeclined]: InviteDeclinedNotificationPresenter,
    [NotificationEventType.IdeaDeleted]: IdeaDeletedNotificationPresenter,
    [NotificationEventType.IdeaLeft]: IdeaLeftNotificationPresenter,
    [NotificationEventType.MembershipChanged]: MembershipChangedNotificationPresenter,
    [NotificationEventType.MembershipRevoked]: MembershipRevokedNotificationPresenter
};

export const notificationActionsMap: Partial<Record<NotificationEventType, ComponentType<any>>> = {
    [NotificationEventType.InviteIssued]: AcceptDeclineInvitationActions
};

export function NotificationsNavItem() {
    const toggleRef = useRef<Button>(null);
    const [show, toggleShow] = useSoleToggle(false);
    const [showMarkAsRead, setShowMarkAsRead] = useState(false);
    const [markAsReadEnabled, setMarkAsReadEnabled] = useState(false);
    const [hasNotSeenNotifications, setHasNotSeenNotifications] = useState(false);
    const notificationsListRef = useRef<any>();
    const isAuthenticated = useAppSelector(s => s.user) !== null;
    const [popoverId, onPopoverPosition] = useFittingPopoverCallout('horizontal', toggleRef.current?.element);

    const hide = useCallback(() => {
        if (show) toggleShow();
    }, [show, toggleShow]);

    const updateMarkAsReadButtonState = useCallback(async () => {
        const unreadNotificationsCount = await notificationsService.getUnreadCount();
        setMarkAsReadEnabled(unreadNotificationsCount > 0);
    }, []);

    const onNotificationsLoaded = useCallback(
        async (notifications: Notification[]) => {
            const hasNotifications = notifications && !!notifications.length;
            setShowMarkAsRead(hasNotifications);
            if (hasNotifications) {
                updateMarkAsReadButtonState();
                notificationsService.setLastSeenNotification(notifications[0].id);
            }
        },
        [updateMarkAsReadButtonState]
    );

    const onMarkAllAsReadClicked = async (e: React.MouseEvent) => {
        setMarkAsReadEnabled(false);
        if (notificationsListRef.current) notificationsListRef.current.markAllAsRead();
    };

    useEffect(() => {
        if (!isAuthenticated) return;

        const refreshNotSeen = async () => {
            const hasNotSeen = await notificationsService.hasNotSeen();
            setHasNotSeenNotifications(hasNotSeen);
        };

        const refreshTimeout = setTimeout(refreshNotSeen, 1000); // Check with a delay since this is not the most important request on the page

        realTimeUpdatesEventHub.addEventListener('connection', 'reconnected', refreshNotSeen);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('connection', 'reconnected', refreshNotSeen);
            clearTimeout(refreshTimeout);
        };
    }, [isAuthenticated]);

    const onToggleButtonClick = (e: React.MouseEvent) => {
        if (!show) {
            setHasNotSeenNotifications(false);
        }
        toggleShow();
    };

    useEffect(() => {
        const markHasNewNotifications = () => setHasNotSeenNotifications(true);
        const unMarkHasNewNotifications = () => setHasNotSeenNotifications(false);

        realTimeUpdatesEventHub.addEventListener('notification', 'new', markHasNewNotifications);
        realTimeUpdatesEventHub.addEventListener('notification', 'seenChanged', unMarkHasNewNotifications);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('notification', 'new', markHasNewNotifications);
            realTimeUpdatesEventHub.removeEventListener('notification', 'seenChanged', unMarkHasNewNotifications);
        };
    }, []);

    if (!isAuthenticated) return null;

    return (
        <div onClick={e => e.stopPropagation()}>
            <Button
                ref={toggleRef}
                onClick={onToggleButtonClick}
                fillMode="flat"
                size="large"
                title="Notifications"
                className="k-icp-tooltip-trigger k-icp-svg-icon-button"
            >
                <BadgeContainer className="!k-display-block">
                    <NotificationsIcon className="k-icp-icon" />
                    {hasNotSeenNotifications && (
                        <Badge align={{ vertical: 'top', horizontal: 'end' }} position="inside" size="medium" themeColor="error" rounded="full" />
                    )}
                </BadgeContainer>
            </Button>
            <Popover
                id={popoverId}
                show={show}
                anchor={toggleRef.current?.element}
                collision={{ horizontal: 'fit', vertical: 'flip' }}
                position="bottom"
                margin={{ vertical: 10, horizontal: 0 }}
                className="!k-pr-3 k-icp-no-padding-popover notifications-popover"
                positionMode="fixed"
                title={
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between">
                        <span className="k-h2 k-icp-font-weight-medium">Notifications</span>
                        {showMarkAsRead && (
                            <Button fillMode="link" size="small" themeColor="secondary" disabled={!markAsReadEnabled} onClick={onMarkAllAsReadClicked}>
                                Mark all as read
                            </Button>
                        )}
                    </StackLayout>
                }
                onPosition={onPopoverPosition}
            >
                <NotificationsList
                    ref={notificationsListRef}
                    visible={show}
                    hide={hide}
                    onLoaded={onNotificationsLoaded}
                    onNotificationRead={updateMarkAsReadButtonState}
                />
            </Popover>
        </div>
    );
}

const NotificationsList = forwardRef(
    (
        {
            visible,
            hide,
            onLoaded,
            onNotificationRead
        }: { visible: boolean; hide: Function; onLoaded: (notifications: Notification[]) => void; onNotificationRead: Function },
        ref
    ) => {
        const [loading, setLoading] = useState(false);
        const [notifications, setNotifications] = useState<Notification[]>();
        const [loadItemsOnScroll, setLoadItemsOnScroll] = useState(true);

        useImperativeHandle(
            ref,
            () => ({
                markAllAsRead: async () => {
                    setNotifications(n => n?.map(n => (n.read ? n : { ...n, read: true })));
                    await notificationsService.markAsRead();
                }
            }),
            []
        );

        const loadItems = useCallback(
            async (lastNotificationId?: number) => {
                setLoading(true);
                const loadedNotifications = await notificationsService.get(lastNotificationId);
                if (lastNotificationId) setNotifications(n => [...(n || []), ...loadedNotifications]);
                else {
                    setNotifications(loadedNotifications);
                    onLoaded(loadedNotifications);
                }
                setLoading(false);
                if (!loadedNotifications.length) setLoadItemsOnScroll(false);
            },
            [onLoaded]
        );

        const appendItems = () => {
            const lastNotificationId = notifications && notifications.length ? notifications[notifications.length - 1].id : undefined;
            return loadItems(lastNotificationId);
        };

        useEffect(() => {
            if (!visible) return;

            loadItems();
        }, [loadItems, visible]);

        const markNotificationAsRead = async (notificationId: number) => {
            setNotifications(notifications => notifications?.map(n => (n.id === notificationId ? { ...n, read: true } : n)));
            await notificationsService.markAsRead(notificationId);
            onNotificationRead();
        };

        const onNotificationClick = (e: React.MouseEvent<Element>, notification: Notification) => {
            if (!notification.read) markNotificationAsRead(notification.id);
        };

        const onNotificationsScroll = (e: React.UIEvent<Element>) => {
            if (loading || !loadItemsOnScroll) return;

            const scrollContainer = e.currentTarget;
            const maxScrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            if (maxScrollTop < 1) return;

            const scrollPercentage = (scrollContainer.scrollTop * 100) / (scrollContainer.scrollHeight - scrollContainer.clientHeight);

            if (scrollPercentage > 90) {
                appendItems();
            }
        };

        if (notifications && !notifications.length)
            return (
                <StackLayout orientation="vertical" className="k-text-center" align={{ horizontal: 'center', vertical: 'top' }}>
                    <span className="k-mt-6 k-mb-6">
                        <img src={bellAnimation} alt="No notifications" />
                    </span>
                    <P className="!k-mb-6">
                        Nothing to see now!
                        <br />
                        Check back later.
                    </P>
                </StackLayout>
            );

        return (
            <div className="notifications-scroll-container" onScroll={onNotificationsScroll}>
                {notifications?.map(n => (
                    <TopNavNotification key={n.id} notification={n} onClick={e => onNotificationClick(e, n)} hide={hide} />
                ))}
                {loading && (
                    <div className="k-text-center k-p-2">
                        <LoadingIndicator size="big" />
                    </div>
                )}
            </div>
        );
    }
);

function TopNavNotification({ notification, onClick, hide }: { notification: Notification; onClick: (e: React.MouseEvent<Element>) => any; hide: Function }) {
    const currentDate = new Date();
    const Presenter = notificationPresentersMap[notification.event.type];
    if (!Presenter) return null;

    const hasActions = notification.actions && !!notification.actions.length && !!notificationActionsMap[notification.event.type];

    return (
        <div onClick={onClick} className={`top-nav-notification${notification.read ? '' : ' top-nav-notification-unread'}`} draggable="true">
            <StackLayout className="k-gap-2" orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
                <StackLayout className="k-gap-2" align={{ horizontal: 'start', vertical: 'top' }}>
                    <div className="k-flex-auto">
                        <div className="k-fs-sm k-icp-subtle-text">{dateTimeService.stringifyDuration(currentDate, notification.timestamp)}</div>
                        <div>
                            <Presenter {...notification.event.data} />
                        </div>
                    </div>

                    {notification.initiator && (
                        <div>
                            <UserAvatar
                                picture={notification.initiator.picture}
                                initials={generateInitials(notification.initiator, 2)}
                                colorIndex={getPreferredColorIndex(notification.initiator)}
                            />
                        </div>
                    )}
                </StackLayout>
                {hasActions && (
                    <StackLayout className="k-gap-2" align={{ horizontal: 'start', vertical: 'middle' }}>
                        {React.createElement(notificationActionsMap[notification.event.type]!, { notificationsData: notification.event.data, hide })}
                    </StackLayout>
                )}
            </StackLayout>
        </div>
    );
}

function InviteIssuedNotificationPresenter({
    creatorFirstName,
    creatorLastName,
    idea,
    role
}: {
    creatorFirstName: string;
    creatorLastName: string;
    idea: string;
    role: UserRole;
}) {
    return (
        <>
            <strong>
                {creatorFirstName} {creatorLastName}
            </strong>{' '}
            invited you to join the <strong>“{idea}”</strong> startup as {role === UserRole.Viewer ? 'a' : 'an'} {role}.
        </>
    );
}

function InviteAcceptedNotificationPresenter({
    acceptorFirstName,
    acceptorLastName,
    idea
}: {
    acceptorFirstName: string;
    acceptorLastName: string;
    idea: string;
}) {
    return <InviteAcceptedDeclinedNotificationPresenter firstName={acceptorFirstName} lastName={acceptorLastName} idea={idea} accepted={true} />;
}

function InviteDeclinedNotificationPresenter({
    declinerFirstName,
    declinerLastName,
    idea
}: {
    declinerFirstName: string;
    declinerLastName: string;
    idea: string;
}) {
    return <InviteAcceptedDeclinedNotificationPresenter firstName={declinerFirstName} lastName={declinerLastName} idea={idea} accepted={false} />;
}

function InviteAcceptedDeclinedNotificationPresenter({
    firstName,
    lastName,
    idea,
    accepted
}: {
    firstName: string;
    lastName: string;
    idea: string;
    accepted: boolean;
}) {
    return (
        <>
            <strong>
                {firstName} {lastName}
            </strong>{' '}
            {accepted ? 'accepted' : 'declined'} your invitation to join the <strong>“{idea}”</strong> startup.
        </>
    );
}

function IdeaDeletedNotificationPresenter({ deleterFirstName, deleterLastName, idea }: { deleterFirstName: string; deleterLastName: string; idea: string }) {
    return (
        <>
            <strong>
                {deleterFirstName} {deleterLastName}
            </strong>{' '}
            deleted the <strong>“{idea}”</strong> startup, which you were a member of.
        </>
    );
}

function IdeaLeftNotificationPresenter({ leaverFirstName, leaverLastName, idea }: { leaverFirstName: string; leaverLastName: string; idea: string }) {
    return (
        <>
            <strong>
                {leaverFirstName} {leaverLastName}
            </strong>{' '}
            left the <strong>“{idea}”</strong> startup, which you are an owner of.
        </>
    );
}

function MembershipRevokedNotificationPresenter({
    membershipEditorFirstName,
    membershipEditorLastName,
    idea
}: {
    membershipEditorFirstName: string;
    membershipEditorLastName: string;
    idea: string;
}) {
    return (
        <>
            <strong>
                {membershipEditorFirstName} {membershipEditorLastName}
            </strong>{' '}
            has revoked your membership in the <strong>“{idea}”</strong> startup.
        </>
    );
}

function MembershipChangedNotificationPresenter({
    membershipEditorFirstName,
    membershipEditorLastName,
    role,
    idea
}: {
    membershipEditorFirstName: string;
    membershipEditorLastName: string;
    role: UserRole;
    idea: string;
}) {
    return (
        <>
            <strong>
                {membershipEditorFirstName} {membershipEditorLastName}
            </strong>{' '}
            has set your membership level to {role} in the <strong>“{idea}”</strong> startup.
        </>
    );
}

enum InvitationState {
    Valid,
    Invalid,
    Accepted,
    Expired,
    Declined
}

function AcceptDeclineInvitationActions({
    notificationsData,
    hide,
    onActionExecuted
}: {
    notificationsData: Record<string, string>;
    hide?: Function;
    onActionExecuted?: Function;
}) {
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState(InvitationState.Valid);
    const ideaRef = useRef<Idea>();
    const navigate = useNavigate();
    const inviteSecret = notificationsData['inviteSecret'];
    const dispatch = useAppDispatch();

    const getInviteState = useCallback(async (secret: string): Promise<InvitationState> => {
        if (!secret) return InvitationState.Invalid;
        let invite: Invite | undefined = undefined;
        try {
            invite = await ideasService.getInvite(secret);
        } catch {
            return InvitationState.Invalid;
        }

        if (!invite) return InvitationState.Invalid;
        if (invite.expires < new Date()) return InvitationState.Expired;
        if (invite.state === InviteState.Declined) return InvitationState.Declined;
        if (invite.state === InviteState.Accepted) return InvitationState.Accepted;
        if (invite.state !== InviteState.Pending) return InvitationState.Invalid;
        if (!invite.idea) return InvitationState.Invalid;
        ideaRef.current = invite.idea;

        return InvitationState.Valid;
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const inviteState = await getInviteState(inviteSecret);
                setState(inviteState);
            } catch {
                setState(InvitationState.Invalid);
            } finally {
                setLoading(false);
            }
        })();
    }, [getInviteState, inviteSecret]);

    const acceptInvitation = async () => {
        setLoading(true);
        try {
            await ideasService.acceptInvite(inviteSecret);
            setState(InvitationState.Accepted);
            if (ideaRef.current) {
                hide?.();
                const linkToIdea = ideasService.getStartupRedirectUrl(ideaRef.current);
                dispatch(
                    addNotification(
                        {
                            htmlContent: `You have joined <span class="k-font-weight-semibold">${ideaRef.current.title}</span>`,
                            actionText: 'View startup'
                        },
                        () => {
                            navigate(linkToIdea);
                        }
                    )
                );
                appEventHub.trigger('invitation', 'accepted', { ideaId: ideaRef.current.uniqueId });
            }
        } catch (error) {
            if (error instanceof HttpException && error.status === 404) {
                const updatedState = await getInviteState(inviteSecret);
                setState(updatedState);
                return;
            }
            setState(InvitationState.Invalid);
            throw error;
        } finally {
            onActionExecuted?.();
            setLoading(false);
        }
    };

    const declineInvitation = async () => {
        setLoading(true);
        try {
            await ideasService.declineInvite(inviteSecret);
            setState(InvitationState.Declined);
        } catch (error) {
            if (error instanceof HttpException && error.status === 404) {
                const updatedState = await getInviteState(inviteSecret);
                setState(updatedState);
                return;
            }
            setState(InvitationState.Invalid);
            throw error;
        } finally {
            onActionExecuted?.();
            setLoading(false);
        }
    };

    if (loading) return <LoadingIndicator size="small" className="loading-inline-md" />;

    if (state === InvitationState.Valid)
        return (
            <>
                <Button fillMode="outline" themeColor="secondary" onClick={acceptInvitation}>
                    Accept
                </Button>
                <Button fillMode="outline" themeColor="base" onClick={declineInvitation}>
                    Decline
                </Button>
            </>
        );

    const infoClassName =
        state === InvitationState.Accepted
            ? 'k-icp-bg-success-8-solid'
            : state === InvitationState.Invalid || state === InvitationState.Expired || state === InvitationState.Declined
            ? 'k-icp-bg-warning-8-solid'
            : undefined;

    const infoText =
        state === InvitationState.Accepted
            ? 'Invitation accepted'
            : state === InvitationState.Declined
            ? 'Invitation declined'
            : state === InvitationState.Expired
            ? 'Invitation expired'
            : state === InvitationState.Invalid
            ? 'Invitation not valid'
            : undefined;
    const IconType =
        state === InvitationState.Accepted
            ? AcceptedInviteIcon
            : state === InvitationState.Invalid || state === InvitationState.Declined || state === InvitationState.Expired
            ? InvalidInviteIcon
            : undefined;
    if (infoText)
        return (
            <span className={`k-button k-button-md k-button-link k-no-click k-rounded-md ${infoClassName}`}>
                {IconType && <IconType className="k-icp-icon k-icp-icon-size-4" />} {infoText}
            </span>
        );

    return null;
}
