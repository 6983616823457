import { useEffect, useState } from 'react';
import { useAsRef } from '../../hooks/commonHooks';

export function TypeText({
    children: text,
    mode,
    reserveSpace,
    waitForMore,
    onTypeEnd,
    paused
}: {
    children?: string;
    mode?: 'letter' | 'word';
    reserveSpace?: boolean;
    waitForMore?: boolean;
    onTypeEnd?: (typedIndex: number) => void;
    paused?: boolean;
}) {
    const [revealToIndex, setRevealToIndex] = useState(0);

    const revealToIndexRef = useAsRef(revealToIndex);
    const onTypedEndRef = useAsRef(onTypeEnd);
    useEffect(() => {
        if (!text || paused) return;

        const splitByLetter = mode === 'letter';
        const typingInterval = splitByLetter ? 10 : 50;

        const typingIntervalId = setInterval(() => {
            if (revealToIndexRef.current >= text.length) {
                if (waitForMore) return;
                clearInterval(typingIntervalId);
                onTypedEndRef.current?.(revealToIndexRef.current);
                return;
            }

            if (splitByLetter) {
                setRevealToIndex(revealToIndexRef.current + 1);
                return;
            }

            const nextEndOfWordIndex = text.indexOf(' ', revealToIndexRef.current + 1);
            const nextRevealToIndex = nextEndOfWordIndex === -1 ? text.length : nextEndOfWordIndex;
            setRevealToIndex(nextRevealToIndex);
        }, typingInterval);

        return () => clearInterval(typingIntervalId);
    }, [mode, onTypedEndRef, paused, revealToIndexRef, text, waitForMore]);

    if (!text) return null;

    const visibleText = text.substring(0, revealToIndex);
    const hiddenText = text.substring(revealToIndex);

    return (
        <>
            {visibleText}
            {reserveSpace && hiddenText && <span className="k-visibility-invisible">{hiddenText}</span>}
        </>
    );
}
