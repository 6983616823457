import { Button } from '@progress/kendo-react-buttons';
import { StackLayout, StackLayoutHandle } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { ReactComponent as AILoader } from '../../icons/ai-loader.svg';
import { ReactComponent as SuggestedQuestionIcon } from '../../icons/help-circle.svg';
import { ReactComponent as MaximizeIcon } from '../../icons/maximize-2.svg';
import { ReactComponent as MinimizeIcon } from '../../icons/minimize-2.svg';
import { ReactComponent as SendIcon } from '../../icons/send-alt.svg';
import aiLogoSrc from '../../images/ai-logo.svg';
import { combineClassNames, immutableAdd } from '../../services/common';
import { AIConversationMessage } from '../../services/experiments/interviewCollectionsService';
import { useAppSelector } from '../../state/hooks';
import { InterviewMessageBox } from '../interview/entries/interviewMessageLog';
import { H3 } from '../ui/typography';

export function AIChatPanel({
    placeholder,
    suggestedQuestions,
    disabled,
    getAnswer,
    formatMessage
}: {
    placeholder?: string;
    disabled?: boolean;
    suggestedQuestions?: string[];
    getAnswer?: (conversation: AIConversationMessage[]) => AsyncIterable<string>;
    formatMessage?: (role: AIConversationMessage['role'], message: string) => string;
}) {
    const [isMaximized, setIsMaximized] = useState<boolean>();
    const [messages, setMessages] = useState<AIConversationMessage[]>([]);
    const currentUser = useAppSelector(s => s.user);
    const [questionText, setQuestionText] = useState<string>('');
    const [answerInProgress, setAnswerInProgress] = useState<string>();
    const messagesWrapperRef = useRef<StackLayoutHandle>(null);

    const formattedAnswerInProgress = formatMessage && answerInProgress ? formatMessage('assistant', answerInProgress) : answerInProgress;

    useEffect(() => {
        if (!isMaximized) return;

        function minimizeOnEscape(e: KeyboardEvent) {
            if (e.key !== 'Escape') return;
            setIsMaximized(false);
        }

        window.addEventListener('keydown', minimizeOnEscape);

        return () => window.removeEventListener('keydown', minimizeOnEscape);
    }, [isMaximized]);

    useEffect(() => {
        const messagesWrapperElement = messagesWrapperRef.current?.element;
        if (!messagesWrapperElement) return;

        if (messagesWrapperElement.scrollHeight > messagesWrapperElement.clientHeight) {
            const maxScrollTop = messagesWrapperElement.scrollHeight - messagesWrapperElement.clientHeight;
            if (messagesWrapperElement.scrollTop < maxScrollTop) messagesWrapperElement.scrollTop = maxScrollTop;
        }
    }, [messages, formattedAnswerInProgress]);

    const [isQuestionInProgress, askQuestion] = useSingleClickCallback(async function askQuestion(question: string) {
        if (!question) return;

        const newUserMessage: AIConversationMessage = { role: 'user', content: question };
        const updatedMessages = immutableAdd(messages, newUserMessage);
        setMessages(updatedMessages);
        let aggregatedAnswer = '';
        if (getAnswer)
            for await (const answerToken of getAnswer(updatedMessages)) {
                aggregatedAnswer += answerToken;
                setAnswerInProgress(aggregatedAnswer);
            }

        setAnswerInProgress(undefined);
        if (aggregatedAnswer) setMessages(messages => immutableAdd(messages, { role: 'assistant', content: aggregatedAnswer }));
    });

    const panelsDisabled = isQuestionInProgress || disabled;

    async function askCurrentQuestion() {
        setQuestionText('');
        await askQuestion?.(questionText);
    }

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className={combineClassNames(
                'k-rounded k-icp-panel-base k-p-4 k-gap-4',
                isMaximized ? 'k-pos-fixed k-top-0 k-left-0 k-w-full k-h-full -zi9999' : 'k-pos-sticky k-top-0'
            )}
        >
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-justify-content-between">
                <H3>Ask IVA</H3>

                <Button type="button" size="small" fillMode="flat" className="k-icp-svg-icon-button" onClick={() => setIsMaximized(m => !m)}>
                    {isMaximized ? <MinimizeIcon className="k-icp-icon" /> : <MaximizeIcon className="k-icp-icon" />}
                </Button>
            </StackLayout>

            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'stretch', vertical: 'top' }}
                className={combineClassNames('k-p-4 k-panel k-flex-1', messages.length ? 'k-gap-4' : 'k-gap-13')}
                style={{ minHeight: 320, maxHeight: isMaximized ? undefined : 500 }}
            >
                <StackLayout
                    ref={messagesWrapperRef}
                    orientation="vertical"
                    align={{ horizontal: 'stretch', vertical: 'top' }}
                    className="k-gap-4 k-flex-1 k-overflow-auto"
                >
                    {messages.length ? (
                        <>
                            {messages.map((message, messageIndex) => (
                                <InterviewMessageBox
                                    key={messageIndex}
                                    author={message.role === 'user' ? currentUser ?? undefined : undefined}
                                    right={message.role === 'user'}
                                    secondary={message.role === 'user'}
                                    messageBoxClassName={message.role === 'assistant' ? 'k-icp-panel-base -maxw-160' : undefined}
                                >
                                    {formatMessage ? formatMessage(message.role, message.content) : message.content}
                                </InterviewMessageBox>
                            ))}
                            {isQuestionInProgress &&
                                (formattedAnswerInProgress ? (
                                    <InterviewMessageBox messageBoxClassName="k-icp-panel-base -maxw-160">{formattedAnswerInProgress}</InterviewMessageBox>
                                ) : (
                                    <AILoader className="k-align-self-center k-shrink-0" />
                                ))}
                        </>
                    ) : (
                        <>
                            <img src={aiLogoSrc} alt="IVA logo" width="48" height="48" className="k-align-self-center" />
                            {Boolean(suggestedQuestions?.length) && (
                                <StackLayout orientation="vertical" className="k-gap-2">
                                    {suggestedQuestions!.map((suggestedQuestion, suggestedQuestionIndex) => (
                                        <div
                                            key={suggestedQuestionIndex}
                                            className={combineClassNames(
                                                'k-stack-layout k-hstack k-justify-content-start k-align-items-start k-icp-panel k-p-4 k-gap-2 k-button-solid-base',
                                                panelsDisabled ? 'k-disabled' : 'k-cursor-pointer'
                                            )}
                                            onClick={panelsDisabled ? undefined : () => askQuestion?.(suggestedQuestion)}
                                        >
                                            <SuggestedQuestionIcon className="k-icp-icon k-icp-icon-size-4 k-flex-shrink-0" />
                                            <div className="k-fs-sm">{suggestedQuestion}</div>
                                        </div>
                                    ))}
                                </StackLayout>
                            )}
                        </>
                    )}
                </StackLayout>

                <div className="k-textbox k-input k-input-solid k-rounded-full k-input-md k-shrink-0">
                    <input
                        className="k-input-inner"
                        placeholder={placeholder}
                        value={questionText}
                        onChange={e => setQuestionText(e.target.value)}
                        disabled={panelsDisabled}
                        onKeyDown={e => e.key === 'Enter' && askCurrentQuestion()}
                    />
                    <span className="k-input-suffix">
                        <Button
                            type="button"
                            fillMode="flat"
                            rounded="full"
                            className="k-icp-svg-icon-button !k-border-none k-mr-2"
                            disabled={panelsDisabled || !questionText}
                            onClick={askCurrentQuestion}
                        >
                            <SendIcon className="k-icp-icon" />
                        </Button>
                    </span>
                </div>
            </StackLayout>
        </StackLayout>
    );
}
