import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useMemo } from 'react';
import { useInProgressOperationsTracker } from '../../../hooks/commonHooks';
import {
    InterviewCollection,
    InterviewCollectionGoal,
    interviewCollectionsService,
    MinimalInterviewCollection
} from '../../../services/experiments/interviewCollectionsService';
import { FileSelector, maxLengthValidator, minLengthValidator, requiredValidator, useFileSelectorValidator, ValidatedInput } from '../../ui/inputs';
import { allowedInterviewFileExtensions, interviewCollectionGoalLabelsMap, maxInterviewFileSizeInBytes } from './common';

const emptyInterviewsErrorMessage = 'Interviews files are required';
const interviewCollectionNameValidators = [requiredValidator('Name'), maxLengthValidator('Name', 150)];
const otherGoalValidators = [requiredValidator('Other type'), maxLengthValidator('Other type', 150)];
const objectiveValidators = [maxLengthValidator('Objective', 1000)];

export const interviewCollectionGoalDropDownValues = [
    InterviewCollectionGoal.ProblemValidation,
    InterviewCollectionGoal.BuyerPersona,
    InterviewCollectionGoal.WinLossAnalysis,
    InterviewCollectionGoal.Other
].map(goal => ({ text: interviewCollectionGoalLabelsMap[goal], value: goal }));

export function InterviewCollectionFields({ uploadInterviews, freeTextGoal }: { uploadInterviews?: boolean; freeTextGoal?: boolean }) {
    const [interviewsFilesValidator, interviewsFilesErrors] = useFileSelectorValidator(allowedInterviewFileExtensions, maxInterviewFileSizeInBytes);
    const interviewsFilesValidators = useMemo(
        () => [interviewsFilesValidator, requiredValidator(emptyInterviewsErrorMessage, false), minLengthValidator(emptyInterviewsErrorMessage, 1, false)],
        [interviewsFilesValidator]
    );
    const [interviewScriptValidator, interviewScriptFilesErrors] = useFileSelectorValidator(allowedInterviewFileExtensions, maxInterviewFileSizeInBytes);

    return (
        <>
            <Field
                name="name"
                component={ValidatedInput}
                label="Interviews collection name"
                validator={interviewCollectionNameValidators}
                maxLength={150}
                placeholder="Type a name..."
            />

            <div>
                <Field
                    name="goal"
                    component={ValidatedInput}
                    label="Type of interviews"
                    inputType={DropDownList}
                    data={interviewCollectionGoalDropDownValues}
                    textField="text"
                    dataItemKey="value"
                />

                {freeTextGoal && (
                    <Field
                        name="otherGoal"
                        wrapperClass="k-mt-2"
                        component={ValidatedInput}
                        placeholder="Specify other type..."
                        validator={otherGoalValidators}
                        maxLength={150}
                    />
                )}
            </div>

            {uploadInterviews && (
                <Field
                    name="files"
                    component={ValidatedInput}
                    inputType={FileSelector}
                    label="Upload all interview files"
                    labelClassName="!k-mb-thin"
                    multiple
                    acceptFileExtensions={allowedInterviewFileExtensions.join(', ')}
                    description="IVA provides better results when working with full transcripts, but will analyze all files you input and help you get the most out of every bit of data you have."
                    validator={interviewsFilesValidators}
                    fileErrors={interviewsFilesErrors}
                    hideErrorMessage={Boolean(interviewsFilesErrors)}
                />
            )}

            {uploadInterviews && (
                <Field
                    name="script"
                    component={ValidatedInput}
                    inputType={FileSelector}
                    label="Upload interview script (optional)"
                    labelClassName="!k-mb-thin"
                    acceptFileExtensions={allowedInterviewFileExtensions.join(', ')}
                    description="Upload file with questions you have prepared for the interview. IVA will provide you feedback how well you have covered them during the interview."
                    validator={interviewScriptValidator}
                    fileErrors={interviewScriptFilesErrors}
                    hideErrorMessage={Boolean(interviewScriptFilesErrors)}
                />
            )}

            <div>
                <Label editorId="objective" className="!k-mb-thin">
                    What do you want to learn?
                </Label>
                <div className="k-icp-subtle-text k-fs-sm k-mb-2">Write down the questions you seek answers to.</div>
                <Field
                    name="objective"
                    component={ValidatedInput}
                    inputType={TextArea}
                    rows={4}
                    maxLengthCount={1000}
                    placeholder="Type here..."
                    validator={objectiveValidators}
                />
            </div>
        </>
    );
}

export function EditInterviewCollectionModal({
    ideaId,
    collection,
    onUpdated,
    onCancel
}: {
    ideaId: string;
    collection: InterviewCollection | MinimalInterviewCollection;
    onUpdated?: (collection: InterviewCollection) => void;
    onCancel?: () => void;
}) {
    const [isSubmitInProgress, trackFormSubmit] = useInProgressOperationsTracker();
    async function onUpdateCollectionSubmit(data: Record<string, any>) {
        const updatedCollection = await interviewCollectionsService.updateInterviewCollection(ideaId, collection.id, { name: data.name });
        onUpdated?.(updatedCollection);
    }

    return (
        <Dialog title="Rename collection" width={480} className="k-icp-dialog-no-padding k-icp-dialog-with-form" onClose={onCancel}>
            <Form
                initialValues={{
                    name: collection.name
                }}
                ignoreModified
                onSubmit={trackFormSubmit(onUpdateCollectionSubmit)}
                render={formRenderProps => (
                    <FormElement noValidate className="k-icp-component-border">
                        <div className="k-window-content k-dialog-content">
                            <Field
                                name="name"
                                component={ValidatedInput}
                                label="Collection name"
                                validator={interviewCollectionNameValidators}
                                maxLength={150}
                                placeholder="Type a name..."
                            />
                        </div>
                        <DialogActionsBar layout="center">
                            <Button disabled={!formRenderProps.allowSubmit || isSubmitInProgress} type="submit" themeColor="primary">
                                Rename collection
                            </Button>
                            <Button type="reset" onClick={onCancel}>
                                Cancel
                            </Button>
                        </DialogActionsBar>
                    </FormElement>
                )}
            />
        </Dialog>
    );
}
