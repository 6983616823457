import { Card, CardBody, CardSubtitle, CardTitle, StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BoundDropDownButton, DropDownButtonItem } from '../../../components/common/boundDropDownButton';
import { interviewCollectionGoalLabelsMap } from '../../../components/experiments/interviewCollections/common';
import { EditInterviewCollectionModal } from '../../../components/experiments/interviewCollections/editInterviewCollection';
import { InterviewCollectionUploadTypeList } from '../../../components/experiments/interviewCollections/interviewCollectionUploadTypeList';
import { StartupMembershipList } from '../../../components/startup/startupMembershipList';
import LoadingIndicator from '../../../components/ui/loadingIndicator';
import { SvgIconButtonContent } from '../../../components/ui/svgIconButtonContent';
import { TextMarker } from '../../../components/ui/textMarker';
import { H1 } from '../../../components/ui/typography';
import { useConfirmDialog } from '../../../hooks/dialogHooks';
import { useIdeaParams } from '../../../hooks/routerHooks';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { immutableAddOrUpdate, immutableRemove } from '../../../services/common';
import { dateTimeService } from '../../../services/dateTimeService';
import { InterviewCollectionGoal, interviewCollectionsService, MinimalInterviewCollection } from '../../../services/experiments/interviewCollectionsService';
import { buildUserViewModel, UserRole } from '../../../services/usersService';
import { useAppSelector } from '../../../state/hooks';

export function InterviewCollectionsPage() {
    const { ideaId } = useIdeaParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canManageInterviewCollections = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const navigate = useNavigate();

    const [interviewCollections, setInterviewCollections] = useState<MinimalInterviewCollection[]>();
    const { show: showDeleteDialog, element: deleteDialog } = useConfirmDialog();

    const [interviewCollectionToEditId, setInterviewCollectionToEditId] = useState<number>();
    const interviewCollectionToEdit = useMemo(
        () =>
            interviewCollections && interviewCollectionToEditId !== undefined
                ? interviewCollections.find(c => c.id === interviewCollectionToEditId)
                : undefined,
        [interviewCollectionToEditId, interviewCollections]
    );

    useEffect(() => {
        interviewCollectionsService.getInterviewCollections(ideaId).then(setInterviewCollections);
    }, [ideaId]);

    return (
        <StackLayout
            orientation="vertical"
            align={
                interviewCollections && interviewCollections.length ? { horizontal: 'stretch', vertical: 'top' } : { horizontal: 'center', vertical: 'middle' }
            }
            className="-minh100"
        >
            {interviewCollections ? (
                interviewCollections.length ? (
                    <>
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row">
                            <TextMarker text="Alpha">
                                <H1>Interviews Collections</H1>
                            </TextMarker>
                            {canManageInterviewCollections && (
                                <>
                                    <div className="vertical-separator" />
                                    <Link to="create" className="k-button k-button-lg k-button-solid k-button-solid-primary k-rounded-md">
                                        <SvgIconButtonContent icon={PlusIcon}>New collection</SvgIconButtonContent>
                                    </Link>
                                </>
                            )}
                        </StackLayout>
                        <InterviewCollectionsList
                            collections={interviewCollections}
                            onOpen={collectionId => navigate(String(collectionId))}
                            onDelete={
                                canManageInterviewCollections
                                    ? collectionId => {
                                          const collectionToDelete = interviewCollections.find(c => c.id === collectionId);
                                          if (!collectionToDelete) return;

                                          showDeleteDialog({
                                              title: 'Delete interview collection',
                                              content: (
                                                  <>
                                                      Are you sure you want to delete <strong>“{collectionToDelete.name}”</strong>?
                                                  </>
                                              ),
                                              confirmCheckboxText: 'I acknowledge that this cannot be undone',
                                              confirmButtonText: 'Delete interview collection',
                                              callback: async () => {
                                                  await interviewCollectionsService.deleteInterviewCollection(ideaId, collectionId);
                                                  setInterviewCollections(collections => immutableRemove(collections, i => i.id !== collectionId));
                                              }
                                          });
                                      }
                                    : undefined
                            }
                            onRename={canManageInterviewCollections ? setInterviewCollectionToEditId : undefined}
                        />
                        {deleteDialog}
                        {interviewCollectionToEdit && (
                            <EditInterviewCollectionModal
                                ideaId={ideaId}
                                collection={interviewCollectionToEdit}
                                onCancel={() => setInterviewCollectionToEditId(undefined)}
                                onUpdated={collection => {
                                    setInterviewCollections(interviewCollections =>
                                        immutableAddOrUpdate(interviewCollections, collection, c => c.id === collection.id)
                                    );
                                    setInterviewCollectionToEditId(undefined);
                                }}
                            />
                        )}
                    </>
                ) : (
                    <div className="k-max-w-full">
                        <H1 className="k-text-center !k-mb-12">Where do you want to start from?</H1>
                        <InterviewCollectionUploadTypeList disabled={!canManageInterviewCollections} renderLinks />
                    </div>
                )
            ) : (
                <LoadingIndicator size="big" />
            )}
        </StackLayout>
    );
}

function InterviewCollectionsList({
    collections,
    onOpen,
    onRename,
    onDelete
}: {
    collections: MinimalInterviewCollection[];
    onOpen: (collectionId: number) => void;
    onRename?: (collectionId: number) => void;
    onDelete?: (collectionId: number) => void;
}) {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const clickedElement = e.target as HTMLElement;
        if (!clickedElement) return;
        if (!e.currentTarget.contains(clickedElement) || clickedElement.closest('.card-actions-area')) e.preventDefault();
    };

    return (
        <div className="cards-relaxed-grid">
            {collections.map(collection => (
                <Link key={collection.id} className="k-link" to={`${collection.id}`} onClick={onClick}>
                    <InterviewCollectionCard
                        collection={collection}
                        onOpen={() => onOpen(collection.id)}
                        onRename={onRename && (() => onRename(collection.id))}
                        onDelete={onDelete && (() => onDelete(collection.id))}
                    />
                </Link>
            ))}
        </div>
    );
}

function InterviewCollectionCard({
    collection,
    onOpen,
    onRename,
    onDelete
}: {
    collection: MinimalInterviewCollection;
    onOpen: () => void;
    onRename?: () => void;
    onDelete?: () => void;
}) {
    const actions: DropDownButtonItem[] = [];
    actions.push({ text: 'Open', action: onOpen });
    actions.push({ text: 'Rename', action: onRename, disabled: !onRename });
    actions.push({ text: 'Delete', danger: true, separated: true, action: onDelete, disabled: !onDelete });

    return (
        <Card className="k-icp-interactive-card">
            <CardBody>
                <CardTitle className="max-lines-2">{collection.name}</CardTitle>
                <CardSubtitle className="!k-mb-4 !k-fs-md">
                    {collection.goal === InterviewCollectionGoal.Other ? collection.otherGoal : interviewCollectionGoalLabelsMap[collection.goal]}
                </CardSubtitle>
                <StackLayout align={{ vertical: 'bottom', horizontal: 'start' }} className="k-justify-content-between k-gap-4 k-flex-nowrap">
                    {collection.createdBy ? (
                        <PopupPropsContext.Provider value={p => ({ ...p, appendTo: document.body })}>
                            <StartupMembershipList users={[buildUserViewModel(collection.createdBy)]} />
                        </PopupPropsContext.Provider>
                    ) : (
                        <div />
                    )}

                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        <span className="k-fs-sm k-icp-subtle-text">Edited {dateTimeService.stringifyDuration(new Date(), collection.lastActivity)}</span>
                        <BoundDropDownButton
                            fillMode="flat"
                            size="small"
                            icon="more-horizontal"
                            popupSettings={{
                                anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                                popupAlign: { horizontal: 'right', vertical: 'top' }
                            }}
                            items={actions}
                            className="card-actions-area"
                        />
                    </StackLayout>
                </StackLayout>
            </CardBody>
        </Card>
    );
}
