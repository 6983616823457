import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { ReactComponent as FileIcon } from '../../../icons/file-text.svg';
import { CollectionInterviewFile, interviewCollectionsService } from '../../../services/experiments/interviewCollectionsService';
import LoadingIndicator from '../../ui/loadingIndicator';

export function CollectionInterviewFilesList({
    ideaId,
    collectionId,
    interviewId,
    files,
    interviewScriptFileId
}: {
    ideaId: string;
    collectionId: number;
    interviewId: number;
    files: CollectionInterviewFile[];
    interviewScriptFileId?: number;
}) {
    const [fileToView, setFileToView] = useState<CollectionInterviewFile>();

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {files.map(file => (
                <StackLayout
                    key={file.id}
                    align={{ horizontal: 'start', vertical: 'middle' }}
                    className="k-border k-border-solid k-icp-component-border k-icp-panel-base k-rounded k-px-2 k-py-1 k-gap-2"
                >
                    <FileIcon className="k-icp-icon k-icp-icon-size-4 k-shrink-0" />
                    <div className="k-icp-line-height-inline-md k-flex-1">{file.name}</div>
                    <Button type="button" themeColor="secondary" fillMode="link" size="small" onClick={() => setFileToView(file)} className="!k-min-w-0">
                        Open
                    </Button>
                </StackLayout>
            ))}

            {fileToView && (
                <CollectionInterviewFileModal
                    ideaId={ideaId}
                    collectionId={collectionId}
                    interviewId={interviewId}
                    file={fileToView}
                    onClose={() => setFileToView(undefined)}
                    isInterviewScript={interviewScriptFileId === fileToView.id}
                />
            )}
        </StackLayout>
    );
}

function CollectionInterviewFileModal({
    ideaId,
    collectionId,
    interviewId,
    file,
    isInterviewScript,
    onClose
}: {
    ideaId: string;
    collectionId: number;
    interviewId: number;
    file: CollectionInterviewFile;
    isInterviewScript?: boolean;
    onClose?: () => void;
}) {
    const [fileContent, setFileContent] = useState<string>();

    useEffect(() => {
        const getContentPromise = isInterviewScript
            ? interviewCollectionsService.getInterviewScriptContent(ideaId, collectionId, interviewId)
            : interviewCollectionsService.getInterviewFileContent(ideaId, collectionId, interviewId);
        getContentPromise.then(setFileContent);
    }, [collectionId, ideaId, interviewId, isInterviewScript]);

    return (
        <Dialog title={`File: ${file.name}`} onClose={onClose} width={640}>
            {fileContent !== undefined ? (
                <div className="k-icp-panel k-icp-panel-base k-white-space-pre-wrap -bw k-p-4 k-min-h-full">{fileContent}</div>
            ) : (
                <LoadingIndicator size="big" className="k-display-block k-mx-auto" />
            )}
            <DialogActionsBar layout="center">
                <Button type="button" onClick={onClose}>
                    Close
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
