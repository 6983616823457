import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { Link, useNavigate } from 'react-router-dom';
import { InterviewFields } from '../../../components/experiments/interviewCollections/editInterview';
import { H1 } from '../../../components/ui/typography';
import { useInProgressOperationsTracker } from '../../../hooks/commonHooks';
import { useIdeaParams, useIntParam } from '../../../hooks/routerHooks';
import { combineClassNames } from '../../../services/common';
import { interviewCollectionsService, NewCollectionInterviewData } from '../../../services/experiments/interviewCollectionsService';
import { useAppSelector } from '../../../state/hooks';

export function AddInterviewPage() {
    const { ideaId } = useIdeaParams();
    const collectionId = useIntParam('collectionId');
    const currentUser = useAppSelector(s => s.user);
    const navigate = useNavigate();

    const [isSubmitInProgress, trackFormSubmit] = useInProgressOperationsTracker();
    async function onCreateInterviewSubmit(data: Record<string, any>) {
        const interviewData: NewCollectionInterviewData = {
            host: data.host,
            interviewee: data.interviewee,
            date: data.interviewDate
        };

        const newInterview = await interviewCollectionsService.createInterview(
            ideaId,
            collectionId,
            interviewData,
            data.interviewFile[0],
            data.interviewScript?.[0]
        );

        navigate(`../${collectionId}/interviews/${newInterview.id}`);
    }

    return (
        <>
            <H1 className="heading-row">Add interview</H1>
            {currentUser && (
                <div style={{ width: 576 }} className="k-mx-auto">
                    <Form
                        ignoreModified={true}
                        initialValues={{
                            interviewDate: new Date(),
                            host: `${currentUser.firstName} ${currentUser.lastName}`
                        }}
                        onSubmit={trackFormSubmit(onCreateInterviewSubmit)}
                        render={({ allowSubmit }) => (
                            <FormElement noValidate={true}>
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10">
                                    <InterviewFields />

                                    <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4 k-mt-4">
                                        <Button type="submit" themeColor="primary" size="large" disabled={!allowSubmit || isSubmitInProgress}>
                                            Save and analyze
                                        </Button>
                                        <Link
                                            to={`../${collectionId}`}
                                            className={combineClassNames(
                                                'k-button k-button-lg k-button-solid k-button-solid-base k-rounded-md',
                                                isSubmitInProgress ? 'k-disabled' : undefined
                                            )}
                                        >
                                            Cancel
                                        </Link>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        )}
                    />
                </div>
            )}
        </>
    );
}
