import { Button } from '@progress/kendo-react-buttons';
import { TextArea, TextAreaHandle, TextAreaProps } from '@progress/kendo-react-inputs';
import { forwardRef, KeyboardEvent, useImperativeHandle, useRef, useState } from 'react';
import { useElementResize } from '../../hooks/commonHooks';
import { ReactComponent as SendIcon } from '../../icons/send-alt-2.svg';
import { combineClassNames, isMobileAgent } from '../../services/common';
import { domService } from '../../services/domService';

interface ChatTextBoxProps {
    onSend: (message: string) => void;
    onResize?: (entries: ResizeObserverEntry[]) => void;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    textBoxAttributes?: TextAreaProps;
}

export interface ChatTextBoxHandle {
    focus: () => void;
}

export const ChatTextBox = forwardRef<ChatTextBoxHandle, ChatTextBoxProps>(function ChatTextBox(
    { onSend, placeholder = 'Type a message...', onResize, className, disabled, textBoxAttributes }: ChatTextBoxProps,
    ref
) {
    const [message, setMessage] = useState('');
    const textAreaKey = useRef(0);
    const textAreaRef = useRef<TextAreaHandle>(null);
    const isMobileUserAgent = isMobileAgent;
    const { elementRef } = useElementResize<HTMLDivElement>(entries => {
        onResize?.(entries);
    });

    useImperativeHandle(
        ref,
        () => ({
            focus: () => {
                const textAreaDomElement = textAreaRef.current?.element.current;
                if (!textAreaDomElement) return;

                domService.focusTextArea(textAreaDomElement);
            }
        }),
        []
    );

    const sendMessage = () => {
        if (message.trim()) {
            onSend(message);
            setMessage('');
            textAreaKey.current++;
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            if (e.shiftKey || isMobileUserAgent) {
                // Allow new line when Shift + Enter is pressed
                return;
            }

            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div ref={elementRef} className={combineClassNames('chat-message-textbox w-full k-pos-relative', className)}>
            <TextArea
                id="chat-area"
                key={textAreaKey.current}
                ref={textAreaRef}
                value={message}
                onChange={e => setMessage(e.value)}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                rounded={'large'}
                autoSize={true}
                disabled={disabled}
                className={combineClassNames('padded-textarea k-icp-no-focus-shadow', className)}
                {...textBoxAttributes}
            />
            <span className="k-pos-absolute k-right-2 k-bottom-1 k-z-10">
                <Button
                    type="button"
                    fillMode="flat"
                    themeColor="secondary"
                    disabled={disabled || !message.trim()}
                    className="k-icp-svg-icon-button !k-border-none !k-p-2"
                    onClick={sendMessage}
                >
                    <SendIcon className="k-icp-icon k-icp-fill-secondary" />
                </Button>
            </span>
        </div>
    );
});
