import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CollectionInterviewFilesList } from '../../../components/experiments/interviewCollections/collectionInterviewFilesList';
import { InterviewFields } from '../../../components/experiments/interviewCollections/editInterview';
import LoadingIndicator from '../../../components/ui/loadingIndicator';
import { H1 } from '../../../components/ui/typography';
import { useInProgressOperationsTracker } from '../../../hooks/commonHooks';
import { useIdeaParams, useIntParam } from '../../../hooks/routerHooks';
import { combineClassNames } from '../../../services/common';
import { CollectionInterview, interviewCollectionsService, UpdateCollectionInterviewData } from '../../../services/experiments/interviewCollectionsService';

export function EditInterviewPage() {
    const { ideaId } = useIdeaParams();
    const collectionId = useIntParam('collectionId');
    const interviewId = useIntParam('interviewId');
    const navigate = useNavigate();

    const [interview, setInterview] = useState<CollectionInterview>();
    useEffect(() => {
        interviewCollectionsService.getInterview(ideaId, collectionId, interviewId).then(setInterview);
    }, [collectionId, ideaId, interviewId]);

    const [isSubmitInProgress, trackFormSubmit] = useInProgressOperationsTracker();
    async function onUpdateInterviewSubmit(data: Record<string, any>) {
        if (!interview) return;

        const interviewData: UpdateCollectionInterviewData = {
            host: data.host,
            interviewee: data.interviewee,
            date: data.interviewDate
        };

        await interviewCollectionsService.updateInterview(
            ideaId,
            collectionId,
            interviewId,
            interviewData,
            !interview.script ? data.interviewScript?.[0] : undefined
        );

        navigate(`../${collectionId}/interviews/${interviewId}`);
    }

    return (
        <>
            <H1 className="heading-row">Edit interview</H1>
            {interview ? (
                <div style={{ width: 576 }} className="k-mx-auto">
                    <Form
                        ignoreModified={true}
                        initialValues={{
                            host: interview.host,
                            interviewee: interview.interviewee,
                            interviewDate: interview.date
                        }}
                        onSubmit={trackFormSubmit(onUpdateInterviewSubmit)}
                        render={({ allowSubmit }) => (
                            <FormElement noValidate={true}>
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10">
                                    <InterviewFields hideFiles hideScript={Boolean(interview.script)} />

                                    {interview.script && (
                                        <div>
                                            <Label>Interview script</Label>
                                            <CollectionInterviewFilesList
                                                ideaId={ideaId}
                                                collectionId={collectionId}
                                                interviewId={interviewId}
                                                files={[interview.script]}
                                                interviewScriptFileId={interview.script.id}
                                            />
                                        </div>
                                    )}

                                    <div>
                                        <Label>Interview file</Label>
                                        <CollectionInterviewFilesList
                                            ideaId={ideaId}
                                            collectionId={collectionId}
                                            interviewId={interviewId}
                                            files={[interview.file]}
                                        />
                                    </div>

                                    <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4 k-mt-4">
                                        <Button type="submit" themeColor="primary" size="large" disabled={!allowSubmit || isSubmitInProgress}>
                                            Save and analyze
                                        </Button>
                                        <Link
                                            to={`../${collectionId}/interviews/${interviewId}`}
                                            className={combineClassNames(
                                                'k-button k-button-lg k-button-solid k-button-solid-base k-rounded-md',
                                                isSubmitInProgress ? 'k-disabled' : undefined
                                            )}
                                        >
                                            Cancel
                                        </Link>
                                    </StackLayout>
                                </StackLayout>
                            </FormElement>
                        )}
                    />
                </div>
            ) : (
                <LoadingIndicator size="big" className="k-display-block k-mx-auto" />
            )}
        </>
    );
}
