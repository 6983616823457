import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { StackLayout, StackLayoutHandle } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import React, { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CanvasItemsZone } from '../../components/canvas/canvasItemsZone';
import { CanvasItemInContextSimpleView } from '../../components/canvas/customerSegmentInContextSimpleView';
import { BoundDropDownButton } from '../../components/common/boundDropDownButton';
import { useInfoTipDeactivation } from '../../components/idea/infoTip';
import { JourneyGetStartedDialog } from '../../components/journey/journeyGetStartedDialog';
import { StartupMembershipList } from '../../components/startup/startupMembershipList';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useCacheBust } from '../../hooks/commonHooks';
import { useStartupLayout } from '../../hooks/startupHooks';
import { ReactComponent as LockedTaskIcon } from '../../icons/lock.svg';
import { ReactComponent as TaskIcon } from '../../icons/task-alt.svg';
import journeyWelcomeAnimationUrl from '../../images/journey-welcome.svg';
import { ReactComponent as StartupCreatedIllustration } from '../../images/startup-created-illustration.svg';
import { JourneyPageWelcomeAnimator } from '../../scripts/journeyPageWelcomeAnimator';
import {
    JourneyNodesCoordinates,
    JourneyPhaseForkedTimelineDrawer,
    NodeLocation,
    TimelineNode,
    TimelineNodeCoordinates,
    TimelineNodeType
} from '../../scripts/journeyPhaseForkedTimelineDrawer';
import { StickyElementObserver } from '../../scripts/stickyElements';
import { BoxType } from '../../services/canvasService';
import { combineClassNames, countWhere, debounce, distinct } from '../../services/common';
import { domService } from '../../services/domService';
import { IdeaFlags, ideasService } from '../../services/ideasService';
import {
    JourneyNode,
    JourneyNodeType,
    JourneyPhase,
    JourneyTaskMarker,
    JourneyTaskNavigationHighlight,
    JourneyTaskStatus,
    journeyService
} from '../../services/journeyService';
import {
    RealTimeUpdateIdeaEventData,
    RealTimeUpdateTaskMarkerEventData,
    RealTimeUpdateTaskStatusEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { ReducedUserViewModel, UserRole, buildUserViewModel } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { loadIdeaById } from '../../state/idea/ideaSlice';
import { setRecentTasksSuppression } from '../../state/idea/recentTasksSlice';

export const JourneyPage = () => {
    const { ideaId } = useParams();
    const location = useLocation();

    const [journeyPhases, setJourneyPhases] = useState<JourneyPhase[] | undefined>();
    const [maxProgressPhaseIndex, setMaxProgressPhaseIndex] = useState<number | undefined>();
    const [maxProgressTaskIndexInPhase, setMaxProgressTaskIndexInPhase] = useState<number | undefined>();
    const [currentPhaseIndex, setCurrentPhaseIndex] = useState<number | undefined>();
    const [currentTaskLocationInPhase, setCurrentTaskLocationInPhase] = useState<NodeLocation | undefined>();
    const [taskVariationKeyToUsersMap, setTaskVariationKeyToUsersMap] = useState<Record<string, ReducedUserViewModel[]>>();
    const currentUserId = useAppSelector(s => s.user?.userId);
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEditTask = !!currentUserRole && currentUserRole !== UserRole.Viewer;
    const connectedIdeaUsersIds = useAppSelector(s => s.ideaMembers.membersActivity)?.map(m => m.user.userId) ?? [];
    if (currentUserId) connectedIdeaUsersIds.push(currentUserId);
    const scrolledToMainNodeRef = useRef(false);
    const scrollingWrapper = useRef<HTMLDivElement>(null);
    const journeyContentWrapperRef = useRef<HTMLDivElement>(null);
    const [hiddenZonesInPhases, setHiddenZonesInPhases] = useState<Record<number, number[]> | undefined>();
    const { setPageContainerClassName } = useStartupLayout();
    const dispatch = useAppDispatch();
    const locationState = location.state as any;
    const runAnimation: boolean = locationState && typeof locationState.animate === 'boolean' ? locationState.animate : false;
    const constAnimationCacheBust = useCacheBust();
    const [showAnimation, setShowAnimation] = useState(false);
    const navigate = useNavigate();
    const idea = useAppSelector(s => s.idea.current);
    const taskOneNode = journeyPhases?.[0]?.nodes.find(n => n.detail);
    const shouldShowJourneyModal = idea && !idea.flags.includes(IdeaFlags.JOURNEY_MODAL_SHOWN_FLAG) && idea.flags.includes(IdeaFlags.IVA_GENERATED_CANVAS_FLAG);

    useEffect(() => {
        setPageContainerClassName('k-pr-0 k-pb-0');

        return () => setPageContainerClassName(undefined);
    }, [setPageContainerClassName]);

    useEffect(() => {
        if (!ideaId) return;

        const loadJourney = async () => {
            const [ideaJourney, taskMarkers] = await Promise.all([journeyService.get(ideaId), journeyService.getTaskMarkers(ideaId)]);
            const { maxPhaseIndex, maxTaskNodeIndex, currentPhaseIndex, currentTaskNodeLocation } = getJourneyProgress(
                currentUserId,
                ideaJourney.phases,
                taskMarkers
            );

            const hiddenZonesInPhases = filterJourneyNodes(ideaJourney.phases, maxPhaseIndex ?? 0, maxTaskNodeIndex ?? 0);
            const taskToUsersMap: Record<string, ReducedUserViewModel[]> = {};
            taskMarkers.forEach(taskMarker => {
                const taskVariationKey = getTaskVariationKey(taskMarker.currentSequenceTag, taskMarker.currentTaskTag, taskMarker.currentTaskVariationTag);
                let usersInTask = taskToUsersMap[taskVariationKey];
                if (!usersInTask) usersInTask = taskToUsersMap[taskVariationKey] = [];
                usersInTask.push(buildUserViewModel(taskMarker.user));
            });

            flushSync(() => {
                setJourneyPhases(ideaJourney.phases);
                setMaxProgressPhaseIndex(maxPhaseIndex);
                setMaxProgressTaskIndexInPhase(maxTaskNodeIndex);
                setCurrentPhaseIndex(currentPhaseIndex);
                setCurrentTaskLocationInPhase(currentTaskNodeLocation);
                if (taskMarkers.length) setTaskVariationKeyToUsersMap(taskToUsersMap);
                setHiddenZonesInPhases(hiddenZonesInPhases);
            });
            if (scrollingWrapper.current && !stickyHeadersObservers.length) {
                const phaseTitleElements = scrollingWrapper.current.querySelectorAll<HTMLElement>('.journey-phase-title');
                const currentScrollingWrapper = scrollingWrapper.current;
                phaseTitleElements.forEach(e => {
                    if (e.classList.contains('k-text-disabled')) return;
                    const phaseHeaderObserver = new StickyElementObserver(e, currentScrollingWrapper, 'journey-phase-title-stuck');
                    phaseHeaderObserver.init();
                    stickyHeadersObservers.push(phaseHeaderObserver);
                });
            }
        };

        const stickyHeadersObservers: StickyElementObserver[] = [];
        loadJourney();
        const onTaskChange = debounce((e: RealTimeUpdateIdeaEventData) => {
            if (e.ideaId !== ideaId) return;
            loadJourney();
        }, 500);
        realTimeUpdatesEventHub.addEventListener('task', 'unlocked', onTaskChange);
        realTimeUpdatesEventHub.addEventListener('task', 'sequenceVariationAdded', onTaskChange);
        realTimeUpdatesEventHub.addEventListener('task', 'sequenceVariationRemoved', onTaskChange);

        dispatch(setRecentTasksSuppression(true));

        return () => {
            realTimeUpdatesEventHub.removeEventListener('task', 'unlocked', onTaskChange);
            realTimeUpdatesEventHub.removeEventListener('task', 'sequenceVariationAdded', onTaskChange);
            realTimeUpdatesEventHub.removeEventListener('task', 'sequenceVariationRemoved', onTaskChange);
            stickyHeadersObservers.forEach(o => o.destroy());
            dispatch(setRecentTasksSuppression(false));
        };
    }, [currentUserId, ideaId, dispatch]);

    useEffect(() => {
        if (!ideaId) return;

        const onTaskMarkerChanged = async (e: RealTimeUpdateTaskMarkerEventData) => {
            if (e.ideaId !== ideaId) return;

            const taskMarkerUserId = e.user;
            const newUserMarker = await journeyService.getTaskUserMarker(ideaId, taskMarkerUserId);
            const newTaskVariationKey = getTaskVariationKey(
                newUserMarker.currentSequenceTag,
                newUserMarker.currentTaskTag,
                newUserMarker.currentTaskVariationTag
            );
            if (
                taskVariationKeyToUsersMap &&
                taskVariationKeyToUsersMap[newTaskVariationKey] &&
                taskVariationKeyToUsersMap[newTaskVariationKey].some(u => u.userId === taskMarkerUserId)
            )
                return;

            if (journeyPhases && taskMarkerUserId === currentUserId) {
                const currentTaskLocation = getTaskLocationBySequenceTagAndVariation(
                    newUserMarker.currentSequenceTag,
                    newUserMarker.currentTaskTag,
                    newUserMarker.currentTaskVariationTag,
                    journeyPhases
                );
                setCurrentPhaseIndex(currentTaskLocation?.phaseIndex);
                setCurrentTaskLocationInPhase(currentTaskLocation?.taskNodeLocation);
            }

            let updatedTaskToUsersMap = taskVariationKeyToUsersMap;
            if (taskVariationKeyToUsersMap)
                for (const taskVariationKey in taskVariationKeyToUsersMap) {
                    if (!Object.prototype.hasOwnProperty.call(taskVariationKeyToUsersMap, taskVariationKey)) continue;
                    const usersInTask = taskVariationKeyToUsersMap[taskVariationKey];
                    const userInTaskIndex = usersInTask.findIndex(u => u.userId === taskMarkerUserId);
                    if (userInTaskIndex !== -1) {
                        const updatedUsersInTask = usersInTask.slice();
                        updatedUsersInTask.splice(userInTaskIndex, 1);
                        updatedTaskToUsersMap = { ...updatedTaskToUsersMap, [taskVariationKey]: updatedUsersInTask };
                        break;
                    }
                }

            updatedTaskToUsersMap = {
                ...updatedTaskToUsersMap,
                [newTaskVariationKey]: [...(updatedTaskToUsersMap?.[newTaskVariationKey] ?? []), buildUserViewModel(newUserMarker.user)]
            };

            setTaskVariationKeyToUsersMap(updatedTaskToUsersMap);
        };

        const onTaskStatusChanged = (e: RealTimeUpdateTaskStatusEventData) => {
            if (e.ideaId !== ideaId || !journeyPhases) return;

            const taskLocation = getTaskLocationBySequenceTagAndVariation(e.sequence, e.task, e.variation, journeyPhases);
            if (!taskLocation) return;
            const taskIndex = isSimpleLocation(taskLocation.taskNodeLocation) ? taskLocation.taskNodeLocation : taskLocation.taskNodeLocation.nodeIndex;
            const variationIndex = isSimpleLocation(taskLocation.taskNodeLocation) ? undefined : taskLocation.taskNodeLocation.variationIndex;

            function mutateTaskStatus(taskNode: JourneyNode, newStatus: JourneyTaskStatus, variationIndex?: number): JourneyNode {
                if (!taskNode.detail) return taskNode;
                if (variationIndex !== undefined) {
                    if (!taskNode.detail.variations) return taskNode;
                    return {
                        ...taskNode,
                        detail: {
                            ...taskNode.detail,
                            variations: taskNode.detail.variations.map((v, vi) => (vi === variationIndex ? { ...v, status: newStatus } : v))
                        }
                    };
                } else {
                    if (!taskNode.detail.status) return taskNode;
                    return {
                        ...taskNode,
                        detail: {
                            ...taskNode.detail,
                            status: newStatus
                        }
                    };
                }
            }

            setJourneyPhases(phases =>
                phases
                    ? phases.map((p, i) =>
                          i === taskLocation.phaseIndex
                              ? {
                                    ...p,
                                    nodes: p.nodes.map((n, ni) => (ni === taskIndex ? mutateTaskStatus(n, e.status, variationIndex) : n))
                                }
                              : p
                      )
                    : phases
            );
        };

        realTimeUpdatesEventHub.addEventListener('taskMarker', 'changed', onTaskMarkerChanged);
        realTimeUpdatesEventHub.addEventListener('task', 'statusChanged', onTaskStatusChanged);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('taskMarker', 'changed', onTaskMarkerChanged);
            realTimeUpdatesEventHub.removeEventListener('task', 'statusChanged', onTaskStatusChanged);
        };
    }, [currentUserId, ideaId, journeyPhases, taskVariationKeyToUsersMap]);

    useInfoTipDeactivation(ideaId, JourneyTaskNavigationHighlight.TaskUnlockedNotice);

    const highlightedTaskNodeLocation: { phaseIndex: number; nodeIndex: number } | undefined = useMemo(
        () => (journeyPhases ? getClosestNotReadyAndUnlockedTask(journeyPhases, currentPhaseIndex, currentTaskLocationInPhase) : undefined),
        [currentPhaseIndex, currentTaskLocationInPhase, journeyPhases]
    );
    function tryScrollToMainNode(phaseIndex: number, phaseNodesCoordinates: JourneyNodesCoordinates, canvasElement: HTMLCanvasElement) {
        if (scrolledToMainNodeRef.current || !scrollingWrapper.current) return;
        const mainPhaseIndex = currentPhaseIndex ?? highlightedTaskNodeLocation?.phaseIndex ?? maxProgressPhaseIndex;
        const mainTaskLocationInPhase: NodeLocation | undefined =
            typeof currentPhaseIndex !== 'undefined'
                ? currentTaskLocationInPhase
                : highlightedTaskNodeLocation
                ? highlightedTaskNodeLocation.nodeIndex
                : maxProgressTaskIndexInPhase;
        if (typeof mainPhaseIndex === 'undefined' || typeof mainTaskLocationInPhase === 'undefined' || phaseIndex !== mainPhaseIndex) return;

        const mainNodeRelativeCoordinates = getNodeCoordinates(mainTaskLocationInPhase, phaseNodesCoordinates);
        if (!mainNodeRelativeCoordinates) return;

        const phaseTimelineElementOffset = domService.getRelativeOffset(canvasElement, scrollingWrapper.current);

        const scrollTopPosition = phaseTimelineElementOffset.top + mainNodeRelativeCoordinates.y - scrollingWrapper.current.clientHeight / 2;
        scrolledToMainNodeRef.current = true;
        scrollingWrapper.current.scrollTo({
            top: scrollTopPosition,
            behavior: 'smooth'
        });
    }

    function onPhaseDraw(phaseIndex: number) {
        if (phaseIndex !== 0 || !journeyContentWrapperRef.current) return;
        journeyContentWrapperRef.current.style.maxWidth = '';
        journeyContentWrapperRef.current.style.width = '';
    }

    function onPhaseDrawn(phaseIndex: number, phaseNodesCoordinates: JourneyNodesCoordinates, canvasElement: HTMLCanvasElement) {
        if (journeyContentWrapperRef.current && canvasElement.parentElement) {
            const canvasWidth = canvasElement.clientWidth;
            const canvasParentWidth = canvasElement.parentElement.clientWidth;
            if (canvasParentWidth < canvasWidth) {
                const journeyContentWrapperWidth = journeyContentWrapperRef.current.clientWidth;
                const newJourneyContentWrapperWidth = journeyContentWrapperWidth + canvasWidth - canvasParentWidth;
                journeyContentWrapperRef.current.style.maxWidth = 'none';
                journeyContentWrapperRef.current.style.width = `${newJourneyContentWrapperWidth}px`;
            }
        }

        if (runAnimation) setShowAnimation(true);
        else tryScrollToMainNode(phaseIndex, phaseNodesCoordinates, canvasElement);
    }

    async function setJourneyModalVisited(ideaId: string) {
        await ideasService.setFlag(ideaId, IdeaFlags.JOURNEY_MODAL_SHOWN_FLAG);
        await dispatch(loadIdeaById(ideaId));
    }

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-maxh100">
            <H1 className="heading-row">Journey</H1>
            <div
                ref={scrollingWrapper}
                className={combineClassNames('k-flex-1 vertical-scrolling-area k-pos-relative', runAnimation ? 'journey-animating' : undefined)}
            >
                <div ref={journeyContentWrapperRef} className="page-content-middle journey-content-wrapper">
                    {journeyPhases ? (
                        <CanvasItemsZone>
                            {journeyPhases.map((_, index) => {
                                const reversedIndex = journeyPhases.length - 1 - index;
                                const journeyPhase = journeyPhases[reversedIndex];

                                return (
                                    <div className={`journey-phase journey-phase-${reversedIndex}`} key={journeyPhase.title}>
                                        {index !== 0 && <div className="journey-arrow -block-center k-my-hair"></div>}
                                        <div
                                            className={combineClassNames(
                                                'journey-phase-title journey-phase-colored -block-center k-fs-sm k-text-center k-rounded k-px-2 k-py-1',
                                                journeyPhase.nodes.length ? undefined : 'k-text-disabled'
                                            )}
                                        >
                                            Phase {reversedIndex + 1}: <span className="k-text-uppercase">{journeyPhase.title}</span>
                                        </div>
                                        {journeyPhase.nodes.length > 0 && (
                                            <JourneyPhaseNodes
                                                nodes={journeyPhase.nodes}
                                                isComplete={typeof maxProgressPhaseIndex !== 'undefined' && reversedIndex < maxProgressPhaseIndex}
                                                maxProgressTaskNodeIndex={reversedIndex === maxProgressPhaseIndex ? maxProgressTaskIndexInPhase : undefined}
                                                currentTaskNodeLocation={reversedIndex === currentPhaseIndex ? currentTaskLocationInPhase : undefined}
                                                highlightedTaskNodeIndex={
                                                    reversedIndex === highlightedTaskNodeLocation?.phaseIndex
                                                        ? highlightedTaskNodeLocation.nodeIndex
                                                        : undefined
                                                }
                                                hiddenZonesNodeIndices={hiddenZonesInPhases?.[reversedIndex]}
                                                usersByTaskVariation={taskVariationKeyToUsersMap}
                                                connectedUsersIds={connectedIdeaUsersIds}
                                                onDraw={() => onPhaseDraw(reversedIndex)}
                                                onDrawn={(coordinates, canvas) => onPhaseDrawn(reversedIndex, coordinates, canvas)}
                                                canEditTasks={canEditTask}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </CanvasItemsZone>
                    ) : (
                        <LoadingIndicator size="big" className="k-display-block -block-center" />
                    )}
                </div>

                {runAnimation && showAnimation && (
                    <div className="journey-welcome-animation-container">
                        <img
                            src={journeyWelcomeAnimationUrl + '?t=' + constAnimationCacheBust}
                            width="440"
                            height="440"
                            alt="Welcome animation"
                            className="responsive-image k-display-block"
                            onLoad={() => {
                                if (!scrollingWrapper.current) return;
                                new JourneyPageWelcomeAnimator(scrollingWrapper.current, 1200).begin();
                            }}
                        />
                    </div>
                )}
            </div>
            {ideaId && taskOneNode && shouldShowJourneyModal && (
                <JourneyGetStartedDialog
                    onOptionOneSelected={async () => {
                        await setJourneyModalVisited(ideaId);
                        navigate(`../journey/tasks/${taskOneNode.detail?.sequence}/${taskOneNode.detail?.tag}`);
                    }}
                    onOptionTwoSelected={async () => {
                        await setJourneyModalVisited(ideaId);
                    }}
                />
            )}
        </StackLayout>
    );
};

type NodeHorizontalAlignment = 'start' | 'center' | 'end';
type NodeComponentProps = {
    node: JourneyNode;
    className?: string;
    horizontalAlign: NodeHorizontalAlignment;
    style?: React.CSSProperties;
};

function getTextAlignClass(horizontalAlignment: NodeHorizontalAlignment) {
    switch (horizontalAlignment) {
        case 'center':
            return 'k-text-center';
        case 'start':
            return 'k-text-left';
        case 'end':
            return 'k-text-right';
    }
}

const nodeToBubbleTopAdjustment = 28; // 16px top padding + half of large line height (12px)
const nodeToUserListTopAdjustment = 20; // Half of the avatar height - 40px
const nodeToUserListLeftAdjustment = 16;
const renderVariationLabelsInterval = 3;
const variationLabelNodeHorizontalInset = 10;
const variationLabelNodeHorizontalOffset = 26;
const variationLabelVerticalOffset = 44;
const JourneyPhaseNodes = ({
    nodes,
    maxProgressTaskNodeIndex,
    isComplete,
    currentTaskNodeLocation,
    highlightedTaskNodeIndex,
    hiddenZonesNodeIndices,
    usersByTaskVariation,
    connectedUsersIds,
    onDraw,
    onDrawn,
    canEditTasks
}: {
    nodes: JourneyNode[];
    isComplete: boolean;
    maxProgressTaskNodeIndex?: number;
    currentTaskNodeLocation?: NodeLocation;
    highlightedTaskNodeIndex?: number;
    hiddenZonesNodeIndices?: number[];
    usersByTaskVariation?: Record<string, ReducedUserViewModel[]>;
    connectedUsersIds?: string[];
    onDraw?: (canvas: HTMLCanvasElement) => void;
    onDrawn?: (nodesCoordinates: JourneyNodesCoordinates, canvas: HTMLCanvasElement) => void;
    canEditTasks?: boolean;
}) => {
    const variableGroupsCollapseData: Record<string, boolean> = {};
    const currentNodeIndex =
        currentTaskNodeLocation === undefined
            ? undefined
            : typeof currentTaskNodeLocation === 'number'
            ? currentTaskNodeLocation
            : currentTaskNodeLocation.nodeIndex;
    for (let nodeIndex = 0; nodeIndex < nodes.length; nodeIndex++) {
        const node = nodes[nodeIndex];
        if (!node.detail || !node.detail.variations) continue;

        const isGroupCollapsed = variableGroupsCollapseData[node.detail.sequence];
        if (isGroupCollapsed === false) continue;

        const isEligibleForCollapsing =
            currentNodeIndex !== nodeIndex &&
            maxProgressTaskNodeIndex !== nodeIndex &&
            highlightedTaskNodeIndex !== nodeIndex &&
            node.type === JourneyNodeType.Task &&
            !node.detail.locked &&
            !node.detail.variations.some(v => v.locked);

        if (isGroupCollapsed === undefined) variableGroupsCollapseData[node.detail.sequence] = isEligibleForCollapsing;
        else if (!isEligibleForCollapsing) variableGroupsCollapseData[node.detail.sequence] = false;
    }

    const leftNodesIndices: number[] = [];
    const rightNodesIndices: number[] = [];
    const variationLabelsRenderData: { nodeIndex: number; align: 'left' | 'right' }[] = [];
    let centerNodeIndex: number | null = null;
    let addNodeToRightColumn = true;
    let previousNodeSequence: string | undefined;
    let nodeInSequenceIndex = 0;
    for (let index = 0; index < nodes.length; index++) {
        const phaseNode = nodes[index];
        if (phaseNode.detail && phaseNode.detail.sequence === previousNodeSequence) {
            if (phaseNode.detail.variations && phaseNode.detail.variations.length > 1 && !variableGroupsCollapseData[phaseNode.detail.sequence])
                addNodeToRightColumn = !addNodeToRightColumn;
            ++nodeInSequenceIndex;
        } else nodeInSequenceIndex = 0;

        if (phaseNode.type === JourneyNodeType.Start) {
            centerNodeIndex = index;
        } else if (addNodeToRightColumn) rightNodesIndices.push(index);
        else leftNodesIndices.push(index);

        if (
            phaseNode.detail &&
            phaseNode.detail.variations &&
            phaseNode.detail.variations.length > 1 &&
            nodeInSequenceIndex % renderVariationLabelsInterval === 0 &&
            !variableGroupsCollapseData[phaseNode.detail.sequence]
        )
            variationLabelsRenderData.push({ nodeIndex: index, align: addNodeToRightColumn ? 'left' : 'right' });

        if (phaseNode.type !== JourneyNodeType.Start) addNodeToRightColumn = !addNodeToRightColumn;
        previousNodeSequence = phaseNode.detail?.sequence;
    }

    const variableGroupsCollapseDataRef = useRef(variableGroupsCollapseData);
    variableGroupsCollapseDataRef.current = variableGroupsCollapseData;
    const timelineNodes = useMemo<TimelineNode[]>(
        () =>
            nodes.map<TimelineNode>(node => {
                if (node.type === JourneyNodeType.Start) return { type: TimelineNodeType.Start, isVisitable: false };
                if (node.type === JourneyNodeType.Milestone) return { type: TimelineNodeType.Default, isVisitable: false };

                if (!node.detail) return { type: TimelineNodeType.Default, isVisitable: false };

                if (node.detail.variations) {
                    if (variableGroupsCollapseDataRef.current[node.detail.sequence]) return { type: TimelineNodeType.Default, isVisitable: true };

                    return {
                        groupKey: node.detail.sequence,
                        variationsTypes: node.detail.variations.map(v => (v.locked ? TimelineNodeType.Disabled : TimelineNodeType.Default))
                    };
                }

                return { type: node.detail.locked ? TimelineNodeType.Disabled : TimelineNodeType.Default, isVisitable: true };
            }),
        [nodes]
    );
    const [nodesCoordinates, setNodesCoordinates] = useState<JourneyNodesCoordinates>();

    function renderJourneyNode(nodeIndex: number, align: NodeHorizontalAlignment) {
        const node = nodes[nodeIndex];
        const NodeComponent = nodeComponents[node.type];
        const isHighlightedTask = node.type === JourneyNodeType.Task && nodeIndex === highlightedTaskNodeIndex;
        const nodeTopPosition = nodesCoordinates ? getNodeCoordinates(nodeIndex, nodesCoordinates)?.y : undefined;
        const style: React.CSSProperties | undefined =
            node.type !== JourneyNodeType.Start
                ? nodeTopPosition
                    ? { top: nodeTopPosition - nodeToBubbleTopAdjustment }
                    : { visibility: 'hidden' }
                : undefined;
        const additionalProps = node.type === JourneyNodeType.Task ? ({ openReadyOnly: !canEditTasks } as TaskNodeComponentProps) : undefined;

        return (
            <NodeComponent
                key={nodeIndex}
                node={node}
                horizontalAlign={align}
                className={combineClassNames(
                    node.type !== JourneyNodeType.Start ? 'journey-node' : 'journey-start-node',
                    isHighlightedTask ? 'journey-task-node-highlighted' : undefined
                )}
                style={style}
                {...additionalProps}
            />
        );
    }

    function renderUsersForNode(
        key: React.Key,
        usersInNode: ReducedUserViewModel[] | undefined,
        nodeCoordinates: TimelineNodeCoordinates | undefined,
        reverseDirection: boolean,
        maxNumberOfUsers?: number
    ) {
        if (!usersInNode || !usersInNode.length || !nodeCoordinates) return null;

        return (
            <StartupMembershipList
                key={key}
                users={usersInNode}
                className={combineClassNames('k-pos-absolute', reverseDirection ? 'k-flex-row-reverse' : undefined)}
                style={{
                    top: nodeCoordinates.y - nodeToUserListTopAdjustment,
                    left: nodeCoordinates.x + (reverseDirection ? -nodeToUserListLeftAdjustment : nodeToUserListLeftAdjustment),
                    transform: reverseDirection ? 'translateX(-100%)' : undefined
                }}
                accentedUsersIds={connectedUsersIds}
                maxCount={maxNumberOfUsers}
            />
        );
    }

    function renderUsersInTask(nodeIndex: number, reverseDirection: boolean) {
        if (!usersByTaskVariation) return null;
        const node = nodes[nodeIndex];
        if (node.type !== JourneyNodeType.Task || !node.detail) return null;

        const nodeDetails = node.detail;
        if (nodeDetails.variations) {
            if (variableGroupsCollapseData[nodeDetails.sequence]) {
                const usersInAllVariations = distinct(
                    nodeDetails.variations.flatMap(nodeVariation => {
                        const taskVariationKey = getTaskVariationKey(nodeDetails.sequence, nodeDetails.tag, nodeVariation.tag);
                        return usersByTaskVariation[taskVariationKey];
                    }),
                    u => u.userId
                );
                const firstTaskVariationKey = getTaskVariationKey(
                    nodeDetails.sequence,
                    nodeDetails.tag,
                    nodeDetails.variations.length ? nodeDetails.variations[0].tag : undefined
                );
                const firstNodeCoordinates = nodesCoordinates ? getNodeCoordinates({ nodeIndex: nodeIndex, variationIndex: 0 }, nodesCoordinates) : undefined;

                return renderUsersForNode(firstTaskVariationKey, usersInAllVariations, firstNodeCoordinates, reverseDirection);
            }

            let maxNumberOfUsers: number | undefined;
            if (nodeDetails.variations.length > 1 && nodesCoordinates) {
                const firstNodeCoordinates = getNodeCoordinates({ nodeIndex: nodeIndex, variationIndex: 0 }, nodesCoordinates);
                const secondNodeCoordinates = getNodeCoordinates({ nodeIndex: nodeIndex, variationIndex: 1 }, nodesCoordinates);
                if (firstNodeCoordinates && secondNodeCoordinates)
                    maxNumberOfUsers = Math.floor((Math.abs(firstNodeCoordinates.x - secondNodeCoordinates.x) - nodeToUserListLeftAdjustment) / 40);
            }

            return nodeDetails.variations.map((nodeVariation, nodeVariationIndex) => {
                const taskVariationKey = getTaskVariationKey(nodeDetails.sequence, nodeDetails.tag, nodeVariation.tag);
                const usersInNode = usersByTaskVariation[taskVariationKey];
                const nodeCoordinates = nodesCoordinates
                    ? getNodeCoordinates({ nodeIndex: nodeIndex, variationIndex: nodeVariationIndex }, nodesCoordinates)
                    : undefined;
                return renderUsersForNode(taskVariationKey, usersInNode, nodeCoordinates, reverseDirection, maxNumberOfUsers);
            });
        }

        const taskVariationKey = getTaskVariationKey(nodeDetails.sequence, nodeDetails.tag);
        const usersInNode = usersByTaskVariation[taskVariationKey];
        const nodeCoordinates = nodesCoordinates ? getNodeCoordinates(nodeIndex, nodesCoordinates) : undefined;
        return renderUsersForNode(taskVariationKey, usersInNode, nodeCoordinates, reverseDirection);
    }

    function renderNodeVariationLabels(nodeIndex: number, align: 'left' | 'right') {
        if (!nodesCoordinates) return;
        if (hiddenZonesNodeIndices && hiddenZonesNodeIndices.includes(nodeIndex - 1)) return;

        const node = nodes[nodeIndex];
        if (!node.detail || !node.detail.variations) return;
        let distanceBetweenVariations: number | undefined;
        if (node.detail.variations.length > 1) {
            const firstNodeCoordinates = getNodeCoordinates({ nodeIndex: nodeIndex, variationIndex: 0 }, nodesCoordinates);
            const secondNodeCoordinates = getNodeCoordinates({ nodeIndex: nodeIndex, variationIndex: 1 }, nodesCoordinates);
            if (firstNodeCoordinates && secondNodeCoordinates) distanceBetweenVariations = Math.abs(firstNodeCoordinates.x - secondNodeCoordinates.x);
        }

        const labelMaxWidth = distanceBetweenVariations
            ? distanceBetweenVariations + variationLabelNodeHorizontalInset - variationLabelNodeHorizontalOffset
            : '100%';

        return (
            <React.Fragment key={`${nodeIndex}-variation-labels`}>
                {node.detail.variations.map((variation, variationIndex) => {
                    const variationCoordinates = getNodeCoordinates({ nodeIndex: nodeIndex, variationIndex: variationIndex }, nodesCoordinates);
                    if (!variationCoordinates) return null;
                    return (
                        <CanvasItemInContextSimpleView
                            key={getTaskVariationKey(node.detail!.sequence, node.detail!.tag, variation.tag)}
                            box={BoxType.CustomerSegments}
                            itemId={variation.customerSegmentId}
                            singleLine={true}
                            size="small"
                            className={align === 'left' ? 'k-flex-row-reverse' : undefined}
                            render={element => (
                                <div
                                    className="journey-variation-label k-icp-panel !k-rounded-sm k-px-1 k-py-thin"
                                    style={{
                                        position: 'absolute',
                                        maxWidth: labelMaxWidth,
                                        top: variationCoordinates.y + variationLabelVerticalOffset,
                                        left: variationCoordinates.x + variationLabelNodeHorizontalInset * (align === 'right' ? -1 : 1),
                                        transform: align === 'left' ? 'translateX(-100%)' : undefined
                                    }}
                                >
                                    {element}
                                </div>
                            )}
                        />
                    );
                })}
            </React.Fragment>
        );
    }

    const onDrawnRef = useRef({ onDraw, onDrawn });
    onDrawnRef.current.onDraw = onDraw;
    onDrawnRef.current.onDrawn = onDrawn;
    const journeyCanvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        if (!journeyCanvasRef.current) return;
        const canvasElement = journeyCanvasRef.current;

        const timelineDrawer = new JourneyPhaseForkedTimelineDrawer(canvasElement, timelineNodes, currentTaskNodeLocation, hiddenZonesNodeIndices, isComplete);

        function drawTimeline() {
            onDrawnRef.current.onDraw?.(canvasElement);
            const drawnNodesCoordinates = timelineDrawer.draw();
            setNodesCoordinates(drawnNodesCoordinates);
            if (drawnNodesCoordinates) onDrawnRef.current.onDrawn?.(drawnNodesCoordinates, canvasElement);
        }
        drawTimeline();

        const drawTimelineDebounced = debounce(drawTimeline, 100);
        window.addEventListener('resize', drawTimelineDebounced);

        return () => {
            window.removeEventListener('resize', drawTimelineDebounced);
        };
    }, [timelineNodes, isComplete, currentTaskNodeLocation, hiddenZonesNodeIndices]);

    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'stretch' }}>
            <div className="journey-node-column">
                {leftNodesIndices.map((_, i) => renderJourneyNode(leftNodesIndices[leftNodesIndices.length - 1 - i], 'end'))}
            </div>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 -minw0 k-gap-1">
                <div className="journey-path-wrapper k-pos-relative">
                    <canvas ref={journeyCanvasRef} className="k-display-block -block-center" />
                    {leftNodesIndices.map((_, i) => renderUsersInTask(leftNodesIndices[leftNodesIndices.length - 1 - i], false))}
                    {rightNodesIndices.map((_, i) => renderUsersInTask(rightNodesIndices[rightNodesIndices.length - 1 - i], true))}
                    {variationLabelsRenderData.map(renderVariationData => renderNodeVariationLabels(renderVariationData.nodeIndex, renderVariationData.align))}
                </div>
                {centerNodeIndex !== null && renderJourneyNode(centerNodeIndex, 'center')}
            </StackLayout>
            <div className="journey-node-column">
                {rightNodesIndices.map((_, i) => renderJourneyNode(rightNodesIndices[rightNodesIndices.length - 1 - i], 'start'))}
            </div>
        </StackLayout>
    );
};

const MilestoneNode = (props: NodeComponentProps) => {
    const className = combineClassNames('k-gap-1', getTextAlignClass(props.horizontalAlign));

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: props.horizontalAlign, vertical: 'top' }}
            className={combineClassNames(className, props.className)}
            style={props.style}
        >
            <strong
                className={combineClassNames(
                    'k-fs-sm journey-milestone-node-title',
                    props.horizontalAlign === 'end' ? 'journey-milestone-node-title-flipped' : undefined
                )}
            >
                <div className="journey-milestone-node-title-bg"></div>
                {props.node.title}
            </strong>
            <div>{props.node.name}</div>
        </StackLayout>
    );
};

type TaskNodeComponentProps = { openReadyOnly?: boolean };
const TaskNode = (props: NodeComponentProps & TaskNodeComponentProps) => {
    const navigate = useNavigate();
    const openVariableTaskButtonRef = useRef<DropDownButton>(null);
    const isLocked = !props.node.detail || props.node.detail.locked;
    const isVariableTask = !!props.node.detail?.variations;
    const accessibleVariationsCount = countAccessibleTaskVariations(props.node, props.openReadyOnly);
    const canInteract = accessibleVariationsCount > 0;
    let nodeClasses: string | undefined = combineClassNames(
        `k-gap-1 journey-task-node journey-task-node-${props.horizontalAlign}`,
        getTextAlignClass(props.horizontalAlign)
    );
    nodeClasses = combineClassNames(nodeClasses, isLocked ? 'journey-task-node-locked' : canInteract ? 'journey-task-node-interactive' : undefined);
    nodeClasses = combineClassNames(nodeClasses, props.className);

    const Icon = isLocked ? LockedTaskIcon : TaskIcon;

    const navigateToTask = useCallback(
        (variationTag?: string) => {
            navigate(`../journey/tasks/${props.node.detail?.sequence}/${props.node.detail?.tag}${variationTag ? `/${variationTag}` : ''}`);
        },
        [navigate, props.node.detail?.sequence, props.node.detail?.tag]
    );

    const nodeElementRef = useRef<StackLayoutHandle>(null);
    useEffect(() => {
        if (!nodeElementRef.current || !canInteract) return;

        function onNodeElementClick(e: MouseEvent) {
            if (isVariableTask) {
                if (props.node.detail?.variations?.length === 1) {
                    const variation = props.node.detail.variations[0];
                    if (variation.locked) return;
                    navigateToTask(variation.tag);
                    return;
                }

                if (!openVariableTaskButtonRef.current) return;
                if (openVariableTaskButtonRef.current.state.opened) return;
                e.stopPropagation();
                const openDropDownButton: any = openVariableTaskButtonRef.current;
                openDropDownButton.onClickMainButton(e);
            } else {
                e.stopPropagation();
                navigateToTask();
            }
        }

        const currentNodeElement = nodeElementRef.current;
        currentNodeElement.element?.addEventListener('click', onNodeElementClick, { capture: true });

        return () => {
            currentNodeElement.element?.removeEventListener('click', onNodeElementClick, { capture: true });
        };
    }, [isVariableTask, navigate, navigateToTask, canInteract, props.node.detail?.variations]);

    return (
        <StackLayout
            ref={nodeElementRef}
            orientation="vertical"
            align={{ horizontal: props.horizontalAlign, vertical: 'top' }}
            className={nodeClasses}
            style={props.style}
        >
            <StackLayout
                align={{ horizontal: 'start', vertical: 'middle' }}
                className={combineClassNames('k-gap-2', props.horizontalAlign === 'end' ? 'k-flex-row-reverse' : undefined)}
            >
                <div className="journey-task-node-icon-wrapper k-p-1 k-rounded-full">
                    <Icon className="k-icp-icon-size-4 k-icp-icon" />
                </div>
                <strong className="journey-task-node-title k-fs-lg">{props.node.title}</strong>
                {isVariableTask && !props.node.detail?.variations?.length && (
                    <div className="k-text-uppercase k-fs-xs k-font-semibold k-py-thin -px-1.5 k-icp-bg-error-8 k-rounded-sm">No customer segment</div>
                )}
            </StackLayout>
            <div dangerouslySetInnerHTML={{ __html: props.node.name }} />
            {!isLocked &&
                (isVariableTask ? (
                    props.node.detail!.variations!.length === 1 ? (
                        <Button
                            fillMode="flat"
                            themeColor="secondary"
                            size="small"
                            onClick={() => navigateToTask(props.node.detail!.variations![0].tag)}
                            disabled={!canInteract}
                        >
                            Open task
                        </Button>
                    ) : (
                        <PopupPropsContext.Provider value={p => ({ ...p, appendTo: nodeElementRef.current?.element })}>
                            <BoundDropDownButton
                                ref={openVariableTaskButtonRef}
                                fillMode="flat"
                                themeColor="secondary"
                                size="small"
                                icon="arrow-60-down"
                                buttonClass="k-flex-row-reverse"
                                text="Open task"
                                items={props.node.detail!.variations!.map(v => ({
                                    disabled: v.locked || (props.openReadyOnly && v.status !== JourneyTaskStatus.Ready),
                                    children: (
                                        <CanvasItemInContextSimpleView
                                            box={BoxType.CustomerSegments}
                                            itemId={v.customerSegmentId}
                                            size="small"
                                            className="k-white-space-normal"
                                        />
                                    ),
                                    lineEndIcon: v.locked ? LockedTaskIcon : undefined,
                                    lineEndIconClassName: 'k-align-self-flex-start',
                                    action() {
                                        navigateToTask(v.tag);
                                    }
                                }))}
                                popupSettings={{
                                    popupClass: 'journey-task-node-variations-popup k-text-left',
                                    anchorAlign: {
                                        horizontal: props.horizontalAlign === 'end' ? 'right' : 'left',
                                        vertical: 'bottom'
                                    },
                                    popupAlign: {
                                        horizontal: props.horizontalAlign === 'end' ? 'right' : 'left',
                                        vertical: 'top'
                                    }
                                }}
                                disabled={!canInteract}
                            />
                        </PopupPropsContext.Provider>
                    )
                ) : (
                    <Button fillMode="flat" themeColor="secondary" size="small" onClick={() => navigateToTask()} disabled={!canInteract}>
                        Open task
                    </Button>
                ))}
        </StackLayout>
    );
};

function countAccessibleTaskVariations(node: JourneyNode, readyOnly?: boolean) {
    if (!node.detail || node.detail.locked) return 0;

    if (node.detail.variations) {
        if (readyOnly) return countWhere(node.detail.variations, v => !v.locked && v.status === JourneyTaskStatus.Ready);

        return countWhere(node.detail.variations, v => !v.locked);
    }

    return readyOnly ? (node.detail.status === JourneyTaskStatus.Ready ? 1 : 0) : 1;
}

const StartNode = (props: NodeComponentProps) => {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: props.horizontalAlign, vertical: 'top' }}
            className={combineClassNames(combineClassNames('k-gap-1', props.className), getTextAlignClass(props.horizontalAlign))}
            style={props.style}
        >
            <StartupCreatedIllustration width="32" height="32" />
            <strong>{props.node.name}</strong>
        </StackLayout>
    );
};

const nodeComponents: Record<JourneyNodeType, ComponentType<NodeComponentProps>> = {
    [JourneyNodeType.Task]: TaskNode,
    [JourneyNodeType.Start]: StartNode,
    [JourneyNodeType.Milestone]: MilestoneNode
};

function getTaskVariationKey(sequenceTag: string, taskTag: string, variationTag?: string) {
    return `${sequenceTag}$$${taskTag}$$${variationTag || ''}`;
}

function getLastPhaseIndexWithLastUnlockedTask(phases: JourneyPhase[]): { phaseIndex: number; taskNodeIndex: number } | undefined {
    for (let unlockedPhaseIndex = phases.length - 1; unlockedPhaseIndex >= 0; --unlockedPhaseIndex) {
        const unlockedPhase = phases[unlockedPhaseIndex];
        for (let nodeIndex = unlockedPhase.nodes.length - 1; nodeIndex >= 0; --nodeIndex) {
            const phaseNode = unlockedPhase.nodes[nodeIndex];
            if (phaseNode.type === JourneyNodeType.Task && phaseNode.detail && !phaseNode.detail.locked) {
                return { phaseIndex: unlockedPhaseIndex, taskNodeIndex: nodeIndex };
            }
        }
    }

    return undefined;
}

function getLastPhaseIndexWithTask(phases: JourneyPhase[]): number | undefined {
    for (let phaseIndex = phases.length - 1; phaseIndex >= 0; --phaseIndex) {
        const phase = phases[phaseIndex];
        const hasTaskInPhase = phase.nodes.some(n => n.type === JourneyNodeType.Task);
        if (hasTaskInPhase) {
            return phaseIndex;
        }
    }

    return undefined;
}

function getUserMarker(userId: string | undefined, markers: JourneyTaskMarker[]): JourneyTaskMarker | undefined {
    if (!userId) return undefined;

    return markers.find(m => m.user.userId === userId);
}

function getTaskLocationBySequenceTagAndVariation(
    taskSequenceTag: string,
    taskTag: string,
    variationTag: string | undefined,
    phases: JourneyPhase[]
): { phaseIndex: number; taskNodeLocation: NodeLocation } | undefined {
    for (let phaseIndex = phases.length - 1; phaseIndex >= 0; --phaseIndex) {
        const phase = phases[phaseIndex];
        for (let nodeIndex = phase.nodes.length - 1; nodeIndex >= 0; --nodeIndex) {
            const phaseNode = phase.nodes[nodeIndex];
            if (
                phaseNode.type !== JourneyNodeType.Task ||
                !phaseNode.detail ||
                phaseNode.detail.sequence !== taskSequenceTag ||
                phaseNode.detail.tag !== taskTag
            )
                continue;
            if (variationTag) {
                if (!phaseNode.detail.variations) return undefined;
                const variationIndex = phaseNode.detail.variations.findIndex(v => v.tag === variationTag);
                if (variationIndex === -1) return undefined;
                return { phaseIndex: phaseIndex, taskNodeLocation: { nodeIndex: nodeIndex, variationIndex: variationIndex } };
            } else return { phaseIndex: phaseIndex, taskNodeLocation: nodeIndex };
        }
    }

    return undefined;
}

function getJourneyProgress(
    userId: string | undefined,
    phases: JourneyPhase[],
    markers: JourneyTaskMarker[]
): { maxPhaseIndex?: number; maxTaskNodeIndex?: number; currentPhaseIndex?: number; currentTaskNodeLocation?: NodeLocation } {
    let currentPhaseIndex: number | undefined;
    let currentTaskNodeLocation: NodeLocation | undefined;
    const userMarker = getUserMarker(userId, markers);
    if (userMarker) {
        const currentTaskLocation = getTaskLocationBySequenceTagAndVariation(
            userMarker.currentSequenceTag,
            userMarker.currentTaskTag,
            userMarker.currentTaskVariationTag,
            phases
        );
        if (currentTaskLocation) {
            currentPhaseIndex = currentTaskLocation.phaseIndex;
            currentTaskNodeLocation = currentTaskLocation.taskNodeLocation;
        }
    }

    const lastUnlockedTaskLocation = getLastPhaseIndexWithLastUnlockedTask(phases);
    if (lastUnlockedTaskLocation)
        return {
            maxPhaseIndex: lastUnlockedTaskLocation.phaseIndex,
            maxTaskNodeIndex: lastUnlockedTaskLocation.taskNodeIndex,
            currentPhaseIndex,
            currentTaskNodeLocation
        };

    const lastPhaseWithTaskIndex = getLastPhaseIndexWithTask(phases);
    if (typeof lastPhaseWithTaskIndex !== 'undefined') return { maxPhaseIndex: lastPhaseWithTaskIndex };

    if (phases.length && phases[0].nodes.length) return { maxPhaseIndex: 0 };

    return {};
}

function filterJourneyNodes(
    journeyPhases: JourneyPhase[],
    maxProgressPhaseIndex: number,
    maxProgressTaskIndexInPhase: number
): Record<number, number[]> | undefined {
    const requiredVisibleTasksLocation = findLocationOfNode(
        journeyPhases,
        maxProgressPhaseIndex,
        maxProgressTaskIndexInPhase + 1,
        n => n.type === JourneyNodeType.Task,
        2
    );
    if (requiredVisibleTasksLocation.phaseIndex === -1 || requiredVisibleTasksLocation.nodeIndex === -1) return undefined;
    const requiredVisibleMilestonesLocation = findLocationOfNode(
        journeyPhases,
        requiredVisibleTasksLocation.phaseIndex,
        requiredVisibleTasksLocation.nodeIndex + 1,
        n => n.type === JourneyNodeType.Milestone,
        1
    );
    const lastPhaseIndex = journeyPhases.length - 1;
    const lastNodeIndex = journeyPhases[lastPhaseIndex].nodes.length - 1;
    if (requiredVisibleMilestonesLocation.phaseIndex === -1 || requiredVisibleMilestonesLocation.nodeIndex === -1) {
        clearJourney(journeyPhases, requiredVisibleTasksLocation.phaseIndex, requiredVisibleTasksLocation.nodeIndex + 1, lastPhaseIndex, lastNodeIndex);

        return undefined;
    }

    const hasNodesAfterMilestone = journeyPhases[requiredVisibleMilestonesLocation.phaseIndex].nodes.length - 1 > requiredVisibleMilestonesLocation.nodeIndex;
    clearJourney(journeyPhases, requiredVisibleMilestonesLocation.phaseIndex, requiredVisibleMilestonesLocation.nodeIndex + 1, lastPhaseIndex, lastNodeIndex);
    clearJourney(
        journeyPhases,
        requiredVisibleTasksLocation.phaseIndex,
        requiredVisibleTasksLocation.nodeIndex + 1,
        requiredVisibleMilestonesLocation.phaseIndex,
        requiredVisibleMilestonesLocation.nodeIndex - 1
    );

    if (
        requiredVisibleTasksLocation.phaseIndex === requiredVisibleMilestonesLocation.phaseIndex &&
        requiredVisibleMilestonesLocation.nodeIndex - requiredVisibleTasksLocation.nodeIndex > 1
    ) {
        return {
            [requiredVisibleTasksLocation.phaseIndex]: [requiredVisibleTasksLocation.nodeIndex]
        };
    }

    return hasNodesAfterMilestone
        ? {
              [requiredVisibleMilestonesLocation.phaseIndex]: [requiredVisibleMilestonesLocation.nodeIndex]
          }
        : undefined;
}

function findLocationOfNode(
    phases: JourneyPhase[],
    startPhaseIndex: number,
    startNodeIndex: number,
    condition: (node: JourneyNode) => boolean,
    timesToMeetCondition: number
) {
    for (let phaseIndex = startPhaseIndex; phaseIndex < phases.length; phaseIndex++) {
        const phase = phases[phaseIndex];
        for (let nodeIndex = phaseIndex === startPhaseIndex ? startNodeIndex : 0; nodeIndex < phase.nodes.length; nodeIndex++) {
            const node = phase.nodes[nodeIndex];
            if (condition(node)) --timesToMeetCondition;
            if (timesToMeetCondition <= 0) return { phaseIndex, nodeIndex };
        }
    }

    return { phaseIndex: -1, nodeIndex: -1 };
}

function clearJourney(phases: JourneyPhase[], fromPhaseIndex: number, fromTaskIndex: number, toPhaseIndex: number, toTaskIndex: number) {
    for (let phaseIndex = fromPhaseIndex; phaseIndex <= toPhaseIndex && phaseIndex < phases.length; phaseIndex++) {
        const nodesInPhase = phases[phaseIndex].nodes;
        const clearTasksFrom = phaseIndex === fromPhaseIndex ? fromTaskIndex : 0;
        const clearTaskTo = phaseIndex === toPhaseIndex ? toTaskIndex : nodesInPhase.length - 1;
        nodesInPhase.splice(clearTasksFrom, clearTaskTo - clearTasksFrom + 1);
    }
}

function isSimpleLocation(location: NodeLocation): location is number {
    return typeof location === 'number';
}

function getNodeCoordinates(nodeLocation: NodeLocation, journeyNodesCoordinates: JourneyNodesCoordinates): TimelineNodeCoordinates | undefined {
    if (isSimpleLocation(nodeLocation)) {
        const nodeCoordinates = journeyNodesCoordinates[nodeLocation];
        if (nodeCoordinates instanceof Array) {
            return nodeCoordinates[0];
        } else return nodeCoordinates;
    } else {
        const nodesCoordinates = journeyNodesCoordinates[nodeLocation.nodeIndex];
        if (nodesCoordinates instanceof Array) return nodesCoordinates[nodeLocation.variationIndex];
        else {
            return nodesCoordinates;
        }
    }
}

function getClosestNotReadyAndUnlockedTask(phases: JourneyPhase[], startPhaseIndex?: number, startNodeLocationInPhase?: NodeLocation) {
    if (!phases.length) return undefined;

    let currentPhaseIndex = startPhaseIndex ?? 0;
    if (!phases[currentPhaseIndex].nodes.length) return undefined;

    for (let phaseIndex = currentPhaseIndex; phaseIndex < phases.length; phaseIndex++) {
        const currentPhase = phases[phaseIndex];

        let currentNodeLocation: NodeLocation | undefined = phaseIndex === startPhaseIndex ? startNodeLocationInPhase ?? 0 : 0;
        while (currentNodeLocation !== undefined) {
            const currentNodeIndex = isSimpleLocation(currentNodeLocation) ? currentNodeLocation : currentNodeLocation.nodeIndex;
            const currentNode = currentPhase.nodes[currentNodeIndex];

            if (currentNode.type === JourneyNodeType.Task) {
                if (currentNode.detail?.locked) return undefined;
                const isVariableNode = currentNode.detail?.variations && currentNode.detail?.variations.length > 0;

                let isCurrentNodeNotReady = false;

                if (!isVariableNode) {
                    if (currentNode.detail?.status && currentNode.detail.status !== JourneyTaskStatus.Ready) isCurrentNodeNotReady = true;
                } else {
                    if (isSimpleLocation(currentNodeLocation)) {
                        if (currentNode.detail?.variations?.some(v => !v.locked && v.status && v.status !== JourneyTaskStatus.Ready))
                            isCurrentNodeNotReady = true;
                    } else {
                        const variation = currentNode.detail?.variations?.[currentNodeLocation.variationIndex];
                        if (!variation || variation.locked) return undefined;
                        if (variation.status && variation.status !== JourneyTaskStatus.Ready) isCurrentNodeNotReady = true;
                    }
                }

                if (isCurrentNodeNotReady)
                    return {
                        phaseIndex: phaseIndex,
                        nodeIndex: currentNodeIndex
                    };
            }

            if (currentNodeIndex === currentPhase.nodes.length - 1) currentNodeLocation = undefined;
            else {
                if (isSimpleLocation(currentNodeLocation)) currentNodeLocation++;
                else {
                    const nextNode = currentPhase.nodes[currentNodeIndex + 1];
                    if (
                        nextNode.detail &&
                        nextNode.detail.sequence === currentNode.detail?.sequence &&
                        nextNode.detail.variations &&
                        currentNodeLocation.variationIndex < nextNode.detail.variations.length
                    )
                        currentNodeLocation = {
                            nodeIndex: currentNodeLocation.nodeIndex + 1,
                            variationIndex: currentNodeLocation.variationIndex
                        };
                    else currentNodeLocation = currentNodeLocation.nodeIndex + 1;
                }
            }
        }
    }
}
