import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Skeleton } from '@progress/kendo-react-indicators';
import { TextArea } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { maxLengthValidator, requiredValidator, ValidatedInput } from '../../../components/ui/inputs';
import { SvgIconButtonContent } from '../../../components/ui/svgIconButtonContent';
import { H1 } from '../../../components/ui/typography';
import { useSingleClickCallback } from '../../../hooks/commonHooks';
import { useIdeaParams, useIntParam, useIsOnPage } from '../../../hooks/routerHooks';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { combineClassNames } from '../../../services/common';
import { InterviewCollection, interviewCollectionsService } from '../../../services/experiments/interviewCollectionsService';
import { RealTimeExperimentalInterviewCollectionEventData, realTimeUpdatesEventHub } from '../../../services/realTimeUpdatesService';
import { UserRole } from '../../../services/usersService';
import { useAppSelector } from '../../../state/hooks';
import { TabbedLayout, TabHeader } from '../../layouts/tabbedLayout';

const tabs: TabHeader[] = [
    {
        title: 'Insights',
        to: ''
    },
    {
        title: 'Interviews',
        to: 'interviews'
    }
];

export function InterviewCollectionPage() {
    const { ideaId } = useIdeaParams();
    const collectionId = useIntParam('collectionId');
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canManageCollections = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const isOnInterviewsPage = useIsOnPage(tabs[1].to);
    const [isAddingObjective, setIsAddingObjective] = useState<boolean>();

    const [collection, setCollection] = useState<InterviewCollection>();

    const refreshInterviewCollection = useCallback(() => interviewCollectionsService.getInterviewCollection(ideaId, collectionId).then(setCollection), [
        collectionId,
        ideaId
    ]);

    useEffect(() => {
        refreshInterviewCollection();
    }, [collectionId, ideaId, refreshInterviewCollection]);

    useEffect(() => {
        const onAnalysisComplete = (e: RealTimeExperimentalInterviewCollectionEventData) => {
            if (e.ideaId !== ideaId || e.interviewCollectionId !== collectionId) return;
            refreshInterviewCollection();
        };

        realTimeUpdatesEventHub.addEventListener('interviewCollection', 'analysisComplete', onAnalysisComplete);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interviewCollection', 'analysisComplete', onAnalysisComplete);
        };
    }, [collectionId, ideaId, refreshInterviewCollection]);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
            <div>
                <Link to=".." className="k-button k-button-link k-fs-sm k-button-link-base k-font-weight-semibold k-mt-2">
                    <span className="k-icon k-i-arrow-chevron-left"></span> All collections
                </Link>
            </div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-8 heading-row -mt-1.5">
                <H1 className="k-text-ellipsis" style={{ maxWidth: 512 }} title={collection?.name}>
                    {collection ? collection.name : <Skeleton shape="text" style={{ width: 400 }} />}
                </H1>
                {canManageCollections && (
                    <>
                        <div className="vertical-separator" />

                        {isOnInterviewsPage ? (
                            <Link
                                to="interviews/create"
                                className={combineClassNames(
                                    'k-button k-button-lg k-button-solid k-button-solid-primary k-rounded-md',
                                    !collection || collection.analysis.pending ? 'k-disabled' : undefined
                                )}
                            >
                                <SvgIconButtonContent icon={PlusIcon}>New interview</SvgIconButtonContent>
                            </Link>
                        ) : (
                            <Button
                                type="button"
                                size="large"
                                themeColor="primary"
                                disabled={!collection || collection.analysis.pending}
                                onClick={() => setIsAddingObjective(true)}
                            >
                                <SvgIconButtonContent icon={PlusIcon}>Get more insights</SvgIconButtonContent>
                            </Button>
                        )}
                    </>
                )}
            </StackLayout>

            <TabbedLayout headers={tabs} data={{ collection, updateCollection: (collection: InterviewCollection) => setCollection(collection) }} />
            {isAddingObjective && (
                <AddInterviewCollectionObjectiveModal
                    onCancel={() => setIsAddingObjective(undefined)}
                    onAdd={async objective => {
                        const updatedCollection = await interviewCollectionsService.addInterviewCollectionObjective(ideaId, collectionId, objective);
                        setCollection(updatedCollection);
                        setIsAddingObjective(undefined);
                    }}
                />
            )}
        </StackLayout>
    );
}

const interviewCollectionObjectiveValidators = [requiredValidator('Content'), maxLengthValidator('Content', 1000)];
function AddInterviewCollectionObjectiveModal({ onAdd, onCancel }: { onAdd?: (objective: string) => void | Promise<void>; onCancel?: () => void }) {
    const [isAdding, addObjective] = useSingleClickCallback(onAdd);
    function onAddObjectiveSubmit(data: Record<string, string>) {
        return addObjective?.(data.objective);
    }

    return (
        <Dialog title="Get more insights" width={640} className="k-icp-dialog-no-padding k-icp-dialog-with-form" onClose={onCancel}>
            <Form
                ignoreModified
                onSubmit={onAddObjectiveSubmit}
                render={formRenderProps => (
                    <FormElement noValidate className="k-icp-component-border">
                        <div className="k-window-content k-dialog-content">
                            <div className="k-mb-6">
                                IVA will analyze all interviews and extract valuable insights tailored to your learning objective.
                            </div>
                            <Field
                                name="objective"
                                component={ValidatedInput}
                                inputType={TextArea}
                                label="What do you want to learn?"
                                validator={interviewCollectionObjectiveValidators}
                                placeholder="Type here..."
                                rows={5}
                                maxLengthCount={1000}
                            />
                        </div>
                        <DialogActionsBar layout="center">
                            <Button disabled={!formRenderProps.allowSubmit || isAdding} type="submit" themeColor="primary">
                                Save
                            </Button>
                            <Button type="reset" onClick={onCancel}>
                                Cancel
                            </Button>
                        </DialogActionsBar>
                    </FormElement>
                )}
            />
        </Dialog>
    );
}
