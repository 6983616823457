import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, ReactNode, useState } from 'react';
import { useSingleClickCallback } from '../../../hooks/commonHooks';
import { ReactComponent as ExpandIcon } from '../../../icons/chevron-down.svg';
import { ReactComponent as CollapseIcon } from '../../../icons/chevron-up.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/trash-2.svg';
import { ReactComponent as InsightIcon } from '../../../images/insight-brain-illustration.svg';
import { combineClassNames } from '../../../services/common';
import { InterviewCollectionInsight } from '../../../services/experiments/interviewCollectionsService';

export function InterviewInsightsTopicPanel({
    collectionInsight,
    className,
    onDelete,
    isInternalPanel
}: {
    collectionInsight: InterviewCollectionInsight;
    clasName?: string;
    onDelete?: () => void | Promise<void>;
    className?: string;
    isInternalPanel?: boolean;
}) {
    return (
        <AIGeneratedResultsPanel
            icon={InsightIcon}
            title={<strong>{collectionInsight.topic}</strong>}
            onDelete={collectionInsight.objective ? onDelete : undefined}
            className={combineClassNames(isInternalPanel ? '!k-border-none !k-px-0' : undefined, className)}
            contentClassName={isInternalPanel ? 'k-px-4' : undefined}
        >
            {
                <div className={combineClassNames('k-icp-panel-base k-py-2 k-pr-4', isInternalPanel ? 'k-pl-9' : 'k-pl-12')}>
                    {collectionInsight.description.split('\r\n').map((description, index) => (
                        <p key={index} className="k-fs-sm">
                            {description}
                        </p>
                    ))}
                </div>
            }

            <div className={combineClassNames('k-pt-2 k-pr-4 formatted-content-area', isInternalPanel ? 'k-pl-9' : 'k-pl-12')}>
                <ul>
                    {collectionInsight.summaryInsights.map((insight, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: insight }} />
                    ))}
                </ul>
            </div>
        </AIGeneratedResultsPanel>
    );
}

export function AIGeneratedResultsPanel({
    icon: Icon,
    title,
    children,
    onDelete,
    isExpandedByDefault,
    className,
    contentClassName
}: {
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    title: ReactNode;
    children?: ReactNode;
    onDelete?: () => void | Promise<void>;
    isExpandedByDefault?: boolean;
    className?: string;
    contentClassName?: string;
}) {
    const hasActions = Boolean(children || onDelete);
    const [isDeleteInProgress, onDeleteCallback] = useSingleClickCallback(onDelete);
    const [isContentExpanded, setIsContentExpanded] = useState<boolean>(isExpandedByDefault || false);

    return (
        <div className={combineClassNames('k-icp-panel k-p-4', className)}>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                <Icon className="k-icp-icon-size-6" />
                <div className="k-flex-1">{title}</div>
                {hasActions && (
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-icp-component-border">
                        {onDeleteCallback && (
                            <Button
                                type="button"
                                fillMode="flat"
                                size="small"
                                className="k-icp-svg-icon-button"
                                onClick={onDeleteCallback}
                                disabled={isDeleteInProgress}
                            >
                                <DeleteIcon className="k-icp-icon" />
                            </Button>
                        )}
                        {onDeleteCallback && children && <div className="k-separator" />}
                        {children && (
                            <Button type="button" fillMode="flat" size="small" className="k-icp-svg-icon-button" onClick={() => setIsContentExpanded(e => !e)}>
                                {isContentExpanded ? <CollapseIcon className="k-icp-icon" /> : <ExpandIcon className="k-icp-icon" />}
                            </Button>
                        )}
                    </StackLayout>
                )}
            </StackLayout>
            {children && (
                <Reveal className="!k-display-block k-mx--4">
                    {isContentExpanded && <div className={combineClassNames('k-pt-4', contentClassName)}>{children}</div>}
                </Reveal>
            )}
        </div>
    );
}
