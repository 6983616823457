import { NavLink, NavLinkProps } from 'react-router-dom';
import { ReactComponent as StartMarker } from '../../../images/star.svg';
import { combineClassNames } from '../../../services/common';

export function InterviewCollectionsSidebarNavLink(props: NavLinkProps) {
    const className = props.className;
    const children = props.children;
    return (
        <NavLink
            {...props}
            className={typeof className === 'function' ? props => combineClassNames(className(props), 'k-relative') : combineClassNames(className, 'relative')}
        >
            {props => (
                <>
                    <StartMarker className={combineClassNames('k-absolute k-top--1 k-right--1', props.isActive ? 'k-icp-fill-primary' : 'k-icp-fill-base')} />
                    {typeof children === 'function' ? children(props) : children}
                </>
            )}
        </NavLink>
    );
}
