import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthLocationState, InvitationMessageComponent, useAuthLocationStateParameters, useInvitation, UserEmailForm } from '../../components/auth';
import { ActivationRequiredNotice } from '../../components/auth/activation';
import { LoginWithPasswordForm } from '../../components/auth/login';
import { H1 } from '../../components/ui/typography';
import { AuthenticationIntent, useAuthenticationIntent, useReturnUrl } from '../../hooks/routerHooks';
import { AuthenticationContext } from '../../services/authenticationService';
import { authenticationIntentUrlParamName, returnUrlParamName, urlWithQueryParams } from '../../services/common';
import { ideasService, InviteState } from '../../services/ideasService';
import { EmailAddressAvailability } from '../../services/usersService';
import { AuthFormFooter } from '../layouts/authLayout';

const emailAvailabilityToUrlPathMap: Record<EmailAddressAvailability, string | undefined> = {
    [EmailAddressAvailability.Available]: '../register',
    [EmailAddressAvailability.Taken]: 'password',
    [EmailAddressAvailability.Pending]: 'activate',
    [EmailAddressAvailability.Deleted]: undefined
};

export function AuthenticationPage() {
    const navigate = useNavigate();
    const intent = useAuthenticationIntent();
    const returnUrl = useReturnUrl();
    function onCompletedEmailForm(email: string, availability: EmailAddressAvailability) {
        const pathToNavigate = emailAvailabilityToUrlPathMap[availability];
        if (pathToNavigate === undefined) throw new Error('Unsupported email availability: ' + availability);
        const queryParams: Record<string, string> = {};
        queryParams[authenticationIntentUrlParamName] = intent;
        if (pathToNavigate !== '../register') queryParams[returnUrlParamName] = returnUrl;
        navigate(urlWithQueryParams(pathToNavigate, queryParams), { state: { email } as AuthLocationState });
    }
    const [queryParams] = useSearchParams();
    const updateIntentQueryParams = new URLSearchParams(queryParams);
    const updatedIntent: AuthenticationIntent = intent === 'login' ? 'register' : 'login';
    updateIntentQueryParams.set(authenticationIntentUrlParamName, updatedIntent);

    return (
        <>
            <Helmet>
                <title>Icanpreneur Platform {intent === 'login' ? 'Login' : 'Registration'}</title>
                <meta name="description" content="Provide username and password to authenticate into the Icanpreneur platform" />
            </Helmet>
            <H1>{intent === 'login' ? 'Welcome back' : 'Try for free'}</H1>
            <div className="auth-page-content-section">
                <UserEmailForm onComplete={onCompletedEmailForm} submitButtonText={intent === 'register' ? 'Create an account' : undefined} />
            </div>
            <AuthFormFooter className="auth-page-content-section">
                {intent === 'login' ? (
                    <>
                        No account yet?{' '}
                        <Link className="k-button-link-secondary" to={{ search: updateIntentQueryParams.toString() }}>
                            Sign up for free
                        </Link>
                    </>
                ) : (
                    <>
                        Already have an account?{' '}
                        <Link className="k-button-link-secondary" to={{ search: updateIntentQueryParams.toString() }}>
                            Log in now
                        </Link>
                    </>
                )}
            </AuthFormFooter>
        </>
    );
}

export function LoginWithPasswordPage() {
    const navigate = useNavigate();
    const returnUrl = useReturnUrl();
    const authStateParams = useAuthLocationStateParameters();
    const { hasInvitation } = authStateParams;

    const invitationData = useInvitation(authStateParams);

    const handleUserLogged = async () => {
        if (hasInvitation && invitationData) {
            const { invitation, inviteCode } = invitationData;
            if (invitation && inviteCode && invitation.state === InviteState.Pending) await ideasService.acceptInvite(inviteCode);
        }

        navigate(returnUrl, { state: { action: 'logged-in', origin: 'platform_default' } as AuthenticationContext });
    };

    return (
        <StackLayout align={{ horizontal: 'stretch', vertical: 'top' }} orientation="vertical" className="auth-page-content-section k-gap-12">
            <H1 className=" k-text-center ">{hasInvitation ? 'You’ve been invited to join a startup on Icanpreneur' : 'Welcome back'}</H1>
            {hasInvitation && !invitationData ? (
                <Skeleton shape="text" style={{ height: 112 }} />
            ) : hasInvitation && invitationData?.invitation.idea ? (
                <InvitationMessageComponent inviteIdea={invitationData.invitation.idea} inviteCreator={invitationData.invitation.creator} />
            ) : null}
            <LoginWithPasswordForm onLoggedIn={handleUserLogged} authLocationState={authStateParams} />
        </StackLayout>
    );
}

export function PendingActivationErrorPage() {
    const { email } = useAuthLocationStateParameters();
    if (!email) return null;

    return <ActivationRequiredNotice emailAddress={email} />;
}
