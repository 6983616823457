import { StackLayout } from '@progress/kendo-react-layout';
import { ReactComponent as IVATextIcon } from '../../icons/IVA-text.svg';
import { ReactComponent as AILoader } from '../../icons/ai-loader.svg';
import { combineClassNames } from '../../services/common';

export function IVALoadingIndicator({ className, children }: { className?: string; children?: React.ReactNode }) {
    return (
        <StackLayout align={{ horizontal: 'center', vertical: 'top' }} orientation="vertical" className={combineClassNames('k-text-center k-gap-2', className)}>
            <AILoader />
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-text-secondary k-text-start" style={{ lineHeight: '15px' }}>
                <IVATextIcon />
                <div className="k-separator k-mt-0.5 -mb-0.75" />
                <span style={{ fontSize: 15 }}>
                    icanpreneur
                    <br /> <span className="k-font-weight-light k-fs-sm">virtual assistant</span>
                </span>
            </StackLayout>
            <p>{children}</p>
        </StackLayout>
    );
}
