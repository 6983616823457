import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { XMLParser } from 'fast-xml-parser';
import { useEffect, useMemo } from 'react';
import { ReactComponent as AIBulb } from '../../icons/ai-bulb.svg';
import { useChatContext } from '../../pages/startups/startupGetStartedPage';
import { googleTagManager } from '../../scripts/googleTagManager';
import { combineClassNames } from '../../services/common';
import { ChatMessageType } from '../../services/ideaOnboardingService';
import { useAppDispatch } from '../../state/hooks';
import { P } from './typography';
import { addErrorNotification } from '../../state/notifications/platformNotificationsSlice';

export interface BlockProps {
    text: string;
    messageIdx?: number;
    readonly?: boolean;
    onClick?: (params?: any) => void;
}

export function parseOptions(optionsXml: string): { index: string; content: string }[] {
    const parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: '',
        textNodeName: 'content'
    });

    const parsed = parser.parse(optionsXml);

    if (!parsed.options?.option) {
        return [];
    }

    // Handle both single option and array of options
    const optionsArray = Array.isArray(parsed.options.option) ? parsed.options.option : [parsed.options.option];

    return optionsArray.map((opt: { index: any; content: any }) => ({
        index: opt.index,
        content: opt.content
    }));
}

export function parseTracking(trackingXml: string): string {
    const parser = new XMLParser();
    const parsed = parser.parse(trackingXml);
    return parsed.tracking || '';
}

export const TextBlock: React.FC<BlockProps> = ({ text }) => {
    return <>{text}</>;
};

export const OptionsBlock: React.FC<BlockProps> = ({ text, messageIdx, onClick, readonly }) => {
    const options = useMemo(() => parseOptions(text), [text]);
    const { messages } = useChatContext();
    const nextMessage = messageIdx !== undefined ? messages[messageIdx + 1] : undefined;
    const isDisabled = messageIdx !== undefined && messageIdx < messages.length - 1;

    return (
        <div className="chat-options-block k-my-2">
            {options.map(option => {
                const isSelected = nextMessage && nextMessage.type === ChatMessageType.User && nextMessage.content === option.content;
                return (
                    <div
                        key={option.index}
                        className={combineClassNames('k-flex-1 k-pos-relative', isDisabled || readonly ? '' : 'k-cursor-pointer')}
                        onClick={
                            !isDisabled && !readonly
                                ? () => {
                                      onClick?.(option.content);
                                  }
                                : undefined
                        }
                    >
                        <StackLayout
                            orientation="vertical"
                            align={{ horizontal: 'start', vertical: 'top' }}
                            className={` k-p-4 k-gap-2  ${isDisabled && !isSelected ? 'k-disabled' : ''} ${
                                isSelected
                                    ? 'k-border k-border-solid k-rounded-lg k-border-transparent'
                                    : 'k-icp-panel highlight-border-on-hover k-icp-interactive-card-small'
                            }`}
                        >
                            <AIBulb className={`k-icp-icon-size-4 ${isDisabled && !isSelected ? 'k-icp-icon' : ''}`} />
                            <p>{option.content}</p>
                        </StackLayout>
                        {isSelected && <div className="k-border k-border-solid ai-border ai-border--purple k-rounded-lg k-border-transparent" />}
                    </div>
                );
            })}
        </div>
    );
};

export const GoToJourneyBlock: React.FC<BlockProps> = props => {
    return (
        <ActionCard
            {...props}
            title="Your Guided Entrepreneurial Journey is Ready"
            description="Explore the Icanpreneur platform, follow the structured journey, and start turning your vision into reality."
            buttonText="Take me to my journey"
        />
    );
};

export const CanvasBlock: React.FC<BlockProps> = props => {
    return (
        <ActionCard
            {...props}
            title="Your Business Idea Canvas is Ready"
            description="Review your canvas and make any necessary adjustments to align it with your vision."
            buttonText="Take me to my canvas"
        />
    );
};

const trackingEvents = ['looking-for-business-idea', 'with-business-idea', 'improve-existing-business'];

export const TrackingBlock: React.FC<BlockProps> = ({ text, messageIdx }) => {
    useEffect(() => {
        if (messageIdx) return;
        const trackingEvent = parseTracking(text).toLowerCase();

        if (trackingEvents.includes(trackingEvent)) {
            googleTagManager.reportChatOptionSelectedEvent(trackingEvent);
        }
    }, [messageIdx, text]);

    return null;
};

export const ErrorBlock: React.FC<BlockProps> = ({ messageIdx }) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (messageIdx) return;

        dispatch(addErrorNotification('Error processing message. Please try again in a few minutes.'));
    }, [dispatch, messageIdx]);

    return null;
};

interface ActionCardProps extends BlockProps {
    title: string;
    description: string;
    buttonText: string;
}

function ActionCard({ title, description, buttonText, onClick, readonly }: ActionCardProps) {
    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'start', vertical: 'top' }}
            style={{ maxWidth: 414 }}
            className="k-icp-panel k-p-4 k-gap-4 k-mt-4 k-mb-2"
        >
            <div>
                <div className="k-font-semibold k-mb-1">{title}</div>
                <P>{description}</P>
            </div>
            <Button disabled={readonly} themeColor="primary" onClick={() => onClick?.()}>
                {buttonText}
            </Button>
        </StackLayout>
    );
}
