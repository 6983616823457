import { useConfirmDialog } from '../../../hooks/dialogHooks';
import {
    AIConversationMessage,
    InterviewCollectionGoal,
    interviewCollectionsService,
    MinimalCollectionInterview
} from '../../../services/experiments/interviewCollectionsService';

export const interviewCollectionGoalLabelsMap: Record<InterviewCollectionGoal, string> = {
    [InterviewCollectionGoal.ProblemValidation]: 'Problem discovery and validation',
    [InterviewCollectionGoal.BuyerPersona]: 'Buyer persona',
    [InterviewCollectionGoal.WinLossAnalysis]: 'Win/loss',
    [InterviewCollectionGoal.Other]: 'Other'
};

export const allowedInterviewFileExtensions = ['.pdf', '.docx', '.doc', '.rtf', '.txt'];
export const maxInterviewFileSizeInBytes = 10 * 1024 * 1024; // 10 MB

export function useDeleteCollectionInterview(ideaId: string, collectionId: number) {
    const { show: showDeleteDialog, element: deleteDialog } = useConfirmDialog();

    async function deleteInterview(
        interview: MinimalCollectionInterview,
        deleteCollectionCheck: (collectionId: number) => boolean | Promise<boolean>,
        onDeleted?: (collectionDeleted: boolean) => void
    ) {
        showDeleteDialog({
            title: 'Delete interview',
            content: `Are you sure you want to delete ${interview.interviewee ? `interview with ${interview.interviewee}` : 'this interview'}?`,
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete interview',
            callback: async () => {
                await interviewCollectionsService.deleteInterview(ideaId, collectionId, interview.id);
                const shouldDeleteCollection = await deleteCollectionCheck(collectionId);
                if (shouldDeleteCollection) await interviewCollectionsService.deleteInterviewCollection(ideaId, collectionId);
                onDeleted?.(shouldDeleteCollection);
            }
        });
    }

    return [deleteInterview, deleteDialog] as const;
}

export function stripAIThinkingMessage(role: AIConversationMessage['role'], message: string) {
    if (role !== 'assistant') return message;
    if (!message.startsWith('<thinking>')) return message;

    const answerTagName = 'answer';
    const answerStartTag = `<${answerTagName}>`;
    const answerIndex = message.indexOf(answerStartTag);
    if (answerIndex === -1) return '';
    const answerStartIndex = answerIndex + answerStartTag.length;
    const answerEndIndex = message.indexOf(`</${answerTagName}>`, answerStartIndex);

    return message.substring(answerStartIndex, answerEndIndex === -1 ? undefined : answerEndIndex).trim();
}
