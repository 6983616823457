import { StackLayout } from '@progress/kendo-react-layout';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ResponsiveGroup, useResponsiveLayout } from '../../hooks/commonHooks';
import { ReactComponent as RightArrowIcon } from '../../icons/chevron-right.svg';
import { ReactComponent as StartupsIcon } from '../../icons/startups.svg';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { updateIdea } from '../../state/idea/ideaSlice';

export default function StartupTitleNavEditor() {
    const currentIdea = useAppSelector(s => s.idea.current);
    const currentRole = useAppSelector(s => s.idea.role);
    const [updatedTitle, setUpdatedTitle] = useState(currentIdea?.title || '');
    const [isUpdatingTitle, setIsUpdatingTitle] = useState(false);
    const startupTitleInput = useRef<HTMLInputElement>(null);
    const responsiveGroup = useResponsiveLayout();
    const isMobile = responsiveGroup === ResponsiveGroup.xs;
    const dispatch = useAppDispatch();

    useEffect(() => {
        setUpdatedTitle(currentIdea?.title || '');
        setIsUpdatingTitle(false);
    }, [currentIdea]);

    useEffect(() => {
        if (isUpdatingTitle && startupTitleInput.current) {
            startupTitleInput.current.focus();
            startupTitleInput.current.select();
        }
    }, [isUpdatingTitle]);

    const updateIdeaTitle = async () => {
        if (startupTitleInput.current && !startupTitleInput.current.validity.valid) {
            cancelUpdate();
        } else {
            if (currentIdea?.title !== updatedTitle) {
                await dispatch(updateIdea({ title: updatedTitle }));
                setIsUpdatingTitle(false);
            } else {
                setIsUpdatingTitle(false);
            }
        }
    };

    const cancelUpdate = () => {
        if (!currentIdea) return;
        setUpdatedTitle(currentIdea.title);
        setIsUpdatingTitle(false);
    };

    const canUpdateTitle = currentRole && currentRole !== UserRole.Viewer;

    if (!currentIdea) return null;

    return (
        <>
            <div className="vertical-separator vertical-separator-short"></div>
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className={`top-nav-editor${isUpdatingTitle ? ' top-nav-editor--in-edit' : ''}`}>
                {!isMobile && (
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="top-nav-editor-breadcrumb">
                        <Tooltip anchorElement="target" position="bottom" openDelay={0}>
                            <NavLink
                                to="/"
                                title="Startups"
                                className="k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger"
                            >
                                <StartupsIcon className="k-icp-icon" />
                            </NavLink>
                        </Tooltip>
                        <div className="k-p-1">
                            <RightArrowIcon className="k-icp-icon-size-4 k-display-block" />
                        </div>
                    </StackLayout>
                )}
                {isUpdatingTitle ? (
                    <input
                        required={true}
                        maxLength={150}
                        ref={startupTitleInput}
                        type="text"
                        value={updatedTitle}
                        onChange={e => setUpdatedTitle(e.target.value)}
                        onBlur={e => updateIdeaTitle()}
                        onKeyUp={e => {
                            if (e.key === 'Enter') updateIdeaTitle();
                            else if (e.key === 'Escape') cancelUpdate();
                        }}
                        className="k-input k-input-lg !k-border-none !k-p-0"
                    />
                ) : (
                    <span
                        className={`k-cursor-pointer k-text-ellipsis highlight-target-inset${isMobile ? ' k-fs-md k-font-weight-semibold' : 'k-fs-lg'}`}
                        onClick={canUpdateTitle ? () => setIsUpdatingTitle(true) : undefined}
                    >
                        {currentIdea.title}
                    </span>
                )}
            </StackLayout>
        </>
    );
}
