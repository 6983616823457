import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { guid } from '@progress/kendo-react-common';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AIChatPanel } from '../../../components/ai/aiChatPanel';
import { BoundDropDownButton } from '../../../components/common/boundDropDownButton';
import { CollectionInterviewFilesList } from '../../../components/experiments/interviewCollections/collectionInterviewFilesList';
import {
    interviewCollectionGoalLabelsMap,
    stripAIThinkingMessage,
    useDeleteCollectionInterview
} from '../../../components/experiments/interviewCollections/common';
import { DonutProgress } from '../../../components/ui/donutProgress';
import LoadingIndicator from '../../../components/ui/loadingIndicator';
import { TextWithShowMoreByLines } from '../../../components/ui/textWithShowMoreByLines';
import { TypingDots } from '../../../components/ui/typingDots';
import { H2, H3 } from '../../../components/ui/typography';
import { useIdeaParams, useIntParam } from '../../../hooks/routerHooks';
import { useLazyHtmlParser } from '../../../hooks/useLazyHtmlParser';
import { ReactComponent as ExpandIcon } from '../../../icons/chevron-down.svg';
import { ReactComponent as CollapseIcon } from '../../../icons/chevron-up.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit-2.svg';
import strengthsIllustrationSrc from '../../../images/green-check-illustration.svg';
import principleIllustrationSrc from '../../../images/principle-illustration.svg';
import { combineClassNames } from '../../../services/common';
import { CollectionInterview, InterviewCollection, interviewCollectionsService } from '../../../services/experiments/interviewCollectionsService';
import { RealTimeExperimentalInterviewCollectionEventData, realTimeUpdatesEventHub } from '../../../services/realTimeUpdatesService';
import { UserRole } from '../../../services/usersService';
import { useAppSelector } from '../../../state/hooks';
import { InterviewInsightsTopicPanel } from '../components/interviewInsights';

export function InterviewInCollectionPage() {
    const { ideaId } = useIdeaParams();
    const collectionId = useIntParam('collectionId');
    const interviewId = useIntParam('interviewId');
    const htmlParseFunction = useLazyHtmlParser();

    const currentUserRole = useAppSelector(s => s.idea.role);
    const canManageInterview = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;
    const navigate = useNavigate();

    const [deleteInterview, deleteInterviewDialog] = useDeleteCollectionInterview(ideaId, collectionId);
    const [interviewsCollection, setInterviewsCollection] = useState<InterviewCollection>();
    const [interview, setInterview] = useState<CollectionInterview>();

    const getInterviewData = useCallback(() => {
        interviewCollectionsService.getInterview(ideaId, collectionId, interviewId).then(setInterview);
        interviewCollectionsService.getInterviewCollection(ideaId, collectionId).then(setInterviewsCollection);
    }, [collectionId, ideaId, interviewId]);

    useEffect(() => {
        getInterviewData();

        const onAnalysisComplete = (e: RealTimeExperimentalInterviewCollectionEventData) => {
            if (e.ideaId !== ideaId || e.interviewCollectionId !== collectionId) return;
            getInterviewData();
        };

        realTimeUpdatesEventHub.addEventListener('interviewCollection', 'analysisComplete', onAnalysisComplete);
        realTimeUpdatesEventHub.addEventListener('interviewCollection', 'interviewScriptAnalysisComplete', onAnalysisComplete);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interviewCollection', 'analysisComplete', onAnalysisComplete);
            realTimeUpdatesEventHub.removeEventListener('interviewCollection', 'interviewScriptAnalysisComplete', onAnalysisComplete);
        };
    }, [collectionId, getInterviewData, ideaId]);

    const chatId = useMemo(() => guid(), []);

    if (!interview || !interviewsCollection) {
        return <LoadingIndicator size="big" className="!k-pos-absolute k-centered" />;
    }

    const isAnalyzing = interview.analysis === null || interview.analysis?.pending;
    const isFileTranscript = !isAnalyzing && !!interview.fileIsTranscript;
    const hasScriptFile = interview.script !== null;

    const qualityAnalysisSummary = !isFileTranscript
        ? 'Quality analysis cannot be run because the uploaded file is not a complete interview transcript.'
        : interview.analysis?.qualityAnalysis?.summary;

    const scriptCoverageSummary = !isFileTranscript
        ? 'Script coverage cannot be assessed because the uploaded file is not a complete interview transcript.'
        : !hasScriptFile
        ? 'Upload an interview script in order to assess how well it was followed in the interview.'
        : 'Shows how closely the actual interview followed the scripted questions. The score indicates the percentage of scripted questions that were covered during the interview.';

    const insightCoverage =
        'Interview insights are concise summaries from a transcript addressing specific questions or topics. The percentage indicates what proportion of the predefined questions are answered by the extracted insights.';

    return (
        <>
            <div className="heading-row">
                <Link to={`../${collectionId}/interviews`} className="k-button k-button-link k-fs-sm k-button-link-base k-font-weight-semibold k-mt-2">
                    <span className="k-icon k-i-arrow-chevron-left"></span> All interviews
                </Link>
            </div>
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-10">
                    <div>
                        <H2 className="!k-mb-3">{interview.title}</H2>
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                            <Link
                                to="edit"
                                className={combineClassNames(
                                    'k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md',
                                    isAnalyzing ? 'k-disabled' : undefined,
                                    canManageInterview ? undefined : 'k-disabled'
                                )}
                            >
                                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                    <EditIcon className="k-icp-icon-size-4" />
                                    Edit interview
                                </StackLayout>
                            </Link>

                            <BoundDropDownButton
                                icon="arrow-60-down"
                                buttonClass="k-flex-row-reverse"
                                text="More actions"
                                disabled={isAnalyzing}
                                items={[
                                    {
                                        text: 'Delete interview',
                                        action: () =>
                                            interview &&
                                            deleteInterview(
                                                interview,
                                                () => interviewCollectionsService.getInterviews(ideaId, collectionId).then(interviews => !interviews.length),
                                                (collectionDeleted: boolean) => navigate(collectionDeleted ? '..' : `../${collectionId}`)
                                            ),
                                        separated: true,
                                        danger: true,
                                        disabled: !canManageInterview || interview.analysis?.pending
                                    }
                                ]}
                            />
                            {deleteInterviewDialog}
                        </StackLayout>
                    </div>
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                        <InterviewAnalysisPanel
                            title="Quality score"
                            isNotAvailable={!isFileTranscript}
                            isAnalyzing={isAnalyzing}
                            score={interview.qualityScore}
                            description={qualityAnalysisSummary}
                            renderExpandedContent={
                                !interview.analysis?.qualityAnalysis?.suggestions || interview.analysis.qualityAnalysis.suggestions.length === 0
                                    ? undefined
                                    : () => (
                                          <StackLayout
                                              orientation="vertical"
                                              align={{ horizontal: 'stretch', vertical: 'top' }}
                                              className="k-gap-4 k-icp-component-border"
                                          >
                                              {interview.analysis!.qualityAnalysis!.suggestions.map((suggestion, suggestionIndex) => (
                                                  <Fragment key={suggestionIndex}>
                                                      <div className="k-separator" />
                                                      <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                                                          <img src={principleIllustrationSrc} width="24" height="24" alt="" />
                                                          <div>
                                                              <strong className="k-mb-2">{suggestion.principle}</strong>
                                                              <div>{suggestion.description}</div>
                                                          </div>
                                                      </StackLayout>
                                                  </Fragment>
                                              ))}
                                          </StackLayout>
                                      )
                            }
                        />
                        {
                            <InterviewAnalysisPanel
                                isAnalyzing={isAnalyzing}
                                isNotAvailable={!isFileTranscript || !hasScriptFile}
                                score={interview.scriptCoverage}
                                title="Script coverage"
                                description={scriptCoverageSummary}
                                renderExpandedContent={
                                    interview.analysis &&
                                    ((interview.analysis.scriptAnalysis?.areasForImprovement &&
                                        interview.analysis.scriptAnalysis?.areasForImprovement.length) ||
                                        (interview.analysis.scriptAnalysis?.strengths && interview.analysis.scriptAnalysis?.strengths.length))
                                        ? () => {
                                              const areasForImprovement = interview.analysis!.scriptAnalysis!.areasForImprovement;
                                              const strengths = interview.analysis!.scriptAnalysis!.strengths;
                                              return (
                                                  <StackLayout
                                                      orientation="vertical"
                                                      align={{ horizontal: 'stretch', vertical: 'top' }}
                                                      className="k-gap-4 k-icp-component-border"
                                                  >
                                                      {strengths && strengths.length > 0 && (
                                                          <InterviewScriptCoveragePanelSection
                                                              title="Strengths"
                                                              bullets={strengths}
                                                              iconSrc={strengthsIllustrationSrc}
                                                          />
                                                      )}
                                                      {areasForImprovement && areasForImprovement.length > 0 && (
                                                          <InterviewScriptCoveragePanelSection
                                                              title="Areas for improvement"
                                                              bullets={areasForImprovement}
                                                              iconSrc={principleIllustrationSrc}
                                                          />
                                                      )}
                                                  </StackLayout>
                                              );
                                          }
                                        : undefined
                                }
                            />
                        }
                        {
                            <InterviewAnalysisPanel
                                title="Insights coverage"
                                isAnalyzing={isAnalyzing}
                                isNotAvailable={isAnalyzing}
                                score={interview.insightCoverage}
                                description={insightCoverage}
                                renderExpandedContent={
                                    !interview.analysis || !interview.analysis.insightAnalysis || interview.analysis.insightAnalysis.insights.length === 0
                                        ? undefined
                                        : () => {
                                              const topics = interviewsCollection.analysis.topics!;

                                              return (
                                                  <>
                                                      {topics.map((insightTopic, index) => {
                                                          const insights = interview
                                                              .analysis!.insightAnalysis!.insights.filter(i => i.topic === insightTopic.topic)
                                                              .map(i => i.insight);

                                                          if (!insights.length) return null;

                                                          return (
                                                              <React.Fragment key={`${insightTopic}_${index}`}>
                                                                  {index < topics.length && <div className="k-separator k-icp-component-border" />}
                                                                  <InterviewInsightsTopicPanel
                                                                      isInternalPanel
                                                                      className={index === topics.length - 1 ? '!k-pb-0' : undefined}
                                                                      collectionInsight={{
                                                                          topic: insightTopic.topic,
                                                                          summaryInsights: insights,
                                                                          questions: insightTopic.questions,
                                                                          description: insightTopic.description,
                                                                          objective: insightTopic.objective
                                                                      }}
                                                                  />
                                                              </React.Fragment>
                                                          );
                                                      })}
                                                  </>
                                              );
                                          }
                                }
                            />
                        }
                    </StackLayout>
                </StackLayout>

                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-w2 k-gap-4 k-align-self-stretch">
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-rounded k-icp-panel-base k-p-4 k-gap-4">
                        <H3>Interview details</H3>
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                            <strong>Host</strong>
                            <div>{interview.host || 'Unknown host'}</div>
                        </StackLayout>

                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                            <strong>Interviewee</strong>
                            <div>{interview.interviewee || 'Unknown interviewee'}</div>
                        </StackLayout>

                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                            <strong>Type of interview</strong>
                            <div>{interviewCollectionGoalLabelsMap[interviewsCollection.goal]}</div>
                        </StackLayout>

                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                            <strong>Uploaded files</strong>
                            <CollectionInterviewFilesList
                                ideaId={ideaId}
                                collectionId={collectionId}
                                interviewId={interviewId}
                                files={interview.script ? [interview.script, interview.file] : [interview.file]}
                                interviewScriptFileId={interview.script?.id}
                            />
                        </StackLayout>
                    </StackLayout>
                    <div className="k-rounded k-icp-panel-base k-p-4 k-gap-4">
                        <InterviewSummary loading={isAnalyzing}>
                            {htmlParseFunction && interview.analysis?.summary && htmlParseFunction(interview.analysis.summary)}
                        </InterviewSummary>
                    </div>
                    <AIChatPanel
                        disabled={isAnalyzing}
                        placeholder="Ask IVA about this interview..."
                        suggestedQuestions={[
                            'What are the most important problems or pain points mentioned during the interview?',
                            'What workarounds or alternative solutions is the interviewee currently using to address their most pressing problems?',
                            'Were there any budgetary constraints or time limits that influenced the choice of the solution?'
                        ]}
                        getAnswer={conversation => interviewCollectionsService.askQuestion(ideaId, collectionId, interviewId, chatId, conversation)}
                        formatMessage={stripAIThinkingMessage}
                    />
                </StackLayout>
            </StackLayout>
        </>
    );
}

function InterviewScriptCoveragePanelSection({ title, bullets, iconSrc }: { title: string; bullets: string[]; iconSrc: string }) {
    return (
        <>
            {' '}
            <div className="k-separator" />
            <div>
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3 k-mb-2">
                    <img src={iconSrc} width="24" height="24" alt="" />
                    <strong>{title}</strong>
                </StackLayout>
                <div className="k-pl-9 formatted-content-area">
                    <ul>
                        {bullets.map((bullet, index) => (
                            <li key={index}>{bullet}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

function InterviewSummary({ children: summary, loading = false, className }: { children?: string; loading?: boolean; className?: string }) {
    const [showFullSummary, setShowFullSummary] = useState<boolean>();

    const loadingElement = (
        <>
            <Skeleton shape="text" style={{ width: '70%' }} />
            <Skeleton shape="text" className="k-mb-2" style={{ width: '100%' }} />
        </>
    );

    return (
        <div className={className}>
            <H3 className="!k-mb-2">Interview summary by IVA</H3>
            {loading ? (
                loadingElement
            ) : (
                <TextWithShowMoreByLines
                    lines={2}
                    className="experimental-interview-summary-preview"
                    onToggleTruncate={e => {
                        if (!e.showMore) return;

                        e.preventDefault = true;
                        setShowFullSummary(true);
                    }}
                    loader={loadingElement}
                >
                    {summary || 'Summary not available.'}
                </TextWithShowMoreByLines>
            )}

            {showFullSummary && (
                <Dialog
                    title="Interview summary by IVA"
                    className="experimental-interview-summary-formatted"
                    onClose={() => setShowFullSummary(false)}
                    width={640}
                >
                    {summary}
                    <DialogActionsBar layout="center">
                        <Button type="button" onClick={() => setShowFullSummary(false)}>
                            Close
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
}

function InterviewAnalysisPanel({
    title,
    description,
    score,
    isAnalyzing,
    isNotAvailable,
    renderExpandedContent
}: {
    title: string;
    description?: string;
    isAnalyzing?: boolean;
    isNotAvailable?: boolean;
    score?: number | null;
    renderExpandedContent?: () => ReactNode;
}) {
    const [isExpanded, setIsExpanded] = useState<boolean>();
    const isExpandable = !isAnalyzing && Boolean(renderExpandedContent);

    const roundedScore = score && Math.round(score);
    const hasScore = roundedScore !== null && roundedScore !== undefined;
    const progress = hasScore ? roundedScore : undefined;
    return (
        <div className="k-p-4 k-icp-panel">
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                <DonutProgress size="24" borderWidth="5" progress={!isAnalyzing && !isNotAvailable ? progress : undefined} className="k-shrink-0">
                    {isAnalyzing ? (
                        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-icp-subtle-text">
                            Analyzing
                            <span style={{ width: 9 }}>
                                <TypingDots />
                            </span>
                        </StackLayout>
                    ) : isNotAvailable ? (
                        <div className="k-icp-subtle-text k-text-center k-text-uppercase k-icp-text-sm">Not Enough Data</div>
                    ) : (
                        <span className="k-text-secondary k-font-light" style={{ fontSize: '32px' }}>
                            {roundedScore || 0}
                            {'%'}
                        </span>
                    )}
                </DonutProgress>
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-align-self-center k-flex-1 k-gap-2">
                    <strong className="k-fs-lg">{title}</strong>
                    <div className={isExpandable && !isExpanded ? 'max-lines-4' : undefined}>
                        {isAnalyzing ? (
                            <>
                                <Skeleton shape="text" style={{ width: '100%' }} />
                                <Skeleton shape="text" style={{ width: '30%' }} />
                            </>
                        ) : (
                            description
                        )}
                    </div>
                </StackLayout>
                {isExpandable && (
                    <Button type="button" fillMode="flat" size="small" className="k-icp-svg-icon-button" onClick={() => setIsExpanded(e => !e)}>
                        {isExpanded ? <CollapseIcon className="k-icp-icon" /> : <ExpandIcon className="k-icp-icon" />}
                    </Button>
                )}
            </StackLayout>
            {isExpandable && <Reveal className="!k-display-block">{isExpanded && <div className="k-pt-4 k-px-2">{renderExpandedContent!()}</div>}</Reveal>}
        </div>
    );
}
