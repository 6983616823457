import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import { InvitationMessageComponent, useAuthLocationStateParameters, useInvitation } from '../../components/auth';
import { RegistrationForm } from '../../components/auth/registration';
import { H1 } from '../../components/ui/typography';
import { useReturnUrl } from '../../hooks/routerHooks';
import { AuthenticationContext } from '../../services/authenticationService';
import { ideasService, InviteState } from '../../services/ideasService';

export function RegistrationPage() {
    const returnUrl = useReturnUrl();
    const navigate = useNavigate();
    const authStateParams = useAuthLocationStateParameters();
    const { hasInvitation } = authStateParams;

    const invitationData = useInvitation(authStateParams);

    const handleUserRegistered = async () => {
        if (hasInvitation && invitationData) {
            const { invitation, inviteCode } = invitationData;
            if (invitation && inviteCode && invitation.state === InviteState.Pending) await ideasService.acceptInvite(inviteCode);
            navigate(returnUrl, { state: { action: 'registered', origin: 'platform_default' } as AuthenticationContext });
        } else {
            const idea = await ideasService.createDefault();
            navigate(ideasService.getStartupRedirectUrl(idea), { state: { action: 'registered', origin: 'platform_default' } as AuthenticationContext });
        }
    };

    return (
        <StackLayout align={{ horizontal: 'stretch', vertical: 'top' }} orientation="vertical" className="auth-page-content-section k-gap-12">
            <H1 className=" k-text-center ">{hasInvitation ? 'You’ve been invited to join a startup on Icanpreneur' : 'Create an account'}</H1>
            {hasInvitation && !invitationData ? (
                <Skeleton shape="text" style={{ height: 112 }} />
            ) : hasInvitation && invitationData?.invitation.idea ? (
                <InvitationMessageComponent inviteIdea={invitationData.invitation.idea} inviteCreator={invitationData.invitation.creator} />
            ) : null}
            <RegistrationForm onRegistered={handleUserRegistered} locationStateParams={authStateParams} />
        </StackLayout>
    );
}
