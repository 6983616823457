import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Switch, TextAreaProps } from '@progress/kendo-react-inputs';
import { StackLayout, StackLayoutHandle } from '@progress/kendo-react-layout';
import React, { ComponentType, forwardRef, ReactNode, RefAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { TaskEditorProps, TaskEditorRef, useTaskCommitOperation } from '.';
import { usePreviousValue, useSingleClickButton } from '../../../hooks/commonHooks';
import { ReactComponent as ExpandIcon } from '../../../icons/chevron-down.svg';
import { ReactComponent as CollapseIcon } from '../../../icons/chevron-up.svg';
import { ReactComponent as ClockIcon } from '../../../icons/clock.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as ReorderIcon } from '../../../icons/reorder.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash-2.svg';
import { useResearchJobToBeDone } from '../../../pages/journey/extensibility/researchExtensions';
import {
    InterviewScript,
    InterviewScriptEntry,
    InterviewScriptEntryLabel,
    InterviewScriptSection,
    interviewScriptsService,
    InterviewScriptTip
} from '../../../services/interviewScriptsService';
import { InterviewScriptEditorParams, JourneyTaskGuidanceHints } from '../../../services/journeyService';
import { BoundDropDownButton, DropDownButtonItem } from '../../common/boundDropDownButton';
import {
    InlineEditor,
    InlineEditorComponentHandle,
    InlineEditorComponentProps,
    InlineEditorInput,
    InlineEditorNumericTextBox,
    InlineEditorTextArea,
    InlineEditorViewerProps
} from '../../common/inlineEditor';

import { AsyncOperationsQueue, combineClassNames, immutableAdd, immutableRemove, immutableUpdate } from '../../../services/common';
import { dateTimeService } from '../../../services/dateTimeService';
import { domService } from '../../../services/domService';
import {
    RealTimeUpdateInterviewScriptData,
    RealTimeUpdateInterviewScriptEntryEventData,
    RealTimeUpdateInterviewScriptSectionEventData,
    RealTimeUpdateInterviewScriptTipEventData,
    realTimeUpdatesEventHub
} from '../../../services/realTimeUpdatesService';
import { researchService } from '../../../services/researchService';
import { useAppDispatch } from '../../../state/hooks';
import { addErrorNotification, addNotification } from '../../../state/notifications/platformNotificationsSlice';
import { IVALoadingIndicator } from '../../ai/ivaLoadingIndicator';
import { Hints, HintsDefaultLayout } from '../../common/Hints';
import { InterviewScriptEntryLabelView } from '../../interview/entries/interviewScriptEntryLabelView';
import { SvgIconButtonContent } from '../../ui/svgIconButtonContent';
import { InterviewQuestionListItem, InterviewScriptQuestionsSortableList } from './interviewDraggableQuestionView';
import { EditorMainArea } from './shared/editorMainArea';

interface TipsOperations {
    createTip: (entryId: number, sectionId: number, content: string) => Promise<void>;
    updateTip: (entryId: number, sectionId: number, tipId: number, content: string) => Promise<void>;
    deleteTip: (sectionId: number, entryId: number, tipId: number) => Promise<void>;
}

interface InterviewQuestionOperations {
    createQuestion: (sectionId: number, content: string) => Promise<void>;
    updateQuestion: (sectionId: number, entryId: number, content: string) => Promise<void>;
    deleteQuestion: (sectionId: number, entryId: number) => Promise<void>;
    reorderQuestion: (sectionId: number, entryId: number, newIdx: number) => Promise<void>;
    hideQuestion: (sectionId: number, entryId: number) => Promise<void>;
    unhideQuestion: (sectionId: number, entryId: number) => Promise<void>;
    resetQuestion: (sectionId: number, entryId: number) => Promise<void>;
}

interface InterviewSectionOperations {
    updateSection: (sectionId: number, title: string, durationInMinutes: number) => Promise<void>;
    insertSectionBefore: (sectionId: number) => Promise<void>;
    insertSectionAfter: (sectionId: number) => Promise<void>;
    hideSection: (sectionId: number) => Promise<void>;
    unhideSection: (sectionId: number) => Promise<void>;
    resetSection: (sectionId: number) => Promise<void>;
    deleteSection: (sectionId: number) => Promise<void>;
    moveSectionUp: (sectionId: number) => Promise<void>;
    moveSectionDown: (sectionId: number) => Promise<void>;
}

export const InterviewScriptEditor = forwardRef<TaskEditorRef, TaskEditorProps<InterviewScriptEditorParams>>(function(props, ref) {
    const {
        ideaId,
        taskData,
        setTaskData,
        isEditing,
        isHidden,
        params: { researchId }
    } = props;

    const [, currentJtbd] = useResearchJobToBeDone(ideaId, researchId, taskData, setTaskData);
    const entryOperationsQueue = useRef<Partial<Record<number, AsyncOperationsQueue>>>({});
    const asyncSectionOperationsQueue = useRef<Partial<Record<number, AsyncOperationsQueue>>>({});
    const optimisticSectionMoveOperationsQueue = useRef<AsyncOperationsQueue>(new AsyncOperationsQueue());
    const createTaskCommitOperation = useTaskCommitOperation(props);

    function enqueueSectionOperation<T>(sectionId: number, operation: () => Promise<T>): Promise<T> {
        if (!asyncSectionOperationsQueue.current[sectionId]) {
            asyncSectionOperationsQueue.current[sectionId] = new AsyncOperationsQueue();
        }
        return asyncSectionOperationsQueue.current[sectionId]!.execute(operation);
    }

    function enqueueEntryOperation<T>(entryId: number, operation: () => Promise<T>): Promise<T> {
        if (!entryOperationsQueue.current[entryId]) {
            entryOperationsQueue.current[entryId] = new AsyncOperationsQueue();
        }
        return entryOperationsQueue.current[entryId]!.execute(operation);
    }

    const dispatch = useAppDispatch();
    const interviewScript = taskData?.researchInterviewScript?.[researchId];
    const hints = props.guidance ? props.guidance.find((g): g is JourneyTaskGuidanceHints => g.id === props.params.questionHintsRef) : undefined;

    const [disabledWhileUpdatingList, pendingListUpdateActionWrapper] = useSingleClickButton<[number], Promise<void>>();
    const [generateScriptOnErrorDisabled, generateScriptOnErrorButton] = useSingleClickButton<[], Promise<void>>();

    const showErrors = props.isEditing && !!props.errors?.length;
    const scriptTime = interviewScript?.sections?.reduce((acc, s) => acc + s.durationInMinutes, 0) || 0;
    const recommendedMaxTime = 45;
    const fiveMinsBeforeNow = dateTimeService.addMinutes(new Date(), -5);
    const interviewScriptGenerationTimeout =
        interviewScript && !interviewScript.ready && !interviewScript.error && interviewScript.createdOn < fiveMinsBeforeNow;
    const interviewScriptGenerating = interviewScript && !interviewScript.ready && !interviewScript.error && interviewScript.createdOn >= fiveMinsBeforeNow;
    const interviewScriptError = interviewScript?.error || interviewScriptGenerationTimeout;
    const scriptMessageWrapperRef = useRef<HTMLDivElement>(null);
    const firstScriptSectionRef = useRef<HTMLDivElement>(null);

    const scriptErrorPrevVal = usePreviousValue(interviewScript?.error);
    const scriptReadyPrevVal = usePreviousValue(interviewScript?.ready);

    useEffect(() => {
        if (showErrors) {
            dispatch(addErrorNotification(props.errors![0]));
        }
    }, [dispatch, props.errors, showErrors]);

    useEffect(() => {
        // trigger only when script state changes, not when loading
        if (scriptErrorPrevVal === undefined && scriptReadyPrevVal === undefined) return;

        if (firstScriptSectionRef.current && interviewScript?.ready) {
            domService.scrollIntoViewIfNeeded(firstScriptSectionRef.current);
        } else if (scriptMessageWrapperRef.current && (interviewScriptError || interviewScriptGenerating)) {
            domService.scrollIntoViewIfNeeded(scriptMessageWrapperRef.current);
        }
    }, [interviewScript?.ready, interviewScriptError, interviewScriptGenerating, scriptErrorPrevVal, scriptReadyPrevVal]);

    const updateInterviewScriptInTaskData = useCallback(
        (update: (interviewScriptToUpdate: InterviewScript) => InterviewScript | undefined) => {
            setTaskData(taskData => {
                if (!taskData.researchInterviewScript) return taskData;

                const intervewScriptForResearch = taskData.researchInterviewScript[researchId];
                if (!intervewScriptForResearch) return taskData;

                const updatedInterviewScript = update(intervewScriptForResearch);
                if (!updatedInterviewScript || updatedInterviewScript === intervewScriptForResearch) return taskData;

                return {
                    ...taskData,
                    researchInterviewScript: {
                        ...taskData.researchInterviewScript,
                        [researchId]: updatedInterviewScript
                    }
                };
            });
        },
        [researchId, setTaskData]
    );

    const updateInterviewScriptSectionInTaskData = useCallback(
        (sectionId: number, update: (sectionToUpdate: InterviewScriptSection) => InterviewScriptSection | undefined) => {
            updateInterviewScriptInTaskData(script => {
                const sectionToUpdate = script.sections.find(s => s.id === sectionId);
                if (!sectionToUpdate) return script;
                const updatedSection = update(sectionToUpdate);
                if (!updatedSection || sectionToUpdate === updatedSection) return script;

                return {
                    ...script,
                    sections: script.sections.map(s => (s.id === updatedSection.id ? updatedSection : s))
                };
            });
        },
        [updateInterviewScriptInTaskData]
    );

    const updateInterviewScriptQuestionInTaskData = useCallback(
        (sectionId: number, entryId: number, update: (questionToUpdate: InterviewScriptEntry) => InterviewScriptEntry | undefined) => {
            updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => {
                const questionToUpdate = sectionToUpdate.entries.find(e => e.id === entryId);
                if (!questionToUpdate) return;
                const updatedQuestion = update(questionToUpdate);
                if (!updatedQuestion || questionToUpdate === updatedQuestion) return;

                return {
                    ...sectionToUpdate,
                    entries: sectionToUpdate.entries.map(e => (e.id === updatedQuestion.id ? updatedQuestion : e))
                };
            });
        },
        [updateInterviewScriptSectionInTaskData]
    );

    const generateInterviewScript = useCallback(async () => {
        if (interviewScriptGenerating) return;

        const newScript = await researchService.createInterviewScript(ideaId, researchId);

        setTaskData(taskData => {
            if (!taskData.researchInterviewScript) return taskData;

            const researchToUpdate = taskData.problemValidationResearch?.[researchId];
            if (!researchToUpdate) return taskData;

            return {
                ...taskData,
                problemValidationResearch: {
                    ...taskData.problemValidationResearch,
                    [researchId]: {
                        ...researchToUpdate,
                        interviewScriptId: newScript.id
                    }
                }
            };
        });

        updateInterviewScriptInTaskData(() => newScript);
    }, [ideaId, interviewScriptGenerating, researchId, setTaskData, updateInterviewScriptInTaskData]);

    useEffect(() => {
        if (isHidden || !currentJtbd || interviewScript === undefined) return;

        if (interviewScript === null) {
            generateInterviewScript();
        }
    }, [currentJtbd, generateInterviewScript, interviewScript, isHidden]);

    useEffect(() => {
        if (!ideaId || !interviewScript) return;

        const refreshInterviewScript = async (eventIdeaId: string, interviewScriptId: number) => {
            if (eventIdeaId !== ideaId || interviewScriptId !== interviewScript.id) return;

            const updatedScript = await interviewScriptsService.getInterviewScript(ideaId, interviewScriptId);

            updateInterviewScriptInTaskData(() => updatedScript);
        };

        const refreshScriptSection = async (eventIdeaId: string, interviewScriptId: number, sectionId: number) => {
            if (eventIdeaId !== ideaId || interviewScriptId !== interviewScript.id) return;

            const updatedSection = await interviewScriptsService.getSection(ideaId, interviewScriptId, sectionId);

            updateInterviewScriptInTaskData(interviewScriptToUpdate => ({
                ...interviewScriptToUpdate,
                sections: immutableUpdate(interviewScriptToUpdate.sections, updatedSection, s => s.id === sectionId)
            }));
        };

        const deleteScriptSection = async (eventIdeaId: string, interviewScriptId: number, sectionId: number) => {
            if (eventIdeaId !== ideaId || interviewScriptId !== interviewScript.id) return;

            updateInterviewScriptInTaskData(interviewScriptToUpdate => {
                return {
                    ...interviewScriptToUpdate,
                    sections: immutableRemove(interviewScriptToUpdate.sections, s => s.id !== sectionId)
                };
            });
        };

        const refreshInterviewScriptQuestion = async (eventIdeaId: string, interviewScriptId: number, sectionId: number, entryId: number) => {
            if (eventIdeaId !== ideaId || interviewScriptId !== interviewScript.id) return;

            const updatedQuestion = await interviewScriptsService.getEntry(ideaId, interviewScriptId, sectionId, entryId);

            updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => ({
                ...sectionToUpdate,
                entries: immutableUpdate(sectionToUpdate.entries, updatedQuestion, q => q.id === entryId)
            }));
        };

        const deleteScriptQuestion = async (eventIdeaId: string, interviewScriptId: number, sectionId: number, entryId: number) => {
            if (eventIdeaId !== ideaId || interviewScriptId !== interviewScript.id) return;

            updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => ({
                ...sectionToUpdate,
                entries: immutableRemove(sectionToUpdate.entries, q => q.id !== entryId)
            }));
        };

        const refreshInterviewScriptQuestionTip = async (eventIdeaId: string, interviewScriptId: number, sectionId: number, entryId: number, tipId: number) => {
            if (eventIdeaId !== ideaId || interviewScriptId !== interviewScript.id) return;

            const updatedTip = await interviewScriptsService.getTip(ideaId, interviewScriptId, sectionId, entryId, tipId);

            updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => ({
                ...questionToUpdate,
                tips: immutableUpdate(questionToUpdate.tips, updatedTip, t => t.id === tipId)
            }));
        };

        const deleteScriptQuestionTip = async (eventIdeaId: string, interviewScriptId: number, sectionId: number, entryId: number, tipId: number) => {
            if (eventIdeaId !== ideaId || interviewScriptId !== interviewScript.id) return;

            updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => ({
                ...questionToUpdate,
                tips: immutableRemove(questionToUpdate.tips, t => t.id !== tipId)
            }));
        };

        const onScriptUpdate = (data: RealTimeUpdateInterviewScriptData) => refreshInterviewScript(data.ideaId, data.interviewScriptId);
        const onScriptSectionAdd = (data: RealTimeUpdateInterviewScriptSectionEventData) => refreshInterviewScript(data.ideaId, data.interviewScriptId);
        const onScriptSectionUpdate = (data: RealTimeUpdateInterviewScriptSectionEventData) =>
            refreshScriptSection(data.ideaId, data.interviewScriptId, data.interviewScriptSectionId);
        const onScriptSectionDelete = (data: RealTimeUpdateInterviewScriptSectionEventData) =>
            deleteScriptSection(data.ideaId, data.interviewScriptId, data.interviewScriptSectionId);

        const onScriptEntryAdd = (data: RealTimeUpdateInterviewScriptEntryEventData) =>
            refreshScriptSection(data.ideaId, data.interviewScriptId, data.interviewScriptSectionId);
        const onScriptEntryUpdate = (data: RealTimeUpdateInterviewScriptEntryEventData) =>
            refreshInterviewScriptQuestion(data.ideaId, data.interviewScriptId, data.interviewScriptSectionId, data.interviewScriptEntryId);
        const onScriptEntryDelete = (data: RealTimeUpdateInterviewScriptEntryEventData) =>
            deleteScriptQuestion(data.ideaId, data.interviewScriptId, data.interviewScriptSectionId, data.interviewScriptEntryId);

        const onScriptTipAdd = (data: RealTimeUpdateInterviewScriptTipEventData) =>
            refreshInterviewScriptQuestion(data.ideaId, data.interviewScriptId, data.interviewScriptSectionId, data.interviewScriptEntryId);
        const onScriptTipUpdate = (data: RealTimeUpdateInterviewScriptTipEventData) =>
            refreshInterviewScriptQuestionTip(
                data.ideaId,
                data.interviewScriptId,
                data.interviewScriptSectionId,
                data.interviewScriptEntryId,
                data.interviewScriptTipId
            );
        const onScriptTipDelete = (data: RealTimeUpdateInterviewScriptTipEventData) =>
            deleteScriptQuestionTip(data.ideaId, data.interviewScriptId, data.interviewScriptSectionId, data.interviewScriptEntryId, data.interviewScriptTipId);

        realTimeUpdatesEventHub.addEventListener('interview', 'scriptReorder', onScriptUpdate);

        realTimeUpdatesEventHub.addEventListener('interview', 'scriptSectionAdd', onScriptSectionAdd);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptSectionUpdate', onScriptSectionUpdate);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptSectionRestore', onScriptSectionAdd);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptSectionDelete', onScriptSectionDelete);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptSectionReorder', onScriptSectionUpdate);

        realTimeUpdatesEventHub.addEventListener('interview', 'scriptEntryAdd', onScriptEntryAdd);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptEntryDelete', onScriptEntryDelete);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptEntryRestore', onScriptEntryAdd);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptEntryUpdate', onScriptEntryUpdate);

        realTimeUpdatesEventHub.addEventListener('interview', 'scriptTipAdd', onScriptTipAdd);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptTipDelete', onScriptTipDelete);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptTipRestore', onScriptTipAdd);
        realTimeUpdatesEventHub.addEventListener('interview', 'scriptTipUpdate', onScriptTipUpdate);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptReorder', onScriptUpdate);

            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptSectionAdd', onScriptSectionAdd);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptSectionUpdate', onScriptSectionUpdate);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptSectionRestore', onScriptSectionAdd);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptSectionDelete', onScriptSectionDelete);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptSectionReorder', onScriptSectionUpdate);

            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptEntryAdd', onScriptEntryAdd);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptEntryDelete', onScriptEntryDelete);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptEntryRestore', onScriptEntryAdd);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptEntryUpdate', onScriptEntryUpdate);

            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptTipAdd', onScriptTipAdd);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptTipDelete', onScriptTipDelete);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptTipRestore', onScriptTipAdd);
            realTimeUpdatesEventHub.removeEventListener('interview', 'scriptTipUpdate', onScriptTipUpdate);
        };
    }, [ideaId, interviewScript, updateInterviewScriptInTaskData, updateInterviewScriptQuestionInTaskData, updateInterviewScriptSectionInTaskData]);

    const addSection = createTaskCommitOperation(async (sectionId: number, isBefore: boolean) => {
        if (!interviewScript) return;
        let customSectionNumber = 1;
        for (let idx = 1; idx < 1000; idx++) {
            if (!interviewScript.sections.some(s => s.title === `Custom section ${idx}`)) {
                customSectionNumber = idx;
                break;
            }
        }

        const defaultTitle = `Custom section ${customSectionNumber}`;
        const defaultDurationInMins = 5;
        const sectionIdx = interviewScript.sections.findIndex(s => s.id === sectionId);

        if (sectionIdx < 0) return;

        let afterId: number | undefined;
        if (isBefore) {
            afterId = sectionIdx <= 0 ? undefined : interviewScript.sections[sectionIdx - 1].id;
        } else {
            afterId = interviewScript.sections[sectionIdx].id;
        }

        const optimisticSection: InterviewScriptSection = {
            id: -1,
            title: defaultTitle,
            durationInMinutes: defaultDurationInMins,
            entries: [],
            hidden: false
        };
        const newSections = [...interviewScript.sections];

        if (isBefore) {
            newSections.splice(sectionIdx, 0, optimisticSection);
        } else {
            newSections.splice(sectionIdx + 1, 0, optimisticSection);
        }

        updateInterviewScriptInTaskData(interviewScriptToUpdate => {
            return {
                ...interviewScriptToUpdate,
                sections: newSections
            };
        });

        try {
            const newSection = await interviewScriptsService.addSection(ideaId, interviewScript.id, defaultTitle, defaultDurationInMins, afterId);

            updateInterviewScriptInTaskData(interviewScriptToUpdate => {
                return {
                    ...interviewScriptToUpdate,
                    sections: immutableUpdate(
                        interviewScriptToUpdate.sections,
                        s => ({ ...s, id: newSection.id, title: newSection.title, durationInMinutes: newSection.durationInMinutes, hidden: newSection.hidden }),
                        s => s.id === -1
                    )
                };
            });
        } catch (e) {
            updateInterviewScriptInTaskData(interviewScriptToUpdate => {
                return {
                    ...interviewScriptToUpdate,
                    sections: immutableRemove(interviewScriptToUpdate.sections, s => s.id !== -1)
                };
            });

            throw e;
        }
    });

    const moveSection = createTaskCommitOperation(async (sectionId: number, isUp: boolean) => {
        if (!interviewScript) return;

        const sectionIdx = interviewScript.sections.findIndex(s => s.id === sectionId);
        if ((isUp && sectionIdx === 0) || (!isUp && sectionIdx === interviewScript.sections.length - 1)) return;

        if (sectionIdx < 0) return;

        let afterId: number | undefined;
        if (isUp) {
            afterId = sectionIdx <= 1 ? undefined : interviewScript.sections[sectionIdx - 2].id;
        } else {
            afterId = interviewScript.sections[sectionIdx + 1].id;
        }

        const originalOrderIds = interviewScript.sections.map(s => s.id);
        const newSections = [...interviewScript.sections];
        const movedSection = newSections[sectionIdx];
        newSections.splice(sectionIdx, 1);
        newSections.splice(isUp ? sectionIdx - 1 : sectionIdx + 1, 0, movedSection);

        //optimistically set in task data
        updateInterviewScriptInTaskData(interviewScriptToUpdate => {
            return {
                ...interviewScriptToUpdate,
                sections: newSections
            };
        });

        await optimisticSectionMoveOperationsQueue.current
            .execute(() => interviewScriptsService.reorderSection(ideaId, interviewScript.id, sectionId, afterId))
            .catch(e => {
                updateInterviewScriptInTaskData(interviewScriptToUpdate => {
                    const reorderedSections = originalOrderIds
                        .map(id => interviewScriptToUpdate.sections.find(s => s.id === id))
                        .filter(section => section !== undefined);
                    return {
                        ...interviewScriptToUpdate,
                        sections: reorderedSections as InterviewScriptSection[]
                    };
                });

                throw e;
            });
    });

    const moveSectionUp = (sectionId: number) => moveSection(sectionId, true);
    const moveSectionDown = (sectionId: number) => moveSection(sectionId, false);
    const addSectionBefore = (sectionId: number) => addSection(sectionId, true);
    const addSectionAfter = (sectionId: number) => addSection(sectionId, false);

    const hideUnhideSection = createTaskCommitOperation(async (sectionId: number, isHide: boolean) => {
        if (!interviewScript) return;

        isHide
            ? await interviewScriptsService.hideSection(ideaId, interviewScript.id, sectionId)
            : await interviewScriptsService.unhideSection(ideaId, interviewScript.id, sectionId);

        updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => ({ ...sectionToUpdate, hidden: isHide }));
    });

    const hideSection = (sectionId: number) => hideUnhideSection(sectionId, true);
    const unhideSection = (sectionId: number) => hideUnhideSection(sectionId, false);

    const deleteSection = createTaskCommitOperation(async (sectionId: number) => {
        if (!interviewScript) return;

        await interviewScriptsService.deleteSection(ideaId, interviewScript.id, sectionId);
        updateInterviewScriptInTaskData(interviewScriptToUpdate => {
            return {
                ...interviewScriptToUpdate,
                sections: immutableRemove(interviewScriptToUpdate.sections, s => s.id !== sectionId)
            };
        });

        dispatch(
            addNotification({ content: 'Section deleted.', actionText: 'Undo' }, async () => {
                await interviewScriptsService.restoreSection(ideaId, interviewScript.id, sectionId);
                const updatedInterviewScript = await interviewScriptsService.getInterviewScript(ideaId, interviewScript.id);
                updateInterviewScriptInTaskData(interviewScriptToUpdate => {
                    return {
                        ...interviewScriptToUpdate,
                        sections: updatedInterviewScript.sections
                    };
                });
            })
        );
    });

    const updateSection = createTaskCommitOperation(async (sectionId: number, title: string, durationInMinutes: number) => {
        if (!interviewScript) return;

        const updatedSection = await interviewScriptsService.updateSection(ideaId, interviewScript.id, sectionId, title, durationInMinutes);
        updateInterviewScriptSectionInTaskData(sectionId, updateSection => ({
            ...updateSection,
            title: updatedSection.title,
            durationInMinutes: updatedSection.durationInMinutes
        }));
    });

    const resetSection = createTaskCommitOperation(async (sectionId: number) => {
        if (!interviewScript) return;

        const updatedResetSection = await interviewScriptsService.resetSection(ideaId, interviewScript.id, sectionId);
        updateInterviewScriptSectionInTaskData(sectionId, () => updatedResetSection);

        dispatch(
            addNotification({ content: 'Section reset.', actionText: 'Undo' }, async () => {
                const undoResetSection = await interviewScriptsService.undoResetSection(ideaId, interviewScript.id, sectionId);
                updateInterviewScriptSectionInTaskData(sectionId, () => undoResetSection);
            })
        );
    });

    const createQuestion = createTaskCommitOperation(async (sectionId: number, content: string) => {
        if (!interviewScript) return;

        const insertedQuestion = await interviewScriptsService.addEntry(ideaId, interviewScript.id, sectionId, content);

        updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => {
            return {
                ...sectionToUpdate,
                entries: immutableAdd(sectionToUpdate.entries, insertedQuestion)
            };
        });
    });

    const updateQuestion = createTaskCommitOperation(async (sectionId: number, entryId: number, content: string) => {
        if (!interviewScript) return;

        const updatedEntry = await interviewScriptsService.updateEntry(ideaId, interviewScript.id, sectionId, entryId, content);
        updateInterviewScriptQuestionInTaskData(sectionId, entryId, () => updatedEntry);
    });

    const hideUnhideQuestion = createTaskCommitOperation(async (sectionId: number, entryId: number, isHide: boolean) => {
        if (!interviewScript) return;

        updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => {
            return { ...questionToUpdate, hidden: isHide };
        });

        const operation = isHide
            ? interviewScriptsService.hideEntry.bind(interviewScriptsService)
            : interviewScriptsService.unhideEntry.bind(interviewScriptsService);

        await enqueueEntryOperation(entryId, () => operation(ideaId, interviewScript.id, sectionId, entryId)).catch(e => {
            updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => {
                return { ...questionToUpdate, hidden: !questionToUpdate.hidden };
            });

            throw e;
        });
    });

    const hideQuestion = (sectionId: number, entryId: number) => hideUnhideQuestion(sectionId, entryId, true);
    const unhideQuestion = (sectionId: number, entryId: number) => hideUnhideQuestion(sectionId, entryId, false);

    const resetQuestion = createTaskCommitOperation(async (sectionId: number, entryId: number) => {
        if (!interviewScript) return;

        const resetEntry = await interviewScriptsService.resetEntry(ideaId, interviewScript.id, sectionId, entryId);
        updateInterviewScriptQuestionInTaskData(sectionId, entryId, () => resetEntry);

        dispatch(
            addNotification({ content: 'Question reset.', actionText: 'Undo' }, async () => {
                const undoResetEntry = await interviewScriptsService.undoResetEntry(ideaId, interviewScript.id, sectionId, entryId);
                updateInterviewScriptQuestionInTaskData(sectionId, entryId, () => undoResetEntry);
            })
        );
    });

    const deleteQuestion = createTaskCommitOperation(async (sectionId: number, entryId: number) => {
        if (!interviewScript) return;

        await interviewScriptsService.deleteEntry(ideaId, interviewScript.id, sectionId, entryId);
        updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => ({
            ...sectionToUpdate,
            entries: immutableRemove(sectionToUpdate.entries, e => e.id !== entryId)
        }));

        dispatch(
            addNotification({ content: 'Question deleted.', actionText: 'Undo' }, async () => {
                await interviewScriptsService.restoreEntry(ideaId, interviewScript.id, sectionId, entryId);
                const section = await interviewScriptsService.getSection(ideaId, interviewScript.id, sectionId);

                updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => ({
                    ...sectionToUpdate,
                    entries: section.entries
                }));
            })
        );
    });

    const reorderQuestion = createTaskCommitOperation(async (sectionId: number, entryId: number, newIdx: number) => {
        if (!interviewScript) return;

        const section = interviewScript.sections.find(s => s.id === sectionId)!;
        const oldIdx = section.entries.findIndex(e => e.id === entryId);

        if (oldIdx === -1 || oldIdx === newIdx) return;

        const reorderedEntries = [...section.entries];
        const oldOrderIds = reorderedEntries.map(e => e.id);
        const [movedQuestion] = reorderedEntries.splice(oldIdx, 1);
        reorderedEntries.splice(newIdx, 0, movedQuestion);

        //optimistically update task data with new questions
        updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => {
            return {
                ...sectionToUpdate,
                entries: reorderedEntries
            };
        });

        const afterEntryId = newIdx === 0 ? undefined : reorderedEntries[newIdx - 1].id;

        await enqueueSectionOperation(sectionId, () =>
            interviewScriptsService.reorderEntry(ideaId, interviewScript.id, sectionId, entryId, afterEntryId)
        ).catch(e => {
            updateInterviewScriptSectionInTaskData(sectionId, sectionToUpdate => {
                const oldOrderEntries = oldOrderIds
                    .map(id => sectionToUpdate.entries.find(e => e.id === id))
                    .filter(e => e !== undefined) as InterviewScriptEntry[];
                return {
                    ...sectionToUpdate,
                    entries: oldOrderEntries
                };
            });

            throw e;
        });
    });

    const createTip = createTaskCommitOperation(async (entryId: number, sectionId: number, content: string) => {
        if (!interviewScript) return;

        const createdTip = await interviewScriptsService.addTip(ideaId, interviewScript.id, sectionId, entryId, content);
        updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => {
            return {
                ...questionToUpdate,
                tips: immutableAdd(questionToUpdate.tips, createdTip)
            };
        });
    });

    const updateTip = createTaskCommitOperation(async (entryId: number, sectionId: number, tipId: number, content: string) => {
        if (!interviewScript) return;

        const updatedTip = await interviewScriptsService.updateTip(ideaId, interviewScript.id, sectionId, entryId, tipId, content);

        updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => {
            return {
                ...questionToUpdate,
                tips: immutableUpdate(questionToUpdate.tips, updatedTip, t => t.id === tipId)
            };
        });
    });

    const deleteTip = createTaskCommitOperation(async (sectionId: number, entryId: number, tipId: number) => {
        if (!interviewScript) return;

        const tipToDelete = interviewScript.sections
            .find(s => s.id === sectionId)
            ?.entries.find(e => e.id === entryId)
            ?.tips.find(t => t.id === tipId);

        if (!tipToDelete) return;

        await interviewScriptsService.deleteTip(ideaId, interviewScript.id, sectionId, entryId, tipId);

        updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => {
            return {
                ...questionToUpdate,
                tips: immutableRemove(questionToUpdate.tips, t => t.id !== tipId)
            };
        });

        dispatch(
            addNotification({ content: 'Tip deleted.', actionText: 'Undo' }, async () => {
                const restoredTip = await interviewScriptsService.restoreTip(ideaId, interviewScript.id, sectionId, entryId, tipToDelete.id);

                updateInterviewScriptQuestionInTaskData(sectionId, entryId, questionToUpdate => {
                    return {
                        ...questionToUpdate,
                        tips: immutableAdd(questionToUpdate.tips, restoredTip).sort((a, b) => a.id - b.id)
                    };
                });
            })
        );
    });

    return (
        <EditorMainArea>
            {!interviewScript || interviewScriptError || interviewScriptGenerating ? (
                <div ref={scriptMessageWrapperRef}>
                    {interviewScriptGenerating ? (
                        <IVALoadingIndicator>
                            IVA generates your interview script based on the
                            <br />
                            given Job-to-be-Done. It can take up to 2 minutes.
                        </IVALoadingIndicator>
                    ) : (
                        <StackLayout align={{ horizontal: 'center', vertical: 'top' }} orientation="vertical" className="k-text-center k-gap-4">
                            <p>
                                We were unable to generate your interview script.
                                <br />
                                If this keeps happening consider changing the given Job-to-be-Done.
                            </p>
                            <Button
                                themeColor="secondary"
                                fillMode="outline"
                                disabled={!isEditing || generateScriptOnErrorDisabled}
                                onClick={generateScriptOnErrorButton(() => generateInterviewScript())}
                            >
                                Generate new script
                            </Button>
                        </StackLayout>
                    )}
                </div>
            ) : (
                <StackLayout orientation="vertical" className="k-gap-6">
                    <StackLayout orientation="vertical" className="k-gap-6">
                        {interviewScript.sections.map(interviewScriptSection => {
                            return (
                                <InterviewScriptSectionEditor
                                    ref={firstScriptSectionRef}
                                    section={interviewScriptSection}
                                    key={interviewScriptSection.id}
                                    tipsOperations={{ createTip, updateTip, deleteTip }}
                                    questionOperations={{
                                        reorderQuestion: reorderQuestion,
                                        createQuestion: createQuestion,
                                        updateQuestion: updateQuestion,
                                        deleteQuestion: deleteQuestion,
                                        hideQuestion: hideQuestion,
                                        unhideQuestion: unhideQuestion,
                                        resetQuestion: resetQuestion
                                    }}
                                    sectionOperations={{
                                        updateSection: updateSection,
                                        resetSection: resetSection,
                                        deleteSection: pendingListUpdateActionWrapper(deleteSection),
                                        hideSection: hideSection,
                                        unhideSection: unhideSection,
                                        insertSectionAfter: pendingListUpdateActionWrapper(addSectionAfter),
                                        insertSectionBefore: pendingListUpdateActionWrapper(addSectionBefore),
                                        moveSectionDown: moveSectionDown,
                                        moveSectionUp: moveSectionUp
                                    }}
                                    taskHidden={isHidden}
                                    isEditing={isEditing}
                                    questionHints={hints}
                                    listModifyActionsDisabled={disabledWhileUpdatingList}
                                    isFirst={interviewScript.sections[0].id === interviewScriptSection.id}
                                    isLast={interviewScript.sections[interviewScript!.sections.length - 1].id === interviewScriptSection.id}
                                />
                            );
                        })}
                    </StackLayout>
                    <StackLayout
                        align={{ vertical: 'top', horizontal: 'center' }}
                        orientation="vertical"
                        className="k-gap-2 k-text-center k-pt-4 k-border-t k-border-t-solid k-icp-component-border"
                    >
                        <div
                            className={combineClassNames(
                                'k-icp-icon-background',
                                scriptTime > recommendedMaxTime ? 'k-icp-icon-background-warning' : 'k-icp-icon-background-success'
                            )}
                        >
                            <ClockIcon className="k-icp-icon k-icp-icon-size-6" />
                        </div>
                        Approx. total interview time: {scriptTime === 1 ? '1 minute' : `${scriptTime} minutes`}.<br />
                        {scriptTime > recommendedMaxTime ? 'Interviews longer than 30-45 minutes are not recommended.' : undefined}
                    </StackLayout>
                </StackLayout>
            )}
        </EditorMainArea>
    );
});

type InterviewSectionProps = {
    section: InterviewScriptSection;
    tipsOperations: TipsOperations;
    questionOperations: InterviewQuestionOperations;
    sectionOperations: InterviewSectionOperations;
    listModifyActionsDisabled?: boolean;
    questionHints?: JourneyTaskGuidanceHints;
    isEditing?: boolean;
    taskHidden?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
};

const InterviewScriptSectionEditor = forwardRef<HTMLDivElement, InterviewSectionProps>(
    (
        { section, tipsOperations, questionOperations, sectionOperations, listModifyActionsDisabled, questionHints, isEditing, taskHidden, isFirst, isLast },
        fwdRef
    ) => {
        const [disabledWhileModifyActionPending, disableWhileModifyActionPending] = useSingleClickButton<[], Promise<unknown> | undefined>();

        const deleteAction = disableWhileModifyActionPending(() => sectionOperations.deleteSection(section.id));
        const hideSectionAction = disableWhileModifyActionPending(() => sectionOperations.hideSection(section.id));
        const unhideSectionAction = disableWhileModifyActionPending(() => sectionOperations.unhideSection(section.id));
        const resetSectionAction = disableWhileModifyActionPending(() => sectionOperations.resetSection(section.id));

        const sectionActions: DropDownButtonItem[] = [
            { text: 'Hide section', danger: false, separated: false, action: hideSectionAction },
            { text: 'Reset section', danger: false, separated: false, action: resetSectionAction },
            {
                text: 'Move up',
                danger: false,
                disabled: listModifyActionsDisabled || isFirst,
                separated: true,
                action: () => sectionOperations.moveSectionUp(section.id)
            },
            {
                text: 'Move down',
                danger: false,
                disabled: listModifyActionsDisabled || isLast,
                separated: false,
                action: () => sectionOperations.moveSectionDown(section.id)
            },
            {
                text: 'Insert section before',
                disabled: listModifyActionsDisabled,
                danger: false,
                separated: true,
                action: () => sectionOperations.insertSectionBefore(section.id)
            },
            {
                text: 'Insert section after',
                disabled: listModifyActionsDisabled,
                danger: false,
                separated: false,
                action: () => sectionOperations.insertSectionAfter(section.id)
            },
            { text: 'Delete section', danger: true, separated: true, action: deleteAction }
        ];

        const [isCollapsed, setIsCollapsed] = useState(false);
        const [isAddingQuestion, setIsAddingQuestion] = useState(false);
        const [showHiddenQuestions, setShowHiddenQuestions] = useState(false);
        const hiddenQuestions = section.entries.filter(e => e.hidden);
        const sectionDisabled = section.id === -1 || disabledWhileModifyActionPending;

        const setForwardedRefToFirstSection = (stackHandleRef: StackLayoutHandle | null) => {
            if (!stackHandleRef || !fwdRef || !isFirst) return;

            if (typeof fwdRef === 'function') {
                fwdRef(stackHandleRef.element);
            } else {
                fwdRef.current = stackHandleRef.element;
            }
        };

        return (
            <StackLayout ref={setForwardedRefToFirstSection} orientation="vertical" className="k-icp-panel !k-rounded k-px-4 k-py-2">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-flex-1 k-min-w-0">
                        <SectionGenericEditableComponent
                            initialValue={section.title}
                            onUpdate={updatedTitle => sectionOperations.updateSection(section.id, updatedTitle, section.durationInMinutes)}
                            disabled={sectionDisabled || section.hidden}
                            viewInputClassName="k-text-ellipsis"
                            viewWrapperClassName="!k-font-medium k-input-lg !k-w-auto k-pl-2"
                            editClassName="k-flex-1"
                            editor={InlineEditorInput}
                            allowEditing={!!isEditing}
                            editorSettings={{
                                className: 'k-input-lg'
                            }}
                            hasTooltip
                        />
                        <StackLayout
                            align={{ horizontal: 'start', vertical: 'middle' }}
                            className={combineClassNames('k-gap-1 k-flex-shrink-0', sectionDisabled || section.hidden ? 'k-disabled' : undefined)}
                        >
                            <ClockIcon className="k-icp-icon k-icp-icon-size-4 k-mr-thin" />
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-0.5">
                                <span className="k-shrink-0 k-fs-sm">Approx. interview time:</span>

                                <SectionGenericEditableComponent
                                    initialValue={section.durationInMinutes}
                                    onUpdate={updatedDuration => sectionOperations.updateSection(section.id, section.title, updatedDuration ?? 0)}
                                    disabled={sectionDisabled || section.hidden}
                                    viewContentSuffix=" min"
                                    viewInputClassName="k-text-nowrap"
                                    viewWrapperClassName="k-input-sm"
                                    editClassName="-maxw-16"
                                    editor={InlineEditorNumericTextBox}
                                    allowEditing={!!isEditing}
                                    editorSettings={{
                                        defaultValue: 5,
                                        max: 99,
                                        spinners: false,
                                        format: "# 'min'",
                                        min: 0,
                                        step: 1,
                                        size: 'small',
                                        fillMode: 'solid',
                                        rounded: 'medium'
                                    }}
                                />
                            </StackLayout>
                        </StackLayout>
                    </StackLayout>

                    {isEditing && section.hidden ? (
                        <Button disabled={sectionDisabled} size="small" fillMode="flat" onClick={unhideSectionAction}>
                            Unhide
                        </Button>
                    ) : (
                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4">
                            {isEditing && (
                                <BoundDropDownButton disabled={sectionDisabled} fillMode="flat" size="small" icon="more-horizontal" items={sectionActions} />
                            )}
                            {isEditing && <div className="k-separator k-icp-component-border" />}
                            <Button
                                size="small"
                                disabled={sectionDisabled}
                                fillMode="flat"
                                className="k-icp-svg-icon-button"
                                onClick={e => {
                                    setIsCollapsed(prev => !prev);
                                }}
                            >
                                {isCollapsed ? <CollapseIcon className="k-icp-icon" /> : <ExpandIcon className="k-icp-icon" />}
                            </Button>
                        </StackLayout>
                    )}
                </StackLayout>
                <Reveal>
                    {!section.hidden && !isCollapsed && (
                        <StackLayout orientation="vertical">
                            {hiddenQuestions && hiddenQuestions.length > 0 && (
                                <StackLayout
                                    align={{ vertical: 'middle', horizontal: 'start' }}
                                    className="k-justify-content-between k-icp-panel-base k-px-2 k-py-1 k-mt-2 k-gap-2.5 k-rounded"
                                >
                                    <span className="k-fs-sm">
                                        {hiddenQuestions.length} hidden question{hiddenQuestions.length === 1 ? '' : 's'}
                                    </span>
                                    <StackLayout className="k-gap-1" align={{ horizontal: 'start', vertical: 'middle' }}>
                                        <span className="k-fs-sm k-font-weight-semibold">Show hidden questions</span>
                                        <Switch
                                            className="k-icp-on-off-switch"
                                            defaultChecked={false}
                                            checked={showHiddenQuestions}
                                            onChange={e => setShowHiddenQuestions(e.value)}
                                            offLabel=""
                                            onLabel=""
                                            size="small"
                                        />
                                    </StackLayout>
                                </StackLayout>
                            )}
                            {/* Rendering question editors while task is hidden doesn't calculate their height correctly. */}
                            {!taskHidden && (
                                <StackLayout orientation="vertical" className="k-pt-2">
                                    {section.entries.some(entry => !entry.hidden) || (hiddenQuestions.length > 0 && showHiddenQuestions) ? (
                                        <InterviewScriptQuestionsSortableList
                                            disableDrag={!isEditing}
                                            items={
                                                section.entries
                                                    .map(entry => {
                                                        if (entry.hidden && !showHiddenQuestions) return null;
                                                        return {
                                                            isAddingEntry: false,
                                                            sectionId: section.id,
                                                            id: entry.id,
                                                            entry,
                                                            disabled: sectionDisabled,
                                                            hidden: entry.hidden,
                                                            questionOperations: questionOperations,
                                                            tipsOperations,
                                                            allowEditing: isEditing
                                                        } as InterviewQuestionListItem;
                                                    })
                                                    .filter(e => e !== null) as InterviewQuestionListItem[]
                                            }
                                            onItemReordered={(id, newIndex) => {
                                                return questionOperations.reorderQuestion(section.id, id, newIndex);
                                            }}
                                        />
                                    ) : null}
                                    {!isEditing ? null : !isAddingQuestion ? (
                                        <StackLayout align={{ horizontal: 'start' }} className="k-pt-1 k-pb-3">
                                            <Button
                                                disabled={sectionDisabled}
                                                size="small"
                                                fillMode="flat"
                                                themeColor="base"
                                                onClick={() => setIsAddingQuestion(isAdding => !isAdding)}
                                            >
                                                <SvgIconButtonContent icon={PlusIcon}>Add custom question</SvgIconButtonContent>
                                            </Button>
                                        </StackLayout>
                                    ) : (
                                        <InterviewQuestionEditor
                                            questionOperations={questionOperations}
                                            elementAttributes={{ className: 'k-pb-2' }}
                                            isAddingEntry={true}
                                            sectionId={section.id}
                                            questionHints={questionHints}
                                            onCancelAdding={() => setIsAddingQuestion(false)}
                                            allowEditing={isEditing}
                                        />
                                    )}
                                </StackLayout>
                            )}
                        </StackLayout>
                    )}
                </Reveal>
            </StackLayout>
        );
    }
);

interface SectionGenericEditableComponentProps<T, TEditorSettings> {
    initialValue: T;
    viewWrapperClassName?: string;
    viewInputClassName?: string;
    viewContentSuffix?: string;
    onUpdate?: (newValue: T) => Promise<unknown>;
    editor: ComponentType<InlineEditorComponentProps<T, TEditorSettings> & RefAttributes<InlineEditorComponentHandle>>;
    editorSettings?: TEditorSettings;
    editClassName?: string;
    disabled?: boolean;
    allowEditing: boolean;
    hasTooltip?: boolean;
}

function ViewerComponent<T>(props: InlineEditorViewerProps<T, { addTitle?: boolean; viewContentSuffix?: string; className?: string }>) {
    const suffix = props.settings?.viewContentSuffix;
    return (
        <div title={props.settings?.addTitle ? String(props.value) : undefined} className={props.settings?.className}>
            {String(props.value) + (suffix ? suffix : '')}
        </div>
    );
}
const SectionGenericEditableComponent = <T, TEditorSettings>({
    initialValue,
    viewWrapperClassName,
    viewInputClassName,
    viewContentSuffix,
    onUpdate,
    disabled,
    allowEditing,
    editor,
    editClassName,
    editorSettings,
    hasTooltip
}: SectionGenericEditableComponentProps<T, TEditorSettings>) => {
    const [isEditing, setIsEditing] = useState(false);
    const [updatedValue, setUpdatedValue] = useState<T | undefined>(undefined);

    const value = updatedValue !== undefined ? updatedValue : initialValue;

    const handleSave = async () => {
        try {
            if (updatedValue !== undefined && updatedValue !== null) {
                if (typeof updatedValue === 'string' && updatedValue !== initialValue && updatedValue.trim().length !== 0) {
                    await onUpdate?.(updatedValue);
                } else if (typeof updatedValue !== 'string' && updatedValue !== initialValue) {
                    await onUpdate?.(updatedValue);
                }
            }
        } finally {
            onCancel();
        }
    };

    const onCancel = () => {
        setUpdatedValue(undefined);
        setIsEditing(false);
    };

    return (
        <InlineEditor
            editor={editor}
            editorSettings={editorSettings}
            hideEditControls
            isEditing={allowEditing && isEditing}
            onClick={() => setIsEditing(true)}
            autoFocus
            onEditorChange={e => setUpdatedValue(e.value)}
            editClassName={editClassName}
            onSave={handleSave}
            onCancel={onCancel}
            viewerSettings={{ viewContentSuffix, className: viewInputClassName, addTitle: hasTooltip }}
            viewClassName={combineClassNames(allowEditing ? 'border-on-hover' : '!k-border-none', disabled ? 'k-disabled' : undefined, viewWrapperClassName)}
            value={value}
            viewer={ViewerComponent}
            disabled={disabled}
        />
    );
};

export type InterviewQuestionEditorEditingProps = {
    isAddingEntry: false;
    sectionId: number;
    entry: InterviewScriptEntry;
    tipsOperations: TipsOperations;
    questionOperations: InterviewQuestionOperations;
    onCancelAdding?: () => void;
    disabled?: boolean;
    hidden?: boolean;
    questionHints?: JourneyTaskGuidanceHints;
    isDragView?: boolean;
    allowEditing: boolean;
    isPlaceholder?: boolean;
    elementAttributes?: React.HTMLAttributes<HTMLDivElement>;
    forwardElementRef?: (element: HTMLElement | null) => void;
};

export type InterviewQuestionEditorAddingQuestionProps = {
    isAddingEntry: true;
    sectionId: number;
    entry?: InterviewScriptEntry;
    onCancelAdding?: () => void;
    tipsOperations?: TipsOperations;
    questionOperations: InterviewQuestionOperations;
    disabled?: boolean;
    hidden?: boolean;
    allowEditing: boolean;
    isDragView?: boolean;
    questionHints?: JourneyTaskGuidanceHints;
    isPlaceholder?: boolean;
    elementAttributes?: React.HTMLAttributes<HTMLDivElement>;
    forwardElementRef?: (element: HTMLElement | null) => void;
};

export type InterviewQuestionEditorProps = InterviewQuestionEditorEditingProps | InterviewQuestionEditorAddingQuestionProps;

export const InterviewQuestionEditor = ({
    isAddingEntry,
    sectionId,
    entry,
    disabled,
    hidden,
    onCancelAdding,
    isDragView,
    questionHints,
    isPlaceholder,
    allowEditing,
    tipsOperations,
    questionOperations,
    elementAttributes,
    forwardElementRef
}: InterviewQuestionEditorProps) => {
    const [tipsVisibleLocal, setTipsVisible] = useState<boolean>(false);

    const tipsVisible = tipsVisibleLocal && !isDragView && !isAddingEntry && ((allowEditing && !hidden) || !!entry?.tips.length);
    const [disabledWhileModifyActionPending, disableWhileModifyActionPending] = useSingleClickButton<[], Promise<unknown> | undefined>();

    const resetQuestionAction = !isAddingEntry ? disableWhileModifyActionPending(() => questionOperations.resetQuestion(sectionId, entry.id)) : undefined;
    const deleteQuestionAction = !isAddingEntry ? disableWhileModifyActionPending(() => questionOperations.deleteQuestion(sectionId, entry.id)) : undefined;

    const questionActions: DropDownButtonItem[] = isAddingEntry
        ? []
        : [
              entry.hidden
                  ? {
                        text: 'Unhide question',
                        danger: false,
                        separated: false,
                        action: () => questionOperations.unhideQuestion(sectionId, entry.id)
                    }
                  : {
                        text: 'Hide question',
                        danger: false,
                        separated: false,
                        action: () => questionOperations.hideQuestion(sectionId, entry.id)
                    },

              {
                  text: 'Reset question',
                  danger: false,
                  separated: false,
                  action: resetQuestionAction
              },
              {
                  text: 'Delete question',
                  danger: true,
                  separated: true,
                  action: deleteQuestionAction
              }
          ];

    const actionsDisabled = disabled || isAddingEntry || disabledWhileModifyActionPending;
    const actionsContainer = (
        <StackLayout
            align={{ vertical: 'middle', horizontal: 'start' }}
            className={combineClassNames(isDragView ? 'k-visibility-invisible' : '', 'k-gap-2 k-ml-1')}
        >
            {allowEditing && <BoundDropDownButton fillMode="flat" size="small" icon="more-horizontal" disabled={actionsDisabled} items={questionActions} />}
            <Button
                togglable
                fillMode="outline"
                size="small"
                onClick={() => setTipsVisible(tipsVisible => !tipsVisible)}
                selected={tipsVisible}
                themeColor="info"
                disabled={actionsDisabled || ((!allowEditing || hidden) && !entry?.tips.length)}
                rounded="full"
                className="k-shrink-0 !k-min-w-0 k-w-12 !k-fs-xs !k-font-normal"
            >
                {!isAddingEntry ? (entry.tips.length === 1 ? '1 tip' : `${entry.tips.length} tips`) : '0 tips'}
            </Button>
        </StackLayout>
    );

    const dragHandle = (
        <div
            data-drag-handle={true}
            className={combineClassNames('k-p-1', actionsDisabled ? 'k-disabled' : undefined, allowEditing ? 'k-cursor-pointer' : undefined)}
        >
            <ReorderIcon className="k-icp-icon k-icp-icon-size-4" />
        </div>
    );

    return (
        <div {...elementAttributes} ref={forwardElementRef}>
            <StackLayout align={{ vertical: 'top', horizontal: 'stretch' }} orientation="vertical" className="k-gap-1.5">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                    <InterviewScriptObjectEditor
                        additionalContentLeft={dragHandle}
                        additionalContentRight={actionsContainer}
                        onCancel={() => onCancelAdding?.()}
                        onSave={content =>
                            isAddingEntry
                                ? questionOperations.createQuestion(sectionId, content).finally(() => onCancelAdding?.())
                                : questionOperations.updateQuestion(sectionId, entry!.id, content)
                        }
                        className="k-flex-1"
                        settings={{ size: 'medium' }}
                        hints={isAddingEntry ? questionHints : undefined}
                        hideEditControls={!isAddingEntry}
                        allowEditing={allowEditing}
                        autoFocus={isAddingEntry}
                        disabled={disabled || hidden || disabledWhileModifyActionPending}
                        label={!isAddingEntry ? entry?.label : undefined}
                        placeholder={isAddingEntry ? 'Type a question...' : undefined}
                        content={isAddingEntry ? undefined : entry!.content}
                    />
                </StackLayout>
                {tipsVisible && !isPlaceholder && (
                    <InterviewTipsView
                        allowEditing={allowEditing}
                        disabled={disabled || disabledWhileModifyActionPending}
                        hidden={hidden}
                        sectionId={sectionId}
                        entryId={entry!.id}
                        tips={entry!.tips}
                        tipsOperations={tipsOperations!}
                    />
                )}
            </StackLayout>
        </div>
    );
};

export const InterviewTipsView = ({
    sectionId,
    entryId,
    tips,
    disabled,
    hidden,
    allowEditing,
    tipsOperations
}: {
    sectionId: number;
    entryId: number;
    allowEditing: boolean;
    tips: InterviewScriptTip[];
    tipsOperations: TipsOperations;
    disabled?: boolean;
    hidden?: boolean;
}) => {
    const [isAddingTip, setIsAddingTip] = useState(false);

    return (
        <StackLayout orientation="vertical" align={{ vertical: 'top', horizontal: 'stretch' }} className="k-gap-1 k-ml-14 k-mr-22">
            <span className={combineClassNames('k-fs-sm k-font-semibold', disabled ? 'k-disabled' : undefined)}>Interviewing tips</span>

            {tips.map(tip => {
                return (
                    <TipRowView
                        allowEditing={allowEditing}
                        disabled={disabled}
                        key={tip.id}
                        entryId={entryId}
                        sectionId={sectionId}
                        tip={tip}
                        tipsOperations={tipsOperations}
                        hidden={hidden}
                    />
                );
            })}
            {!allowEditing || hidden ? null : !isAddingTip ? (
                <Button
                    disabled={disabled}
                    size="small"
                    fillMode="flat"
                    onClick={e => setIsAddingTip(isAdding => !isAdding)}
                    themeColor="base"
                    className="k-align-self-start"
                >
                    <SvgIconButtonContent icon={PlusIcon}>Add tip</SvgIconButtonContent>
                </Button>
            ) : (
                <InterviewScriptObjectEditor
                    onSave={updatedContent => tipsOperations.createTip(entryId, sectionId, updatedContent).finally(() => setIsAddingTip(false))}
                    onCancel={() => setIsAddingTip(false)}
                    placeholder="Type a tip..."
                    settings={{ size: 'small' }}
                    collapsedActionsView
                    allowEditing={allowEditing}
                    autoFocus
                />
            )}
        </StackLayout>
    );
};

const TipRowView = ({
    sectionId,
    entryId,
    tip,
    disabled,
    allowEditing,
    hidden,
    tipsOperations
}: {
    sectionId: number;
    entryId: number;
    allowEditing: boolean;
    tip: InterviewScriptTip;
    disabled?: boolean;
    hidden?: boolean;
    tipsOperations: TipsOperations;
}) => {
    const [deletePendingDisabled, deleteTipCallbackSingleClick] = useSingleClickButton<[number], Promise<unknown> | undefined>();

    const deleteTipAction = deleteTipCallbackSingleClick((tipId: number) => {
        return tipsOperations.deleteTip(sectionId, entryId, tipId);
    });

    return (
        <StackLayout align={{ vertical: 'middle', horizontal: 'start' }} className="k-gap-1">
            <InterviewScriptObjectEditor
                allowEditing={allowEditing}
                className="k-flex-1"
                onSave={updatedContent => {
                    return tipsOperations.updateTip(entryId, sectionId, tip.id, updatedContent);
                }}
                disabled={hidden || disabled || deletePendingDisabled}
                settings={{ size: 'small' }}
                content={tip.content}
                hideEditControls
                autoFocus={false}
            />
            {allowEditing && !hidden && (
                <Button
                    size="small"
                    fillMode="flat"
                    themeColor="base"
                    className="k-icp-svg-icon-button"
                    disabled={disabled || deletePendingDisabled}
                    onClick={() => deleteTipAction(tip.id)}
                >
                    <TrashIcon className="k-icp-icon" />
                </Button>
            )}
        </StackLayout>
    );
};

const InterviewScriptInlineEditor = forwardRef<
    InlineEditorComponentHandle,
    InlineEditorComponentProps<
        string,
        {
            textAreaProps: TextAreaProps;
            label?: ReactNode;
            additionalContentLeft?: ReactNode;
            additionalContentRight?: ReactNode;
            allowEditing?: boolean;
            isCollapsedActionsView?: boolean;
        }
    >
>(function(props, ref) {
    const inputSize = props.settings?.textAreaProps?.size;
    const inputClass = inputSize === 'small' ? 'k-input-sm' : inputSize === 'medium' ? 'k-input-md' : undefined;

    const editor = !props.settings?.allowEditing ? (
        <div
            className={combineClassNames(
                inputClass,
                'k-input k-input-solid k-rounded-md',
                props.settings?.label ? 'inline-editor-has-label inline-editor-padded' : undefined
            )}
        >
            <div className="k-input-inner">{props.value}</div>
        </div>
    ) : (
        <InlineEditorTextArea ref={ref} {...props} settings={props.settings?.textAreaProps} />
    );

    return (
        <>
            {props.settings?.label}
            {props.settings?.isCollapsedActionsView ? (
                editor
            ) : (
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                    {props.settings?.additionalContentLeft}
                    {editor}
                    {props.settings?.additionalContentRight}
                </StackLayout>
            )}
        </>
    );
});

function InterviewScriptObjectEditor({
    content,
    disabled,
    onSave,
    onCancel,
    placeholder,
    className,
    settings,
    label,
    hints,
    allowEditing,
    additionalContentLeft,
    additionalContentRight,
    collapsedActionsView,
    hideEditControls,
    autoFocus
}: {
    content?: string;
    disabled?: boolean;
    onSave?: (updatedContent: string) => Promise<void>;
    onCancel?: () => void;
    placeholder?: string;
    className?: string;
    settings?: TextAreaProps;
    allowEditing: boolean;
    label?: InterviewScriptEntryLabel;
    hints?: JourneyTaskGuidanceHints;
    additionalContentLeft?: React.ReactNode;
    additionalContentRight?: React.ReactNode;
    collapsedActionsView?: boolean;
    hideEditControls?: boolean;
    autoFocus?: boolean;
}) {
    const [updatedContent, setUpdatedContent] = useState<string | undefined>(undefined);
    const [showHints, setShowHints] = useState(false);

    const saveAction = async () => {
        try {
            if (updatedContent !== undefined && updatedContent !== content && updatedContent.trim().length !== 0) {
                await onSave?.(updatedContent);
            } else {
                onCancel?.();
            }
        } finally {
            setUpdatedContent(undefined);
        }
    };

    const currentContent = updatedContent ?? content;

    const labelView = label && (
        <InterviewScriptEntryLabelView
            className={combineClassNames('k-pos-absolute k-z-10 k-left-9 k-user-select-text', disabled ? 'k-disabled' : '')}
            color={label.color}
        >
            {label.text}
        </InterviewScriptEntryLabelView>
    );

    return (
        <InlineEditor
            editor={InterviewScriptInlineEditor}
            editClassName={combineClassNames(collapsedActionsView ? 'k-d-flex k-gap-1 k-align-items-center' : undefined, className)}
            editControlsClassname={collapsedActionsView ? '!k-m-0' : 'k-ml-7 k-mr-22'}
            hideEditControls={hideEditControls}
            additionalEditControls={
                hints && (
                    <Hints
                        show={showHints}
                        onToggleHints={() => setShowHints(showHints => !showHints)}
                        hints={<HintsDefaultLayout title={hints.title} hints={hints.items} />}
                    />
                )
            }
            allowFloatingItems
            editorSettings={{
                textAreaProps: {
                    rows: 1,
                    autoSize: true,
                    className: label ? 'inline-editor-has-label' : undefined,
                    placeholder: placeholder,
                    maxLength: 1000,
                    ...settings
                },
                additionalContentLeft: additionalContentLeft,
                additionalContentRight: additionalContentRight,
                label: labelView,
                allowEditing: allowEditing,
                isCollapsedActionsView: collapsedActionsView
            }}
            isEditing
            autoFocus={autoFocus}
            onEditorChange={e => setUpdatedContent(e.value)}
            onSave={saveAction}
            onCancel={onCancel}
            value={currentContent}
            disabled={disabled}
        />
    );
}
