import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BoundDropDownButton } from '../../../components/common/boundDropDownButton';
import { CellTemplateProps, createCellTemplateFromComponent } from '../../../components/common/grid';
import { useDeleteCollectionInterview } from '../../../components/experiments/interviewCollections/common';
import { DonutProgress } from '../../../components/ui/donutProgress';
import LoadingIndicator from '../../../components/ui/loadingIndicator';
import { TypingDots } from '../../../components/ui/typingDots';
import { useIdeaParams, useIntParam } from '../../../hooks/routerHooks';
import { ReactComponent as EditIcon } from '../../../icons/edit-2.svg';
import { combineClassNames, immutableRemove } from '../../../services/common';
import { interviewCollectionsService, MinimalCollectionInterview } from '../../../services/experiments/interviewCollectionsService';
import { RealTimeExperimentalInterviewCollectionEventData, realTimeUpdatesEventHub } from '../../../services/realTimeUpdatesService';
import { UserRole } from '../../../services/usersService';
import { useAppSelector } from '../../../state/hooks';

export function InterviewsInCollectionPage() {
    const { ideaId } = useIdeaParams();
    const collectionId = useIntParam('collectionId');
    const [interviews, setInterviews] = useState<MinimalCollectionInterview[]>();
    const navigate = useNavigate();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canExecuteActions = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const getInterviewData = useCallback(() => interviewCollectionsService.getInterviews(ideaId, collectionId).then(setInterviews), [collectionId, ideaId]);

    useEffect(() => {
        getInterviewData();

        const onAnalysisComplete = (e: RealTimeExperimentalInterviewCollectionEventData) => {
            if (e.ideaId !== ideaId || e.interviewCollectionId !== collectionId) return;
            getInterviewData();
        };

        realTimeUpdatesEventHub.addEventListener('interviewCollection', 'analysisComplete', onAnalysisComplete);
        realTimeUpdatesEventHub.addEventListener('interviewCollection', 'interviewScriptAnalysisComplete', onAnalysisComplete);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interviewCollection', 'analysisComplete', onAnalysisComplete);
            realTimeUpdatesEventHub.removeEventListener('interviewCollection', 'interviewScriptAnalysisComplete', onAnalysisComplete);
        };
    }, [collectionId, getInterviewData, ideaId]);

    const [deleteInterview, deleteInterviewDialog] = useDeleteCollectionInterview(ideaId, collectionId);

    return (
        <>
            {interviews ? (
                <>
                    <Grid
                        data={interviews}
                        onRowClick={e => navigate(`${(e.dataItem as MinimalCollectionInterview).id}`)}
                        className="k-grid-no-scrollbar k-icp-grid-navigatable experimental-interviews-grid"
                        onItemChange={e => {
                            if (e.field !== 'command') return;
                            const interview: MinimalCollectionInterview = e.dataItem;
                            if (e.value === 'view') navigate(`${interview.id}`);
                            if (e.value === 'delete')
                                deleteInterview(
                                    interview,
                                    () => interviews.length === 1 && interviews[0].id === interview.id,
                                    (collectionDeleted: boolean) => {
                                        if (collectionDeleted) navigate('./..');
                                        else setInterviews(interviews => immutableRemove(interviews, i => i.id !== interview.id));
                                    }
                                );
                        }}
                    >
                        <GridColumn title="Interview" cell={InterviewIntervieweeCellTemplate} />
                        <GridColumn title="Quality score" cell={InterviewQualityScoreCellTemplate} />
                        <GridColumn title="Script coverage" cell={InterviewScriptAnalysisCellTemplate} />
                        <GridColumn title="Insights question covered" cell={InterviewInsightsCoverageCellTemplate} />
                        {canExecuteActions && <GridColumn title="Actions" cell={InterviewActionsCellTemplate} width={100} />}
                    </Grid>
                    {deleteInterviewDialog}
                </>
            ) : (
                <LoadingIndicator size="big" className="k-align-self-center" />
            )}
        </>
    );
}

const InterviewActionsCellTemplate = createCellTemplateFromComponent(function({
    dataItem: interview,
    cellProps
}: CellTemplateProps<MinimalCollectionInterview>) {
    const onChange = cellProps.onChange;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Link
                to={`${interview.id}/edit`}
                className={combineClassNames(
                    'k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md k-icp-svg-icon-button',
                    interview.qualityScore == null ? 'k-disabled' : undefined
                )}
            >
                <EditIcon className="k-icp-icon" />
            </Link>
            <BoundDropDownButton
                icon="more-horizontal"
                popupSettings={{
                    anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                    popupAlign: { horizontal: 'right', vertical: 'top' }
                }}
                items={[
                    {
                        text: 'View',
                        action(e) {
                            onChange?.({
                                dataItem: cellProps.dataItem,
                                dataIndex: cellProps.dataIndex,
                                syntheticEvent: e.syntheticEvent,
                                field: 'command',
                                value: 'view'
                            });
                        }
                    },
                    {
                        text: 'Delete',
                        separated: true,
                        danger: true,
                        disabled: interview.qualityScore == null,
                        action(e) {
                            onChange?.({
                                dataItem: cellProps.dataItem,
                                dataIndex: cellProps.dataIndex,
                                syntheticEvent: e.syntheticEvent,
                                field: 'command',
                                value: 'delete'
                            });
                        }
                    }
                ]}
            />
        </StackLayout>
    );
});

const InterviewIntervieweeCellTemplate = createCellTemplateFromComponent(function({
    dataItem: interview,
    cellProps
}: CellTemplateProps<MinimalCollectionInterview>) {
    return <strong className="k-fs-lg k-no-click">{interview.title}</strong>;
});

const InterviewQualityScoreCellTemplate = createCellTemplateFromComponent(function({
    dataItem: interview,
    cellProps
}: CellTemplateProps<MinimalCollectionInterview>) {
    return <ProgressCellValue notApplicable={interview.qualityScore === -1} progress={interview.qualityScore ?? undefined} />;
});

const InterviewScriptAnalysisCellTemplate = createCellTemplateFromComponent(function({
    dataItem: interview,
    cellProps
}: CellTemplateProps<MinimalCollectionInterview>) {
    return <ProgressCellValue notApplicable={interview.scriptCoverage === -1} progress={interview.scriptCoverage ?? undefined} />;
});

const InterviewInsightsCoverageCellTemplate = createCellTemplateFromComponent(function({
    dataItem: interview,
    cellProps
}: CellTemplateProps<MinimalCollectionInterview>) {
    return <ProgressCellValue notApplicable={interview.insightCoverage === -1} progress={interview.insightCoverage ?? undefined} />;
});

function ProgressCellValue({ progress, progressMaxValue, notApplicable }: { progress?: number; progressMaxValue?: number; notApplicable?: boolean }) {
    if (notApplicable) return <div className="k-icp-subtle-text k-icp-text-sm">Not enough data</div>;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-no-click">
            {progress === undefined ? (
                <>
                    <Skeleton shape="rectangle" className="k-rounded-full k-w-8 k-h-8 k-shrink-0" />{' '}
                    <div className="k-icp-subtle-text k-fs-sm">
                        analyzing
                        <TypingDots />
                    </div>
                </>
            ) : (
                <>
                    <DonutProgress progress={progressMaxValue ? (progress / progressMaxValue) * 100 : progress} />{' '}
                    <div className="k-text-secondary">{`${progress}${progressMaxValue ? ` / ${progressMaxValue}` : '%'}`}</div>
                </>
            )}
        </StackLayout>
    );
}
