import { useEffect, useState } from 'react';

const MAX_DOTS_COUNT = 3;
const DOTS_RERENDER_INTERVAL = 300;

let currentDotsCount = 0;
let dotsIntervalId: NodeJS.Timer | undefined;
const dotsInstanceCallbacks: ((dotsCount: number) => void)[] = [];

function renderAllDotsInstances() {
    if (currentDotsCount === MAX_DOTS_COUNT) currentDotsCount = 0;
    else ++currentDotsCount;

    dotsInstanceCallbacks.forEach(cb => cb(currentDotsCount));
}

function registerRenderDotsCallback(callback: (dotsCount: number) => void) {
    if (!dotsInstanceCallbacks.length) dotsIntervalId = setInterval(renderAllDotsInstances, DOTS_RERENDER_INTERVAL);
    dotsInstanceCallbacks.push(callback);
}

function unregisterRenderDotsCallback(callback: (dotsCount: number) => void) {
    dotsInstanceCallbacks.splice(dotsInstanceCallbacks.indexOf(callback), 1);
    if (!dotsInstanceCallbacks.length) clearInterval(dotsIntervalId);
}

export function TypingDots({ alwaysUseSpace = false }: { alwaysUseSpace?: boolean }) {
    const [instanceDotsCount, setInstanceDotsCount] = useState(0);

    useEffect(() => {
        registerRenderDotsCallback(setInstanceDotsCount);

        return () => unregisterRenderDotsCallback(setInstanceDotsCount);
    }, []);

    if (!instanceDotsCount && !alwaysUseSpace) return null;

    if (!instanceDotsCount && alwaysUseSpace) return <span className="k-visibility-invisible">...</span>;

    return <>{'.'.repeat(instanceDotsCount)}</>;
}
