import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useMemo, useState } from 'react';
import { ReactComponent as InviteIcon } from '../../icons/user-add.svg';
import { UserRole, buildUserViewModel } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';
import { InviteToPlatformModal } from '../platform/inviteToPlatformModal';
import { StartupInviteMemberModal } from '../startup/startupInviteMember';
import { StartupMembershipList } from '../startup/startupMembershipList';
import { ideasService } from '../../services/ideasService';

export const InviteNavItem = () => {
    const currentIdea = useAppSelector(s => s.idea.current);
    const currentRole = useAppSelector(s => s.idea.role);
    const ideaConnectedMembers = useAppSelector(s => s.ideaMembers.membersActivity);
    const connectedMembersViewModel = useMemo(() => ideaConnectedMembers?.map(m => buildUserViewModel(m.user)), [ideaConnectedMembers]);
    const [showInviteMembersPopup, setShowInviteMembersPopup] = useState(false);

    const onInviteButtonClick = () => {
        setShowInviteMembersPopup(s => !s);
    };

    return (
        <StackLayout className="k-gap-4" align={{ horizontal: 'start', vertical: 'middle' }}>
            <StartupMembershipList users={connectedMembersViewModel} />
            {currentRole !== UserRole.Viewer && (
                <>
                    <Button fillMode="flat" themeColor="secondary" onClick={onInviteButtonClick}>
                        <StackLayout className="k-gap-1" align={{ vertical: 'middle' }}>
                            <InviteIcon className="k-icp-icon k-icp-icon-size-4" />
                            {currentIdea ? 'Invite' : 'Invite to platform'}
                        </StackLayout>
                    </Button>

                    {showInviteMembersPopup &&
                        (currentIdea ? (
                            <StartupInviteMemberModal
                                ideaId={currentIdea.uniqueId}
                                ideaTitle={currentIdea.title}
                                onClose={() => setShowInviteMembersPopup(false)}
                                showManageLink={true}
                                manageLinkDisabled={ideasService.isIntroChatPending(currentIdea)}
                            />
                        ) : (
                            <InviteToPlatformModal onClose={() => setShowInviteMembersPopup(false)} />
                        ))}
                </>
            )}
        </StackLayout>
    );
};
