import { Skeleton } from '@progress/kendo-react-indicators';
import { ReactNode, Suspense, lazy, useState } from 'react';

const TruncateMarkup = lazy(() => import('react-truncate-markup'));
export type ToggleTruncateEvent = { readonly showMore: boolean; preventDefault?: boolean };
export function TextWithShowMoreByLines({
    children,
    lines,
    className,
    onToggleTruncate,
    loader
}: {
    children?: React.ReactNode;
    lines?: number;
    className?: string;
    onToggleTruncate?: (e: ToggleTruncateEvent) => void;
    loader?: ReactNode;
}) {
    const [isExpanded, setIsExpanded] = useState(false);

    function updateIsExpanded(value: boolean) {
        if (onToggleTruncate) {
            const event: ToggleTruncateEvent = { showMore: value };
            onToggleTruncate(event);
            if (event.preventDefault) return;
        }

        setIsExpanded(value);
    }

    if (isExpanded)
        return (
            <div className={className}>
                {children} <ToggleEllipsisButton onClick={() => updateIsExpanded(false)}>Show less</ToggleEllipsisButton>
            </div>
        );

    return (
        <Suspense fallback={loader ?? <Skeleton shape="text" className="-w100" />}>
            <TruncateMarkup tokenize="words" lines={lines} ellipsis={<ShowMoreEllipsis onClick={() => updateIsExpanded(true)} />}>
                <div className={className}>{children}</div>
            </TruncateMarkup>
        </Suspense>
    );
}

function ShowMoreEllipsis({ onClick }: { onClick?: () => void }) {
    return (
        <span>
            ... <ToggleEllipsisButton onClick={onClick}>Show more</ToggleEllipsisButton>
        </span>
    );
}

function ToggleEllipsisButton({ children, onClick }: { children?: string; onClick?: () => void }) {
    return (
        <span onClick={onClick} className="k-button-link-secondary k-cursor-pointer">
            {children}
        </span>
    );
}
