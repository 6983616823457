import { createContext, useContext, useLayoutEffect } from 'react';

export type StartupLayoutContextType = {
    pageContainerRef: React.RefObject<HTMLElement>;
    pageContainerClassName: string | undefined;
    setPageContainerClassName: (className: string | undefined) => void;
    setHideSidebar: (hide: boolean) => void;
};

export const StartupLayoutContext = createContext<StartupLayoutContextType>({
    pageContainerRef: { current: null },
    pageContainerClassName: undefined,
    setPageContainerClassName(_) {
        throw new Error('Use in StartupLayoutContext.Provider');
    },
    setHideSidebar(_) {
        throw new Error('Use in StartupLayoutContext.Provider');
    }
});

export function useStartupLayout() {
    const startupLayoutContext = useContext(StartupLayoutContext);

    return startupLayoutContext;
}

export function useHiddenStartupSidebar() {
    const { setHideSidebar: hideStartupSidebar } = useStartupLayout();
    useLayoutEffect(() => {
        hideStartupSidebar(true);

        return () => hideStartupSidebar(false);
    }, [hideStartupSidebar]);
}
