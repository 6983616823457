import { ChangeEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { FileResponse } from '../services/common';

export const useSingleFileUpload = (
    onChange: (event: { target?: any; value?: string | null }) => void,
    uploadFunction: (file: File) => Promise<FileResponse>,
    value?: string | null,
    filesValidators?: ((value: File[] | null) => string | undefined)[]
) => {
    return useFileUpload(onChange as (event: { target?: any; value?: string | string[] | null }) => void, false, uploadFunction, value, filesValidators);
};

export const useFileUpload = (
    onChange: (event: { target?: any; value?: string | string[] | null }) => void,
    multiple: boolean,
    uploadFunction: (file: File) => Promise<FileResponse>,
    value?: string | string[] | null,
    filesValidators?: ((value: File[] | null) => string | undefined)[]
) => {
    const [validationError, setValidationError] = useState('');
    const [uploading, setUploading] = useState(false);
    const uploadRef = useRef<HTMLInputElement>(null);

    const validate = useCallback(
        (value: File[] | null) => {
            if (!filesValidators || !filesValidators.length) {
                setValidationError('');
                return true;
            }

            for (const validator of filesValidators) {
                const errorMessage = validator(value);
                if (errorMessage) {
                    setValidationError(errorMessage);
                    return false;
                }
            }

            setValidationError('');
            return true;
        },
        [filesValidators]
    );

    const onInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) {
            return;
        }

        const filesToProcess = [];
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            filesToProcess.push(file);
        }

        await processFiles(filesToProcess);
    };

    const processFiles = useCallback(
        async (files: File[]) => {
            if (!files.length) return;
            if (!multiple && files.length > 1) files = [files[0]];
            if (!validate(files)) return;

            try {
                setUploading(true);
                const fileUploadPromises = [];
                for (const file of files) {
                    const fileUploadPromise = uploadFunction(file);
                    fileUploadPromises.push(fileUploadPromise);
                }

                const uploadedFiles = await Promise.all(fileUploadPromises);

                if (multiple) {
                    onChange({ target: uploadRef.current, value: uploadedFiles.map(f => f.url) });
                } else {
                    onChange({ target: uploadRef.current, value: uploadedFiles[0].url });
                }
            } catch {
                setValidationError(`Could not upload the selected file${multiple ? 's' : ''}`);
            } finally {
                setUploading(false);
            }
        },
        [multiple, onChange, uploadFunction, validate]
    );

    const clearValue = () => {
        if (uploadRef.current) {
            uploadRef.current.value = '';
        }

        onChange({ target: uploadRef.current, value: null });
    };

    const removeItem = (item: string) => {
        if (!value) return;

        if (Array.isArray(value)) {
            onChange({ target: uploadRef.current, value: value.filter(v => v !== item) });
        } else if (item === value) {
            clearValue();
        }
    };

    const { draggedOver, dropZoneRef } = useFileDropZone(processFiles);
    useFilePaste(processFiles);

    return {
        uploadRef,
        onInputChange,
        clearValue,
        validationError,
        uploading,
        removeItem,
        draggedOver,
        dropZoneRef
    };
};

export const useFileDropZone = (onDropped?: (files: File[]) => void) => {
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const [draggedOver, setDraggedOver] = useState(false);

    useEffect(() => {
        const dropZone = dropZoneRef.current;
        if (!dropZone) return;

        const onDragEnter = (e: DragEvent) => {
            const currentElement = e.currentTarget as Node;
            const newHoveredElement = e.target as Node;
            if (currentElement.contains(newHoveredElement)) setDraggedOver(true);
        };

        const onDragLeave = (e: DragEvent) => {
            const newHoveredElement = e.relatedTarget as Node;
            const currentElement = e.currentTarget as Node;
            if (newHoveredElement == null || !currentElement.contains(newHoveredElement)) setDraggedOver(false);
        };

        const onDragOver = (e: DragEvent) => e.preventDefault();
        const onDrop = (e: DragEvent) => {
            setDraggedOver(false);

            if (!e.dataTransfer) return;
            e.preventDefault();

            const droppedFiles: File[] = [];
            if (e.dataTransfer.items) {
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    const transferredItem = e.dataTransfer.items[i];
                    if (transferredItem.kind === 'file') {
                        const transferredFile = transferredItem.getAsFile();
                        if (transferredFile) droppedFiles.push(transferredFile);
                    }
                }
            } else {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    const transferredFile = e.dataTransfer.files[i];
                    droppedFiles.push(transferredFile);
                }
            }

            onDropped?.(droppedFiles);
        };

        dropZone.addEventListener('dragenter', onDragEnter);
        dropZone.addEventListener('dragleave', onDragLeave);
        dropZone.addEventListener('dragover', onDragOver);
        dropZone.addEventListener('drop', onDrop);

        return () => {
            if (!dropZone) return;

            dropZone.removeEventListener('dragenter', onDragEnter);
            dropZone.removeEventListener('dragleave', onDragLeave);
            dropZone.removeEventListener('dragover', onDragOver);
            dropZone.removeEventListener('drop', onDrop);
        };
    }, [onDropped]);

    return { draggedOver, dropZoneRef };
};

export const useFilePaste = (onPasted?: (files: File[]) => void, focusScopeElementRef?: RefObject<HTMLElement | null>) => {
    useEffect(() => {
        const onPaste = (e: ClipboardEvent) => {
            if (focusScopeElementRef?.current && !focusScopeElementRef.current.contains(document.activeElement)) return;

            const pastedItems = e.clipboardData?.items;
            if (!pastedItems || !pastedItems.length) return;

            const pastedFiles = [];
            for (let i = 0; i < pastedItems.length; i++) {
                const pastedItem = pastedItems[i];
                if (pastedItem.kind !== 'file') continue;
                const pastedFile = pastedItem.getAsFile();
                if (!pastedFile) continue;

                pastedFiles.push(pastedFile);
            }

            if (pastedFiles.length) {
                e.stopImmediatePropagation();
                e.stopPropagation();
            }

            onPasted?.(pastedFiles);
        };

        document.addEventListener('paste', onPaste);

        return () => {
            document.removeEventListener('paste', onPaste);
        };
    }, [focusScopeElementRef, onPasted]);
};
