import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H2, P } from '../../components/ui/typography';
import inviteLinkExpiredImage from '../../images/invite-link-expired-illustration.svg';
import { authenticationService } from '../../services/authenticationService';
import { getPersonFullName, returnUrlParamName, urlWithQueryParams } from '../../services/common';
import { HttpException } from '../../services/httpServiceBase';
import { Invite, InviteState, ideasService } from '../../services/ideasService';
import { EmailAddressAvailability, User, usersService } from '../../services/usersService';

enum InviteErrorState {
    Invalid,
    Expired,
    Declined
}

const emailAvailabilityToUrlPathMap: Record<EmailAddressAvailability, string | undefined> = {
    [EmailAddressAvailability.Available]: '/register',
    [EmailAddressAvailability.Taken]: '/login/password',
    [EmailAddressAvailability.Pending]: '/login/password',
    [EmailAddressAvailability.Deleted]: undefined
};

export default function AcceptInvitePage() {
    const { inviteSecret } = useParams();
    const [queryParams] = useSearchParams();
    const [inviteErrorState, setInviteErrorState] = useState<
        { errorState: InviteErrorState; invite: Invite | undefined; currentUser: User | undefined } | undefined
    >(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (!inviteSecret) {
            setInviteErrorState({ errorState: InviteErrorState.Invalid, invite: undefined, currentUser: undefined });
            return;
        }

        setInviteErrorState(undefined);

        const acceptInvite = async () => {
            let invite: Invite;
            try {
                invite = await ideasService.getInvite(inviteSecret);
            } catch (e) {
                setInviteErrorState({ errorState: InviteErrorState.Invalid, invite: undefined, currentUser: undefined });
                return;
            }

            if (invite.expires < new Date()) {
                setInviteErrorState({ errorState: InviteErrorState.Expired, invite: invite, currentUser: undefined });
                return;
            }

            if (invite.state === InviteState.Revoked) {
                setInviteErrorState({ errorState: InviteErrorState.Invalid, invite: invite, currentUser: undefined });
                return;
            }

            if (invite.state === InviteState.Declined) {
                setInviteErrorState({ errorState: InviteErrorState.Declined, invite: invite, currentUser: undefined });
                return;
            }

            if (!invite.idea) {
                setInviteErrorState({ errorState: InviteErrorState.Invalid, invite: invite, currentUser: undefined });
                return;
            }

            let loggedUser: User | undefined;
            const emailAvailability = await usersService.getEmailAvailability(invite.emailAddress);
            const activationCode = queryParams.get('activationCode');

            try {
                loggedUser = await usersService.getCurrent();
            } catch (e) {
                if (!(e instanceof HttpException && e.status === 401)) {
                    throw e;
                }

                await activateUserIfNeeded(emailAvailability, invite.emailAddress, activationCode);

                const pathToNavigate = emailAvailabilityToUrlPathMap[emailAvailability];
                if (!pathToNavigate) throw new Error('Unsupported email availability: ' + emailAvailability);

                if (invite.state === InviteState.Accepted) {
                    navigate(ideasService.getStartupRedirectUrl(invite.idea));
                    return;
                }

                const newParams = {
                    inviteCode: inviteSecret,
                    [returnUrlParamName]: ideasService.getStartupRedirectUrl(invite.idea)
                };

                const loginRedirectUrl = urlWithQueryParams(pathToNavigate, newParams);

                navigate(loginRedirectUrl, {
                    state: {
                        email: invite.emailAddress,
                        hasInvitation: true,
                        activationCode: emailAvailability === EmailAddressAvailability.Available && activationCode ? activationCode : undefined
                    }
                });
                return;
            }

            if (invite.state !== InviteState.Accepted && invite.emailAddress.toUpperCase() !== loggedUser.emailAddress.toUpperCase()) {
                navigate('/invites/error/different-user', { state: { inviteeEmail: invite.emailAddress } });
                return;
            }

            await activateUserIfNeeded(emailAvailability, invite.emailAddress, activationCode);

            if (invite.state === InviteState.Pending) await ideasService.acceptInvite(inviteSecret);

            navigate(ideasService.getStartupRedirectUrl(invite.idea));
        };

        acceptInvite();
    }, [inviteSecret, navigate, queryParams]);

    const activateUserIfNeeded = async (emailAvailability: EmailAddressAvailability, emailAddress: string, activationCode?: string | null) => {
        if (emailAvailability === EmailAddressAvailability.Pending && activationCode) {
            await authenticationService.activate(emailAddress, activationCode);
        }
    };

    const inviteErrorContent = inviteErrorState && {
        [InviteErrorState.Expired]: {
            title: 'Invite link expired',
            message:
                inviteErrorState.invite &&
                `Your invite link has expired. Ask ${getPersonFullName(
                    inviteErrorState.invite.creator.firstName,
                    inviteErrorState.invite.creator.lastName
                )} to send you a new invitation.`
        },
        [InviteErrorState.Declined]: {
            title: 'Invitation declined',
            message: `You have declined this invitation. If you still want to be added to the idea, ask for a new invite link.`
        },
        [InviteErrorState.Invalid]: {
            title: 'Oops, something went wrong with this invitation...',
            message: 'The invite link is invalid'
        }
    };

    return (
        <InviteErrorComponent
            loading={!inviteErrorContent}
            errorMessage={inviteErrorContent ? inviteErrorContent[inviteErrorState.errorState].message : ''}
            errorTitle={inviteErrorContent ? inviteErrorContent[inviteErrorState.errorState].title : ''}
        />
    );
}

export const InviteErrorComponent = ({ loading, errorMessage, errorTitle }: { loading?: boolean; errorMessage: ReactNode; errorTitle: ReactNode }) => {
    return (
        <div className="k-d-flex k-flex-col -minh100 k-align-items-center">
            <div className="k-flex-1 k-d-flex k-flex-col k-align-items-center k-justify-content-center page-content-section k-text-center">
                {!loading ? (
                    <>
                        <div className="k-mb-15">
                            <img src={inviteLinkExpiredImage} alt="Invalid invite link" width="640" height="324" className="responsive-image" />
                        </div>
                        <H2 className="!k-mb-4">{errorTitle}</H2>
                        <P className="!k-fs-lg" style={{ maxWidth: 490 }}>
                            {errorMessage}
                        </P>
                    </>
                ) : (
                    <LoadingIndicator size="big" />
                )}
            </div>
        </div>
    );
};
