import { ReactNode, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRealTimeUpdatesFocus } from '../../hooks/realTimeUpdatesHooks';
import { IdeaFlags } from '../../services/ideasService';
import {
    ConnectionFocus,
    RealTimeUpdateIdeaEventData,
    RealTimeUpdateMembershipEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { loadMembersActivityForIdea, resetIdeaMembers } from '../../state/idea/ideaMembersSlice';
import { clearIdea, loadIdeaById } from '../../state/idea/ideaSlice';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export default function IdeaZone({ children }: { children: ReactNode }) {
    const { ideaId } = useParams();
    const dispatch = useAppDispatch();
    const currentUserId = useAppSelector(s => s.user?.userId);
    const ideaFlags = useAppSelector(s => s.idea.current)?.flags;
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (!ideaFlags) return;

        if (ideaFlags.includes(IdeaFlags.INTRO_CHAT_PENDING_FLAG)) {
            navigate(`get-started`);
        }
    }, [ideaFlags, navigate]);

    useRealTimeUpdatesFocus(ideaId || ConnectionFocus.NoIdeas);
    useEffect(() => {
        if (!ideaId) {
            dispatch(clearIdea());
            dispatch(resetIdeaMembers());
            return;
        }

        const loadIdeaPromise = dispatch(loadIdeaById(ideaId));
        const loadConnectedMembersAction = dispatch(loadMembersActivityForIdea(ideaId));

        return () => {
            loadIdeaPromise.abort();
            dispatch(clearIdea());
            loadConnectedMembersAction.abort();
            dispatch(resetIdeaMembers());
        };
    }, [ideaId, dispatch]);

    useEffect(() => {
        if (!ideaId || !currentUserId) return;

        const onIdeaDeleted = (e: RealTimeUpdateIdeaEventData) => {
            if (e.ideaId !== ideaId) return;

            dispatch(addNotification({ content: 'The current startup was deleted' }));
            navigate('/');
        };

        const onAccessRevoked = (e: RealTimeUpdateMembershipEventData) => {
            if (e.ideaId !== ideaId || e.userId !== currentUserId) return;

            dispatch(addNotification({ content: 'Your access to the current startup was revoked' }));
            navigate('/');
        };

        realTimeUpdatesEventHub.addEventListener('idea', 'deleted', onIdeaDeleted);
        realTimeUpdatesEventHub.addEventListener('membership', 'revoke', onAccessRevoked);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('idea', 'deleted', onIdeaDeleted);
            realTimeUpdatesEventHub.removeEventListener('membership', 'revoke', onAccessRevoked);
        };
    }, [ideaId, currentUserId, dispatch, navigate]);

    return <>{children}</>;
}
