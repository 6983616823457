import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum ChatMessageType {
    User = 'User',
    Agent = 'Agent'
}

export enum ChatMessageBlockType {
    Text = 'Text',
    Options = 'Options',
    Canvas = 'Canvas',
    GoToJourney = 'GoToJourney',
    Tracking = 'Tracking',
    Error = 'error'
}

export interface ChatMessageBlock {
    id?: number;
    type: ChatMessageBlockType;
    content: string;
}

export type ChatMessage = {
    id?: number;
    createdOn?: Date;
    updatedOn?: Date;
} & (
    | {
          type: ChatMessageType.Agent;
          blocks: ChatMessageBlock[];
      }
    | {
          type: ChatMessageType.User;
          user: ReducedUser | null;
          content: string;
      }
);

export interface Chat {
    id: number;
    tag: string;
    closed: boolean;
    messages: ChatMessage[];
}

//TODO REMOVE MOCK DATA
const mockMessages: ChatMessage[] = [
    {
        id: 1,
        content: "Hi IVA! I'm interested in starting my own business.",
        type: ChatMessageType.User,
        createdOn: new Date(),
        user: null
    },
    {
        id: 2,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: "That's great!" },
            { id: 2, type: ChatMessageBlockType.Text, content: "I'd be happy to help guide you through the process." },
            { id: 3, type: ChatMessageBlockType.Text, content: 'What kind of business are you thinking about?' }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 3,
        content: "I'm thinking about starting a tech startup.",
        type: ChatMessageType.User,
        user: null,
        createdOn: new Date()
    },
    {
        id: 4,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: 'A tech startup is an exciting choice!' },
            { id: 2, type: ChatMessageBlockType.Text, content: 'There are many aspects to consider.' },
            { id: 3, type: ChatMessageBlockType.Text, content: 'What specific area of technology are you interested in?' }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 5,
        content: "I'm thinking about developing a mobile app for personal finance management.",
        type: ChatMessageType.User,
        user: null,
        createdOn: new Date()
    },
    {
        id: 6,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: "That's a great idea!" },
            { id: 2, type: ChatMessageBlockType.Text, content: 'Personal finance apps are in high demand.' },
            { id: 3, type: ChatMessageBlockType.Text, content: 'Have you done any market research to identify your target audience and potential competitors?' }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 7,
        content: "Not yet, I'm just in the initial planning stages. How should I start with market research?",
        type: ChatMessageType.User,
        createdOn: new Date(),
        user: null
    },
    {
        id: 8,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: "To begin your market research, I'd recommend the following steps:\n" },
            { id: 2, type: ChatMessageBlockType.Text, content: '1. Analyze existing finance apps\n' },
            { id: 3, type: ChatMessageBlockType.Text, content: '2. Identify your target demographic\n' },
            { id: 4, type: ChatMessageBlockType.Text, content: '3. Conduct surveys or interviews with potential users\n' },
            { id: 5, type: ChatMessageBlockType.Text, content: '4. Research market trends in personal finance and mobile apps\n' },
            { id: 6, type: ChatMessageBlockType.Text, content: 'Would you like me to elaborate on any of these points?' }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 9,
        content: 'Yes, could you tell me more about analyzing existing finance apps? What should I be looking for?',
        type: ChatMessageType.User,
        user: null,
        createdOn: new Date()
    },
    {
        id: 10,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: 'Certainly!\n' },
            { id: 2, type: ChatMessageBlockType.Text, content: 'When analyzing existing finance apps, pay attention to:\n' },
            {
                id: 3,
                type: ChatMessageBlockType.Options,
                content:
                    '<options>' +
                    '<option index="1">Features they offer</option>' +
                    '<option index="2">User interface and experience</option>' +
                    '<option index="3">Pricing models</option>' +
                    '</options>'
            },
            { id: 4, type: ChatMessageBlockType.Text, content: 'This will help you identify gaps in the market and opportunities for innovation.\n' },
            { id: 5, type: ChatMessageBlockType.Text, content: "Is there a specific aspect you'd like to focus on?" }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 11,
        content: "I think I'd like to focus on creating a more user-friendly interface. Many finance apps seem complicated.",
        type: ChatMessageType.User,
        user: null,
        createdOn: new Date()
    },
    {
        id: 12,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: "That's an excellent focus!\n" },
            { id: 2, type: ChatMessageBlockType.Text, content: 'A user-friendly interface can definitely set your app apart.\n' },
            { id: 3, type: ChatMessageBlockType.Text, content: 'Consider these principles for a more accessible finance app:\n' },
            {
                id: 6,
                type: ChatMessageBlockType.Options,
                content:
                    '<options>' +
                    '<option index="1">Clear and intuitive navigation</option>' +
                    '<option index="2">Simple data visualization</option>' +
                    '<option index="3">Customizable dashboards</option>' +
                    '</options>'
            },

            { id: 4, type: ChatMessageBlockType.Text, content: 'Would you like some resources on UI/UX design for finance apps?' }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 13,
        content: 'Yes, that would be very helpful. Could you recommend some resources?',
        type: ChatMessageType.User,
        user: null,
        createdOn: new Date()
    },
    {
        id: 14,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: 'Here are some valuable resources for UI/UX design in finance apps:\n' },
            {
                id: 2,
                type: ChatMessageBlockType.Text,
                content: "1. Google's Material Design Guidelines - especially their sections on data visualization and forms\n"
            },
            { id: 3, type: ChatMessageBlockType.Text, content: "2. Apple's Human Interface Guidelines for iOS - great for mobile-first design principles\n" },
            { id: 4, type: ChatMessageBlockType.Text, content: "3. The book 'Hooked' by Nir Eyal - focuses on building habit-forming products\n" },
            { id: 5, type: ChatMessageBlockType.Text, content: "4. Dribbble.com's finance app category - for visual inspiration\n" },
            { id: 6, type: ChatMessageBlockType.Text, content: "5. UX Planet's articles on fintech design patterns\n" },
            {
                id: 6,
                type: ChatMessageBlockType.Options,
                content:
                    '<options>' +
                    '<option index="1">Google\'s Material Design Guidelines - especially their sections on data visualization and forms</option>' +
                    '<option index="2">Apple\'s Human Interface Guidelines for iOS - great for mobile-first design principles</option>' +
                    '<option index="3">The book \'Hooked\' by Nir Eyal - focuses on building habit-forming products</option>' +
                    '<option index="4">Dribbble.com\'s finance app category - for visual inspiration</option>' +
                    '<option index="5">UX Planet\'s articles on fintech design patterns</option>' +
                    '</options>'
            }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 15,
        content: "Yes, I'd love to know how to apply these to my specific app idea.",
        type: ChatMessageType.User,
        user: null,
        createdOn: new Date()
    },
    {
        id: 16,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: "Let's break down how each resource can directly benefit your finance app development:\n" },
            { id: 2, type: ChatMessageBlockType.Text, content: 'Material Design Guidelines will help you:\n' },
            { id: 3, type: ChatMessageBlockType.Text, content: '- Create consistent, intuitive navigation patterns' },
            { id: 4, type: ChatMessageBlockType.Text, content: '- Design clear data input forms' },
            { id: 5, type: ChatMessageBlockType.Text, content: '- Implement effective data visualization components\n' },
            { id: 6, type: ChatMessageBlockType.Text, content: "Apple's Guidelines are crucial for:\n" },
            { id: 7, type: ChatMessageBlockType.Text, content: '- Optimizing touch interactions' },
            { id: 8, type: ChatMessageBlockType.Text, content: '- Ensuring accessibility features' },
            { id: 9, type: ChatMessageBlockType.Text, content: '- Managing complex financial data displays\n' },
            { id: 10, type: ChatMessageBlockType.Text, content: "'Hooked' methodology can help you:\n" },
            { id: 11, type: ChatMessageBlockType.Text, content: '- Design features that encourage daily app usage' },
            { id: 12, type: ChatMessageBlockType.Text, content: '- Create satisfying user reward systems' },
            { id: 13, type: ChatMessageBlockType.Text, content: '- Build habit-forming transaction logging\n' },
            { id: 14, type: ChatMessageBlockType.Text, content: 'Would you like to focus on implementing any of these specific aspects first?' }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    },
    {
        id: 17,
        content: "I think I should start with the data visualization aspects. What's the best approach?",
        type: ChatMessageType.User,
        user: null,
        createdOn: new Date()
    },
    {
        id: 18,
        blocks: [
            { id: 1, type: ChatMessageBlockType.Text, content: 'Excellent choice!\n' },
            { id: 2, type: ChatMessageBlockType.Text, content: "For personal finance data visualization, here's a comprehensive approach:\n" },
            {
                id: 1,
                type: ChatMessageBlockType.Options,
                content:
                    '<options>' +
                    '<option index="1">Essential Charts:\n' +
                    '- Pie charts for expense categories\n' +
                    '- Line graphs for spending trends\n' +
                    '- Bar charts for income vs. expenses\n' +
                    '- Progress bars for savings goals</option>' +
                    '<option index="2">Best Practices:\n' +
                    '- Use consistent color coding for different financial categories\n' +
                    '- Implement interactive tooltips for detailed information\n' +
                    '- Provide multiple time period views (daily, weekly, monthly, yearly)\n' +
                    '- Include customizable dashboards for personal preferences</option>' +
                    '<option index="3">Advanced Features:\n' +
                    '- Predictive spending patterns\n' +
                    '- Budget vs. actual comparisons\n' +
                    '- Investment portfolio performance tracking\n' +
                    '- Bill payment deadline visualizations</option>' +
                    '</options>'
            }
        ],
        type: ChatMessageType.Agent,
        createdOn: new Date()
    }
];

let currentDemoIndex = 0;
const LAST_MESSAGES_COUNT = 8;

class IdeaOnboardingService extends HttpServiceBase {
    constructor() {
        super('/api/chats');
    }

    //TODO REMOVE MOCK METHODS
    async handleDemoProgress(): Promise<ChatMessageBlock[] | string | null> {
        if (currentDemoIndex >= LAST_MESSAGES_COUNT) {
            // Reset demo
            currentDemoIndex = 0;
            return null;
        }

        const startIndex = mockMessages.length - LAST_MESSAGES_COUNT;
        const nextMessage = mockMessages[startIndex + currentDemoIndex];

        currentDemoIndex++;

        // Only return content for user messages
        if (nextMessage.type === ChatMessageType.User) {
            return nextMessage.content;
        }

        // If it was an AI message, immediately try to get the next message
        return this.handleDemoProgress();
    }

    // Add method to reset demo
    resetDemo(): void {
        currentDemoIndex = 0;
    }

    @ErrorWithOperationDisplayName('Get chat messages')
    async getChatMessages(
        ideaId: string,
        tag: string = 'intro',
        order: 'asc' | 'desc' = 'desc',
        afterId: number = 0,
        skip: number = 0,
        take: number = 10
    ): Promise<{ messages: ChatMessage[]; totalCount: number }> {
        const queryParams = new URLSearchParams();

        this.addQueryParamIfPresent(queryParams, 'order', order);
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        const result = await this.performRequest<{ messages: ChatMessage[]; totalCount: number }>({
            path: `/${ideaId}/${tag}/messages`,
            queryParams
        });

        // Ensure dates are properly formatted
        dateTimeService.ensureDateType(m => m, 'createdOn', ...result.messages);
        dateTimeService.ensureDateType(m => m, 'updatedOn', ...result.messages);

        return result;
    }

    @ErrorWithOperationDisplayName('Get chat with messages')
    async getChat(ideaId: string, tag: string = 'intro'): Promise<Chat> {
        const chat = await this.performRequest<Chat>({
            path: `/${ideaId}/${tag}`
        });

        // Ensure dates are properly formatted
        dateTimeService.ensureDateType(m => m, 'createdOn', ...chat.messages);
        dateTimeService.ensureDateType(m => m, 'updatedOn', ...chat.messages);

        //TODO REMOVE MOCK LOGIC
        // return {
        //     id: 1,
        //     tag: tag,
        //     systemPromptId: 'mock',
        //     messages: mockMessages.slice(0, -8) // Initial messages excluding last 8
        // };
        return chat;
    }

    @ErrorWithOperationDisplayName('Fix chat')
    async *fixChat(ideaId: string, tag: string = 'intro'): AsyncIterable<ChatMessageBlock> {
        const response = await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/${tag}/fix`,
            method: RequestMethod.POST,
            headers: {
                Accept: 'text/event-stream'
            }
        });

        const eventsIterator = this.processSseResponse(response);
        for await (const event of eventsIterator) yield { type: event.event as ChatMessageBlockType, content: event.data };
    }

    @ErrorWithOperationDisplayName('Send message to chat')
    async *sendUserMessage(ideaId: string, message: string, tag: string = 'intro'): AsyncIterable<ChatMessageBlock> {
        const response = await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/${tag}/messages`,
            method: RequestMethod.POST,
            body: { text: message }, // Updated to match StringDto
            headers: {
                Accept: 'text/event-stream'
            }
        });
        const eventsIterator = this.processSseResponse(response);
        for await (const event of eventsIterator) yield { type: event.event as ChatMessageBlockType, content: event.data };

        //TODO REMOVE MOCK LOGIC
        // Find matching response from mock data
        // const messageIndex = mockMessages.findIndex(msg => msg.type === ChatMessageType.User && msg.content === message);

        // const nextMessage = messageIndex >= 0 && messageIndex < mockMessages.length - 1 && mockMessages[messageIndex + 1];
        // const aiResponse =
        //     nextMessage && nextMessage.type === ChatMessageType.Assistant
        //         ? nextMessage.blocks
        //         : [{ type: BlockType.Text, content: "I'm not sure how to respond to that." }];

        // // Simulate streaming by splitting into sentences
        // // const sentences = aiResponse.split(/(?<=[.!?])\s+|(?<=\n)/);
        // for (const msgBlock of aiResponse) {
        //     await new Promise(resolve => setTimeout(resolve, 500));

        //     if (msgBlock.type === BlockType.Text) {
        //         yield msgBlock;
        //     } else {
        //         yield msgBlock;
        //         await new Promise(resolve => setTimeout(resolve, 5000));

        //         console.log('yielding options block', msgBlock);
        //     }
        // }
    }
}

// // Find matching response from mock data
// const messageIndex = mockMessages.findIndex(msg => msg.type === ChatMessageType.User && msg.content === message);

// const aiResponse =
//     messageIndex >= 0 && messageIndex < mockMessages.length - 1 ? mockMessages[messageIndex + 1].content : "I'm not sure how to respond to that.";

// // Simulate streaming by splitting into sentences
// const sentences = aiResponse.split(/(?<=[.!?])\s+|(?<=\n)/);
// for (const sentence of sentences) {
//     await new Promise(resolve => setTimeout(resolve, 500));
//     yield sentence;
// }

export const ideaOnboardingService = new IdeaOnboardingService();
