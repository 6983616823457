import { Card, CardBody, CardSubtitle, CardTitle, StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import batchUploadInterviewsIllustration from '../../../images/batch-upload-interviews-illustration.svg';
import manuallyUploadInterviewsIllustration from '../../../images/manually-upload-interviews-illustration.svg';
import { combineClassNames, GraphicData } from '../../../services/common';
import { InterviewCollectionUploadType } from '../../../services/experiments/interviewCollectionsService';

export function InterviewCollectionUploadTypeList({
    disabled,
    renderLinks,
    onUploadTypeClick,
    highlightedUploadType,
    compact
}: {
    disabled?: boolean;
    renderLinks?: boolean;
    onUploadTypeClick?: (type: InterviewCollectionUploadType) => void;
    highlightedUploadType?: InterviewCollectionUploadType;
    compact?: boolean;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-gap-4">
            <UploadInterviewsCard
                title="Upload all interviews at once"
                image={{ url: batchUploadInterviewsIllustration, width: 64, height: 65, description: 'Batch upload' }}
                link={renderLinks ? `create?mode=${InterviewCollectionUploadType.Batch}` : undefined}
                disabled={disabled}
                onClick={onUploadTypeClick && (() => onUploadTypeClick(InterviewCollectionUploadType.Batch))}
                highlighted={highlightedUploadType === InterviewCollectionUploadType.Batch}
                compact={compact}
            >
                Grab all your files from all interviews. IVA will handle them for you.
            </UploadInterviewsCard>
            <UploadInterviewsCard
                title="Upload interviews one by one"
                image={{ url: manuallyUploadInterviewsIllustration, width: 64, height: 65, description: 'Manual upload' }}
                link={renderLinks ? `create?mode=${InterviewCollectionUploadType.Single}` : undefined}
                disabled={disabled}
                onClick={onUploadTypeClick && (() => onUploadTypeClick(InterviewCollectionUploadType.Single))}
                highlighted={highlightedUploadType === InterviewCollectionUploadType.Single}
                compact={compact}
            >
                Focus on each individual interview and review insights as you upload.
            </UploadInterviewsCard>
        </StackLayout>
    );
}

function UploadInterviewsCard({
    image,
    title,
    children,
    link,
    disabled,
    onClick,
    highlighted,
    compact
}: {
    image: GraphicData;
    title: string;
    children: string;
    link?: To;
    disabled?: boolean;
    onClick?: () => void;
    highlighted?: boolean;
    compact?: boolean;
}) {
    const imageScale = compact ? 5 / 8 : 1;
    return (
        <UploadInterviewsCardWrapper link={link} disabled={disabled} onClick={onClick}>
            <Card
                className={combineClassNames(
                    'k-icp-interactive-card k-w-full k-h-full k-cursor-pointer',
                    highlighted ? '!k-border-2 !k-border-secondary' : undefined
                )}
            >
                <CardBody className={combineClassNames('k-text-center', compact ? '!k-p-6' : '!k-p-8', highlighted ? 'k-m--1px' : undefined)}>
                    <img
                        src={image.url}
                        alt={image.description}
                        width={image.width * imageScale}
                        height={image.height * imageScale}
                        className="k-display-block k-mx-auto k-mb-4"
                    />
                    <CardTitle className={compact ? '!k-fs-md !k-font-semibold' : undefined}>{title}</CardTitle>
                    <CardSubtitle className={compact ? undefined : '!k-fs-md'}>{children}</CardSubtitle>
                </CardBody>
            </Card>
        </UploadInterviewsCardWrapper>
    );
}

function UploadInterviewsCardWrapper({ children, link, disabled, onClick }: { children: ReactNode; link?: To; disabled?: boolean; onClick?: () => void }) {
    const style: React.CSSProperties = { width: 350 };
    const className = disabled ? 'k-disabled' : undefined;

    return link ? (
        <Link to={link} className={className} style={style} onClick={onClick}>
            {children}
        </Link>
    ) : (
        <div className={className} style={style} onClick={onClick}>
            {children}
        </div>
    );
}
