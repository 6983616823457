import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { InterviewFields } from '../../../components/experiments/interviewCollections/editInterview';
import { InterviewCollectionFields, interviewCollectionGoalDropDownValues } from '../../../components/experiments/interviewCollections/editInterviewCollection';
import { InterviewCollectionUploadTypeList } from '../../../components/experiments/interviewCollections/interviewCollectionUploadTypeList';
import { H1 } from '../../../components/ui/typography';
import { useInProgressOperationsTracker } from '../../../hooks/commonHooks';
import { useIdeaParams, useQueryParam } from '../../../hooks/routerHooks';
import { combineClassNames, tryParseEnum } from '../../../services/common';
import {
    InterviewCollection,
    InterviewCollectionGoal,
    interviewCollectionsService,
    InterviewCollectionUploadType,
    NewInterviewCollectionData
} from '../../../services/experiments/interviewCollectionsService';
import { useAppSelector } from '../../../state/hooks';

export function AddInterviewCollectionPage() {
    const { ideaId } = useIdeaParams();
    const modeParam = useQueryParam('mode');
    const mode = modeParam ? tryParseEnum<InterviewCollectionUploadType>(modeParam, InterviewCollectionUploadType) : undefined;
    const [selectedUploadType, setSelectedUploadType] = useState<InterviewCollectionUploadType>();
    const formMode = selectedUploadType ?? mode;
    const currentUser = useAppSelector(s => s.user);
    const navigate = useNavigate();

    const [isSubmitInProgress, trackFormSubmit] = useInProgressOperationsTracker();
    async function onCreateCollectionSubmit(data: Record<string, any>) {
        if (!formMode) return;

        const collectionData: NewInterviewCollectionData = {
            name: data.name,
            goal: data.goal.value,
            otherGoal: data.goal.value === InterviewCollectionGoal.Other ? data.otherGoal : null,
            objective: data.objective ?? null
        };

        let newCollection: InterviewCollection;
        if (formMode === InterviewCollectionUploadType.Batch) {
            newCollection = await interviewCollectionsService.createInterviewCollectionWithInterviews(ideaId, collectionData, data.files, data.script?.[0]);
        } else {
            newCollection = await interviewCollectionsService.createInterviewCollection(ideaId, collectionData);
            await interviewCollectionsService.createInterview(
                ideaId,
                newCollection.id,
                {
                    host: data.host,
                    interviewee: data.interviewee,
                    date: data.interviewDate
                },
                data.interviewFile[0],
                data.interviewScript?.[0]
            );
        }

        navigate(`../${newCollection.id}`);
    }

    return (
        <div>
            <H1 className="heading-row">
                {mode === InterviewCollectionUploadType.Single
                    ? 'Add interview'
                    : mode === InterviewCollectionUploadType.Batch
                    ? 'Add interviews collection'
                    : 'Add new collection'}
            </H1>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} style={{ width: 576 }} className="k-gap-10 k-mx-auto">
                {!mode && <InterviewCollectionUploadTypeList highlightedUploadType={selectedUploadType} onUploadTypeClick={setSelectedUploadType} compact />}
                {currentUser && (
                    <Form
                        ignoreModified={true}
                        initialValues={{
                            goal: interviewCollectionGoalDropDownValues[0],
                            interviewDate: new Date(),
                            host: `${currentUser.firstName} ${currentUser.lastName}`
                        }}
                        onSubmit={trackFormSubmit(onCreateCollectionSubmit)}
                        render={({ allowSubmit, valueGetter }) => {
                            const selectedCollectionGoal = valueGetter('goal');
                            return (
                                <FormElement noValidate={true}>
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-10">
                                        {formMode === InterviewCollectionUploadType.Single && (
                                            <>
                                                <InterviewFields />
                                                <div>
                                                    <div className="k-separator k-icp-component-border k-mb-8" />
                                                    <strong>This Interview will be added to a new collection:</strong>
                                                </div>
                                            </>
                                        )}

                                        {formMode && (
                                            <InterviewCollectionFields
                                                uploadInterviews={formMode === InterviewCollectionUploadType.Batch}
                                                freeTextGoal={selectedCollectionGoal && selectedCollectionGoal.value === InterviewCollectionGoal.Other}
                                            />
                                        )}

                                        <StackLayout align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-4 k-mt-4">
                                            <Button type="submit" themeColor="primary" size="large" disabled={!formMode || !allowSubmit || isSubmitInProgress}>
                                                Save and analyze
                                            </Button>
                                            <Link
                                                to=".."
                                                className={combineClassNames(
                                                    'k-button k-button-lg k-button-solid k-button-solid-base k-rounded-md',
                                                    isSubmitInProgress ? 'k-disabled' : undefined
                                                )}
                                            >
                                                Cancel
                                            </Link>
                                        </StackLayout>
                                    </StackLayout>
                                </FormElement>
                            );
                        }}
                    />
                )}
            </StackLayout>
        </div>
    );
}
