import { StackLayout } from '@progress/kendo-react-layout';
import { combineClassNames } from '../../../services/common';

const interviewCollectionsAnnouncementSteps: { title: string; description: string }[] = [
    {
        title: 'Easily Upload Your Interviews',
        description:
            'Upload your interview documents in any format you might have lying around. Our platform ensures your content is stored securely and ready for analysis.'
    },
    {
        title: 'Unlock Valuable Insights',
        description:
            'The Icanpreneur Virtual Assistant (IVA) will analyze your interviews and discover patterns, themes and key insights. IVA will quickly extract meaningful data, turning conversations into tangible knowledge.'
    },
    {
        title: 'Get better at interviewing',
        description: 'IVA will help you level up your interviewing skills and point out what went well and what can be improved.'
    }
];

export function InterviewCollectionsAnnouncement() {
    return (
        <StackLayout align={{ horizontal: 'stretch', vertical: 'top' }}>
            {interviewCollectionsAnnouncementSteps.map((step, stepIndex) => (
                <InterviewCollectionsAnnouncementStep
                    key={stepIndex}
                    index={stepIndex + 1}
                    title={step.title}
                    isLast={stepIndex === interviewCollectionsAnnouncementSteps.length - 1}
                >
                    {step.description}
                </InterviewCollectionsAnnouncementStep>
            ))}
        </StackLayout>
    );
}

function InterviewCollectionsAnnouncementStep({ index, title, children, isLast }: { index: number; title: string; children: string; isLast?: boolean }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }}>
                <div className={combineClassNames('k-flex-1', index !== 1 ? 'full-horizontal-separator' : undefined)} />
                <div className="k-rounded-full k-icp-component-border k-border k-border-solid k-py-1 k-icp-line-height-inline-md k-w-8 k-text-center">
                    {index}
                </div>
                <div className={combineClassNames('k-flex-1', isLast ? undefined : 'full-horizontal-separator')} />
            </StackLayout>
            <div className="k-text-center">
                <strong className="k-fs-lg k-display-block k-mb-1">{title}</strong>
                <div className="k-mx-4 k-fs-sm">{children}</div>
            </div>
        </StackLayout>
    );
}
