import { classNames } from '@progress/kendo-react-common';
import { CSSProperties, forwardRef } from 'react';
import { ReactComponent as AIAvatarSvg } from '../../images/ai-avatar.svg';
import { combineClassNames } from '../../services/common';

type AIAvatarProps = {
    size?: string | number;
    iconSize?: string | number;
    borderSize?: string | number;
    pulse?: boolean;
    draw?: boolean;
    hideBorder?: boolean;
    onBorderDrawn?: () => void;
    className?: string;
};

export const AIAvatar = forwardRef<HTMLDivElement, AIAvatarProps>(function AIAvatar(
    { size, iconSize, borderSize, pulse, draw, hideBorder, onBorderDrawn, className },
    ref
) {
    const wrapperSizeAttributes = getWrapperSizeAttributes(size);
    const avatarSizeAttributes = getAvatarSizeAttributes(iconSize);
    const borderSizeAttributes = getBorderSizeAttributes(borderSize);

    return (
        <div
            ref={ref}
            className={combineClassNames('k-d-flex k-align-items-center k-justify-content-center k-pos-relative', wrapperSizeAttributes.className, className)}
            style={wrapperSizeAttributes.style}
        >
            {!hideBorder && (
                <div
                    className={classNames(
                        'k-border-solid k-border-transparent ai-border k-rounded-full',
                        borderSizeAttributes.className,
                        pulse ? 'ai-border--pulse' : undefined,
                        draw ? 'ai-border--draw' : undefined
                    )}
                    style={borderSizeAttributes.style}
                    onAnimationEnd={onBorderDrawn && (e => e.animationName === 'ai-border-draw' && onBorderDrawn())}
                />
            )}
            <AIAvatarSvg {...avatarSizeAttributes} />
        </div>
    );
});

function getWrapperSizeAttributes(size?: string | number): { className?: string; style?: CSSProperties } {
    if (!size || size === 'medium') return { className: 'k-avatar-md' };
    if (size === 'small') return { className: 'k-avatar-sm' };
    if (size === 'large') return { className: 'k-avatar-lg' };

    if (typeof size === 'string') return { className: size };

    return { style: { width: size, height: size } };
}

function getAvatarSizeAttributes(iconSize?: string | number): { className?: string; style?: CSSProperties } {
    if (!iconSize) return { className: 'k-icp-icon-size-6' };
    if (iconSize === '3' || iconSize === '4' || iconSize === '6') return { className: `k-icp-icon-size-${iconSize}` };

    if (typeof iconSize === 'string') return { className: iconSize };

    return { style: { width: iconSize, height: iconSize } };
}

function getBorderSizeAttributes(borderSize?: string | number): { className?: string; style?: CSSProperties } {
    if (!borderSize) return { className: 'k-border' };
    if (typeof borderSize === 'string') return { className: borderSize };

    return { style: { borderWidth: borderSize } };
}
