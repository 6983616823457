import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Field } from '@progress/kendo-react-form';
import { useMemo } from 'react';
import { KeyboardNavigatableDateInput } from '../../common/date';
import { FileSelector, maxLengthValidator, minLengthValidator, requiredValidator, useFileSelectorValidator, ValidatedInput } from '../../ui/inputs';
import { allowedInterviewFileExtensions, maxInterviewFileSizeInBytes } from './common';

const emptyInterviewFilesErrorMessage = 'Interview files are required';
const hostValidators = [requiredValidator('Host'), maxLengthValidator('Host', 1000)];
const intervieweeValidators = [requiredValidator('Interviewee'), maxLengthValidator('Interviewee', 1000)];
const interviewDateValidators = [requiredValidator('Date')];
export function InterviewFields({ hideFiles, hideScript }: { hideFiles?: boolean; hideScript?: boolean }) {
    const [interviewScriptValidator, interviewScriptFilesErrors] = useFileSelectorValidator(allowedInterviewFileExtensions, maxInterviewFileSizeInBytes);
    const [interviewFileValidator, interviewFileErrors] = useFileSelectorValidator(allowedInterviewFileExtensions, maxInterviewFileSizeInBytes);
    const interviewFileValidators = useMemo(
        () => [
            interviewFileValidator,
            requiredValidator(emptyInterviewFilesErrorMessage, false),
            minLengthValidator(emptyInterviewFilesErrorMessage, 1, false)
        ],
        [interviewFileValidator]
    );

    return (
        <>
            <Field name="host" component={ValidatedInput} label="Interview host" validator={hostValidators} maxLength={1000} placeholder="Type a name..." />

            <Field
                name="interviewee"
                component={ValidatedInput}
                label="Interviewee name"
                validator={intervieweeValidators}
                maxLength={1000}
                placeholder="Type a name..."
            />

            <Field
                name="interviewDate"
                component={ValidatedInput}
                label="Interview date"
                inputType={DatePicker}
                dateInput={KeyboardNavigatableDateInput}
                placeholder="Pick a date.."
                validator={interviewDateValidators}
            />

            {!hideFiles && (
                <Field
                    name="interviewFile"
                    component={ValidatedInput}
                    inputType={FileSelector}
                    label="Upload interview file"
                    labelClassName="!k-mb-thin"
                    acceptFileExtensions={allowedInterviewFileExtensions.join(', ')}
                    description="IVA provides better results when working with full transcripts, but will analyze any file you upload and help you get the most out of every bit of data you have."
                    validator={interviewFileValidators}
                    fileErrors={interviewFileErrors}
                    hideErrorMessage={Boolean(interviewFileErrors)}
                />
            )}

            {!hideScript && (
                <Field
                    name="interviewScript"
                    component={ValidatedInput}
                    inputType={FileSelector}
                    label="Upload interview script (optional)"
                    labelClassName="!k-mb-thin"
                    acceptFileExtensions={allowedInterviewFileExtensions.join(', ')}
                    description="Upload file with questions you have prepared for the interview. IVA will provide you feedback how well you have covered them during the interview."
                    validator={interviewScriptValidator}
                    fileErrors={interviewScriptFilesErrors}
                    hideErrorMessage={Boolean(interviewScriptFilesErrors)}
                />
            )}
        </>
    );
}
