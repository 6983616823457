import { ResponsiveGroup, useResponsiveLayout } from '../../hooks/commonHooks';
import feedbackIcon from '../../images/feedback-icon-animated.svg';
import SidePanelButton from '../sidebar/sidePanelButton';
import { InviteNavItem } from './inviteNavItem';
import { NotificationsNavItem } from './notifications';
import { TrialNoticeNavItem } from './trialNoticeNavItem';
import UserProfileNavItem from './userProfileNavItem';

export default function UserNavMenu() {
    const responsiveGroup = useResponsiveLayout();
    return (
        <>
            {responsiveGroup !== ResponsiveGroup.xs ? (
                <>
                    <InviteNavItem />
                    <div className="vertical-separator vertical-separator-short"></div>
                    <SidePanelButton view="feedback" title="Feedback" className="k-icp-tooltip-trigger">
                        <img src={feedbackIcon} alt="Feedback icon" width={24} height={24} className="k-display-block" />
                    </SidePanelButton>
                    <NotificationsNavItem />
                    <TrialNoticeNavItem />
                    <UserProfileNavItem />
                </>
            ) : (
                <>
                    <UserProfileNavItem />
                </>
            )}
        </>
    );
}
