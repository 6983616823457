import { guid } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import { useMemo } from 'react';
import { AIChatPanel } from '../../../components/ai/aiChatPanel';
import { IVALoadingIndicator } from '../../../components/ai/ivaLoadingIndicator';
import { stripAIThinkingMessage } from '../../../components/experiments/interviewCollections/common';
import { useConfirmDialog } from '../../../hooks/dialogHooks';
import { useIdeaParams, useIntParam } from '../../../hooks/routerHooks';
import { ReactComponent as SummaryIcon } from '../../../images/insights-summary-illustration.svg';
import {
    InterviewCollection,
    InterviewCollectionInsight,
    InterviewCollectionInsightSummary,
    interviewCollectionsService
} from '../../../services/experiments/interviewCollectionsService';
import { useTabbedLayoutData } from '../../layouts/tabbedLayout';
import { AIGeneratedResultsPanel, InterviewInsightsTopicPanel } from '../components/interviewInsights';

export function InterviewCollectionInsightsPage() {
    const { ideaId } = useIdeaParams();
    const collectionId = useIntParam('collectionId');
    const { collection, updateCollection } = useTabbedLayoutData<{
        collection: InterviewCollection | undefined;
        updateCollection: (interviewCollection: InterviewCollection) => void;
    }>();
    const { show: showDeleteCustomInsightQuestionDialog, element: deleteCustomInsightQuestionDialog } = useConfirmDialog();
    const chatId = useMemo(() => guid(), []);

    if (!collection || collection.analysis.pending) {
        return (
            <IVALoadingIndicator className="k-mt-4">
                IVA is analyzing your interviews.
                <br />
                The process can take a few minutes.
            </IVALoadingIndicator>
        );
    }

    const onDeleteInsight = (insight: InterviewCollectionInsight) => {
        if (!insight.objective) {
            return;
        }

        const indexOfObjective = collection.objectives.findIndex(objective => objective.localeCompare(insight.objective!) === 0);
        if (indexOfObjective === undefined || indexOfObjective < 0) {
            throw new Error('Objective not found');
        }

        showDeleteCustomInsightQuestionDialog({
            title: 'Delete insights',
            content: <>Are you sure you want to remove insights based on your learning objective?</>,
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete insights',
            callback: async () => {
                const newCollection = await interviewCollectionsService.removeInterviewCollectionObjective(ideaId, collectionId, indexOfObjective!);
                updateCollection(newCollection);
            }
        });
    };

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-10">
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-flex-1">
                {collection.analysis.summary && (
                    <InterviewSummaryInsightsPanel summary={collection.analysis.summary} interviewsCount={collection.analysis.analyzedInterviewsCount} />
                )}
                {collection.analysis.insights?.map((insight, index) => {
                    const boldedInsights = insight.summaryInsights.map((bulletPoint, index) => {
                        const [beforeColon, ...afterColon] = bulletPoint.split(':');
                        return `<strong>${beforeColon}:</strong>${afterColon.join(':')}`;
                    });
                    return (
                        <InterviewInsightsTopicPanel
                            key={index}
                            collectionInsight={{ ...insight, summaryInsights: boldedInsights }}
                            onDelete={() => onDeleteInsight(insight)}
                        />
                    );
                })}
            </StackLayout>
            <div className="-w2 k-align-self-stretch">
                <AIChatPanel
                    placeholder="Ask IVA about this interviews collection..."
                    suggestedQuestions={[
                        'What are the most frequently mentioned problems or pain points across interviews?',
                        'What workarounds or alternative solutions are interviewees currently using to address these problems?',
                        'What emotional responses consistently arise when interviewees discuss the problem?'
                    ]}
                    getAnswer={conversation => interviewCollectionsService.askQuestion(ideaId, collectionId, undefined, chatId, conversation)}
                    formatMessage={stripAIThinkingMessage}
                />
            </div>

            {deleteCustomInsightQuestionDialog}
        </StackLayout>
    );
}

function InterviewSummaryInsightsPanel({ summary, interviewsCount }: { summary: InterviewCollectionInsightSummary; interviewsCount: number }) {
    return (
        <AIGeneratedResultsPanel icon={SummaryIcon} isExpandedByDefault title={<strong>Summary</strong>}>
            <div className="k-icp-panel-base k-py-2 k-pr-4 k-pl-12 k-fs-sm">
                The summary covers key business insights extracted from all {interviewsCount} interviews you've uploaded. It presents the most significant
                findings and prevalent opinions related to topics covered with your interviewees.
            </div>

            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-pt-2 k-pr-4 k-pl-12 k-gap-2">
                {summary.points.map((point, index) => {
                    const [beforeColon, ...afterColon] = point.split(':');
                    const [first, ...restBeforeColon] = beforeColon.split(' ');
                    return (
                        <StackLayout orientation="horizontal" key={`point-${index}`} align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                            <span>{first}</span>
                            <span>
                                <strong>{restBeforeColon.join(' ')}:</strong>
                                {afterColon.join(':')}
                            </span>
                        </StackLayout>
                    );
                })}
            </StackLayout>
        </AIGeneratedResultsPanel>
    );
}

//TODO Might be used if we can show meaningful data
// Usage:
// <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4 -w2">
// <StackLayout className="k-gap-2.5">
//     <InsightStatPanel title="Covered insight questions" value={27} />
//     <InsightStatPanel title="Uncovered insight questions" type="error" />
// </StackLayout>
// </StackLayout>
//
//Component:
// function InsightStatPanel({ title, value, type = 'success' }: { title: string; value?: number; type?: 'success' | 'error' }) {
//     return (
//         <div className={`k-rounded k-border k-border-solid k-border-${type} k-px-4 k-py-2`}>
//             <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
//                 <div className={`k-p-1 k-rounded-full k-icp-bg-${type}-8`}>
//                     {type === 'success' ? (
//                         <CoveredInsightIcon className="k-icp-icon k-icp-icon-size-6" />
//                     ) : (
//                         <NotCoveredInsightIcon className="k-icp-icon k-icp-icon-size-6" />
//                     )}
//                 </div>
//                 <div className="k-display-4">{value === undefined ? <Skeleton shape="text" style={{ width: 32 }} /> : value}</div>
//                 <div className="k-ml-1">{title}</div>
//             </StackLayout>
//         </div>
//     );
// }
