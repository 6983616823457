import JourneyIllustrationSrc from '../../images/journey-success-illustration.svg';
import { NoTitleModal } from '../common/modal';
import { ModalData } from '../../state/ui/modalSlice';

export const JourneyGetStartedDialog = ({
    onOptionOneSelected,
    onOptionTwoSelected
}: {
    onOptionOneSelected?: () => void;
    onOptionTwoSelected?: () => void;
}) => {
    const modalData: ModalData = {
        graphic: {
            url: JourneyIllustrationSrc,
            width: 88,
            height: 88,
            description: 'Journey illustration'
        },
        heading: 'Get started with your Journey',
        description:
            "Tasks 1-8 have been completed by IVA and are ready for your review. Start with Task 1, tweak the AI assistant's draft and refine your business model.",
        mainButtonText: 'Jump into Task 1',
        cancelButtonText: 'Show me the Journey'
    };

    return <NoTitleModal data={modalData} noCloseIcon onMainAction={onOptionOneSelected} onClose={onOptionTwoSelected} />;
};
