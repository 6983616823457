import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Error } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { googleTagManager } from '../../scripts/googleTagManager';
import { HttpException } from '../../services/httpServiceBase';
import { Invite, ideasService } from '../../services/ideasService';
import { UserPermissionsMap, UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { StartupMembershipDetailedList } from './startupMembershipList';
import { combineClassNames } from '../../services/common';

const inviteDropDownValues = [UserRole.Viewer, UserRole.Editor].map(r => {
    return { text: UserPermissionsMap[r], value: r };
});

export function StartupInviteMemberModal({
    ideaId,
    onClose,
    onInvited,
    ideaTitle,
    showManageLink,
    manageLinkDisabled
}: {
    ideaId: string;
    onClose: () => void;
    onInvited?: (createdInvite: Invite) => void;
    ideaTitle: string;
    showManageLink: boolean;
    manageLinkDisabled?: boolean;
}) {
    const currentUserId = useAppSelector(s => s.user?.userId);
    const dispatch = useAppDispatch();

    const onMemberInvited = (invite: Invite) => {
        onClose();
        dispatch(addNotification({ content: `Invitation was sent to ${invite.emailAddress}` }));

        if (onInvited) onInvited(invite);
    };

    return (
        <Dialog onClose={onClose} title={<span className="k-text-ellipsis">Invite member to {ideaTitle}</span>} width={560}>
            <StartupInviteMemberForm ideaId={ideaId} onInvited={onMemberInvited} />
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch' }} className="k-mt-8 k-gap-2">
                <StartupMembershipDetailedList currentUserId={currentUserId} ideaId={ideaId} itemClassName="!k-p-0" edit={false} />
            </StackLayout>

            {showManageLink && (
                <DialogActionsBar layout="start">
                    <NavLink
                        to={`/startups/${ideaId}/settings#members`}
                        className={combineClassNames(
                            'k-button k-button-sm k-button-rectangle k-button-link k-button-link-secondary k-rounded-md',
                            manageLinkDisabled ? 'k-disabled' : undefined
                        )}
                        onClick={onClose}
                    >
                        Manage startup members
                    </NavLink>
                </DialogActionsBar>
            )}
        </Dialog>
    );
}

export function StartupInviteMemberForm({ ideaId, onInvited }: { ideaId: string; onInvited: (createdInvite: Invite) => void }) {
    const [selectedInviteValue, setSelectedInviteValue] = useState(inviteDropDownValues[0]);
    const [inviteEmail, setInviteEmail] = useState<string>('');
    const [isInviteValid, setIsInviteValid] = useState(true);
    const [showValidation, setShowValidation] = useState(false);
    const [validationError, setValidationError] = useState<string>();

    const onInvite = async () => {
        setShowValidation(true);
        if (!isInviteValid) return;

        if (!inviteEmail) {
            setIsInviteValid(false);
            return;
        }

        try {
            const createdInvite = await ideasService.createInvite(ideaId, inviteEmail, selectedInviteValue.value);
            googleTagManager.reportUserInvitedEvent('idea');

            onInvited(createdInvite);
        } catch (error) {
            if (error instanceof HttpException && error.status === 409) {
                setIsInviteValid(false);
                setValidationError('Idea already has a member with the given email address.');
            } else {
                throw error;
            }
        }
    };

    return (
        <div className="k-d-flex k-gap-4">
            <div className="k-flex-auto">
                <div className={`k-input k-input-lg k-rounded-md k-input-solid${isInviteValid || !showValidation ? '' : ' k-invalid'}`}>
                    <input
                        type="email"
                        required
                        placeholder="Email"
                        className="k-input !k-flex-auto k-pr-0 k-input-inner"
                        value={inviteEmail}
                        onChange={e => {
                            setIsInviteValid(e.target.validity.valid);
                            setValidationError(undefined);
                            setInviteEmail(e.target.value);
                        }}
                        onBlur={() => setShowValidation(true)}
                        onKeyDown={e => e.key === 'Enter' && onInvite()}
                        autoFocus
                    />
                    <DropDownList
                        fillMode="outline"
                        className="k-flex-shrink-0 -wa k-button-clear !k-fs-md"
                        data={inviteDropDownValues}
                        value={selectedInviteValue}
                        onChange={e => setSelectedInviteValue(e.value)}
                        textField="text"
                        dataItemKey="value"
                    />
                </div>
                {showValidation && !isInviteValid && validationError && validationError.length && <Error>{validationError}</Error>}
            </div>
            <div>
                <Button type="button" themeColor="primary" size="large" onClick={onInvite} disabled={!isInviteValid && showValidation}>
                    Send invite
                </Button>
            </div>
        </div>
    );
}
